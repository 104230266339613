import { api } from '@store/api-client';
import { PathParameters, ResponseBody } from '@store/utility';
import Api from '@api-schema';
import { enqueueSnackbar } from 'notistack';

const docsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDocsFavorite: builder.query<
      ResponseBody<Api.operations['getFavoriteProjectAttachments']>,
      {
        start?: number;
        limit?: number;
        projectIds?: string[];
        name?: string;
      }
    >({
      query: ({ start, limit, projectIds, name }) => ({
        method: `GET`,
        url: `/api/project/favorite/attachments`,
        params: { start, limit, projectIds, name },
      }),
      providesTags: ['FavoriteDocs'],
    }),

    setDocsFavorite: builder.mutation<
      ResponseBody<Api.operations['addToFavorites_2']>,
      {
        docId: PathParameters<Api.operations['addToFavorites_2'], 'id'>;
      }
    >({
      query: ({ docId }) => ({
        method: `POST`,
        url: `/api/file/${docId}/favorite`,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Раздел "Избранное" успешно обновлен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['FavoriteDocs', 'ProjectDetail'],
    }),
  }),
});

export const { useGetDocsFavoriteQuery, useSetDocsFavoriteMutation } = docsApi;
