import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import './styles/mainPage.css';
import './scss/common.scss';
import { store } from '@store/index';
import { ApplicationThemeProvider } from './ApplicationThemeProvider';
import { Provider } from 'react-redux';
import { setupApiClient } from '@store/api-client';
import { loadDictionaries } from '@store/dictionary/dictionary.slice';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ClassNameConfigurator } from '@mui/base';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';

dayjs.extend(customParseFormat);
setupApiClient();

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
}));

async function bootstrap() {
  await store.dispatch(loadDictionaries());

  const root = ReactDOM.createRoot(document.getElementById('root')!);
  root.render(
    <React.StrictMode>
      <ApplicationThemeProvider>
        <ClassNameConfigurator disableDefaultClasses>
          <Provider store={store}>
            <SnackbarProvider
              maxSnack={5}
              preventDuplicate={true}
              hideIconVariant={true}
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}>
              <App />
            </SnackbarProvider>
          </Provider>
        </ClassNameConfigurator>
      </ApplicationThemeProvider>
    </React.StrictMode>,
  );
}

bootstrap();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
