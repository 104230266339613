import dayjs from 'dayjs';
import styles from './MessageBlock.module.scss';
import avatar from '@assets/img/avatar.svg';
import { VacancyConditions } from '@pages/project/Vacancies/components/VacancyConditions';

type MessageBlockProps = {
  message: any;
};

export const MessageBlock = ({ message }: MessageBlockProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.container__info}>
        <img src={avatar} alt="avatar" />
        <div>
          <div className={styles.name}>
            <p>{message?.actorName}</p>
            <p>{dayjs(message?.createdAt).format('DD.MM.YYYY')}</p>
          </div>
        </div>
      </div>
      <p>{message?.payload?.message}</p>
      <VacancyConditions
        combinedTypeOfPayment
        typeOfPayment={message?.payload?.typeOfPayment}
        payment={message.payload.payment}
        workloadPerMonth={message.payload.workloadPerMonth}
      />
    </div>
  );
};
