import { useParams } from 'react-router-dom';
import styles from './ProjectTitle.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { projectStatusColors } from '@pages/dashboard/Projects/AmountOfProjects/AmountOfProjects';

export const ProjectTitle = () => {
  const { projectId } = useParams();
  const { data: project } = useDetailProjectQuery(projectId || '');
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>{project?.name}</h2>
        <StatusLabel
          color={projectStatusColors.get(project?.projectStatusName!)}
          text={project?.projectStatusName!}
        />
      </div>

      <div className={styles.manager}>
        <p>Менеджер</p>
        <div className={styles.manager__info}>
          <p>{project?.teamLeadName ?? '-'}</p>
        </div>
      </div>
    </div>
  );
};
