import { ApiClient } from '@store/api-client';
import { PaginationQuery } from '@store/common';
import {
  CreateTemplateRequest,
  CreateTemplateResponse,
  TemplateListResponse,
  ObjectSaveResponse,
  PureCloudResource,
  CloudDelegationListResponse,
  CloudDelegation,
  ItemCloudInfrastructureRequest,
  CloudInfrastructureListResponse,
  CloudInfrastructure,
  ItemCloudDeploymentResourceRequest,
  CloudDeploymentResourceListResponse,
  CloudDeploymentResource,
  ItemCloudInstanceResourceRequest,
  CloudInstanceResourceListResponse,
  CloudInstanceResource,
  CloudDeploymentRuleListResponse,
  CloudImageResourceListResponse,
  ItemCloudScheduleRequest,
  CloudScheduleListResponse,
  CloudSchedule,
  CloudDeploymentActionListResponse,
  CloudDeploymentAction,
} from '@store/cloud/contracts';
import Api from '@api-schema';
import { RequestBody } from '@store/utility';

export const getTemplateList = async (
  request: Partial<PaginationQuery>,
): Promise<TemplateListResponse> => {
  const { data } = await ApiClient.post<TemplateListResponse>(
    `/api/cloud/instance/params/template/list`,
    request,
  );
  return data;
};

export const createTemplate = async (
  request: CreateTemplateRequest,
): Promise<CreateTemplateResponse> => {
  const { data } = await ApiClient.post<CreateTemplateResponse>(
    `/api/cloud/instance/params/template`,
    request,
  );
  return data;
};

// TODO: Отсутствует функционал редактирования шаблона
export const editTemplate = async () => {
  return null;
};

export const getOrganizationsWithClouds = async (
  delegationId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/organization/list?delegationId=${delegationId}`,
  );
  return data;
};

export const getCloudsWithClouds = async (
  delegationId?: string,
  organizationId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/cloud/list?parentId=${organizationId}&delegationId=${delegationId}`,
  );
  return data;
};

export const getFoldersWithClouds = async (
  delegationId?: string,
  cloudId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/folder/list?parentId=${cloudId}&delegationId=${delegationId}`,
  );
  return data;
};

export const getNetworksWithClouds = async (
  delegationId?: string,
  folderId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/network/list?parentId=${folderId}&delegationId=${delegationId}`,
  );
  return data;
};

export const getInstancesWithClouds = async (
  infrastructureId?: string,
  folderId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/instance/list?parentId=${folderId}&infrastructureId=${infrastructureId}`,
  );
  return data;
};

export const getZoneWithClouds = async (
  infrastructureId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/zone/list?infrastructureId=${infrastructureId}`,
  );
  return data;
};

export const getPlatformWithClouds = async (
  infrastructureId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/platform/list?infrastructureId=${infrastructureId}`,
  );
  return data;
};

export const getBillingAccountsWithClouds = async (
  delegationId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/account/list?delegationId=${delegationId}`,
  );
  return data;
};

export const getInstanceGroupsWithClouds = async (
  infrastructureId?: string,
  folderId?: string,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/instanceGroup/list?parentId=${folderId}&infrastructureId=${infrastructureId}`,
  );
  return data;
};

/*****************************************/
/*   Делегирование                       */
/*****************************************/
export const saveCloudDelegation = async (request: RequestBody<Api.operations['saveItem_6']>) => {
  // ItemCloudDelegationRequest
  const modifiedDelegation = {
    ...request,
    //budgetEndDate: dayjs(request.budgetEndDate).format('DD.MM.YYYY HH:mm'),
  };
  const { data } = await ApiClient.post<ObjectSaveResponse>(
    '/api/cloud/delegation',
    modifiedDelegation,
  );
  return data;
};

export const getCloudDelegationList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDelegationListResponse> => {
  const { data } = await ApiClient.post<CloudDelegationListResponse>(
    `/api/cloud/delegation/list`,
    request,
  );
  return data;
};
export const getCloudDelegation = async (id: string) => {
  const { data } = await ApiClient.get<CloudDelegation>(`/api/cloud/delegation/${id}`);
  return data;
};

export const removeCloudDelegation = async (id: string) => {
  const { data } = await ApiClient.get<CloudDelegation>(`/api/cloud/delegation/remove/${id}`);
  return data;
};

/*****************************************/
/*   Инфраструктура                      */
/*****************************************/
export const saveCloudInfrastructure = async (request: ItemCloudInfrastructureRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/infrastructure', request);
  return data;
};

export const getCloudInfrastructureList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudInfrastructureListResponse> => {
  const { data } = await ApiClient.post<CloudInfrastructureListResponse>(
    `/api/cloud/infrastructure/list`,
    request,
  );
  return data;
};
export const getCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudInfrastructure>(`/api/cloud/infrastructure/${id}`);
  return data;
};
/*
export const removeCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudInfrastructure>(
    `/api/cloud/infrastructure/remove/${id}`,
  );
  return data;
};
*/

/************************************** ************/
/*   Правила развертывания                        */
/**************************************************/
export const saveCloudDeploymentResource = async (request: ItemCloudDeploymentResourceRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/deployment', request);
  return data;
};

export const getCloudDeploymentResourceList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDeploymentResourceListResponse> => {
  const { data } = await ApiClient.post<CloudDeploymentResourceListResponse>(
    `/api/cloud/deployment/list`,
    request,
  );
  return data;
};
export const getCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentResource>(`/api/cloud/deployment/${id}`);
  return data;
};

export const removeCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentResource>(
    `/api/cloud/deployment/remove/${id}`,
  );
  return data;
};

/**************************************************/
/*   Инстансы виртуальных машин                   */
/**************************************************/
export const saveCloudInstanceResource = async (request: ItemCloudInstanceResourceRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/instance', request);
  return data;
};

export const getCloudInstanceResourceList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudInstanceResourceListResponse> => {
  const { data } = await ApiClient.post<CloudInstanceResourceListResponse>(
    `/api/cloud/instance/list`,
    request,
  );
  return data;
};
export const getCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudInstanceResource>(`/api/cloud/instance/${id}`);
  return data;
};

export const removeCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudInstanceResource>(`/api/cloud/instance/remove/${id}`);
  return data;
};

/**************************************************/
/*   Справочник правил развертывания              */
/**************************************************/
export const getCloudDeploymentRuleList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDeploymentRuleListResponse> => {
  const { data } = await ApiClient.post<CloudDeploymentRuleListResponse>(
    `/api/cloud/deployment/rule/list`,
    request,
  );
  return data;
};

/**************************************************/
/*   Справочник образов                           */
/**************************************************/

export const getCloudImageResourceList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudImageResourceListResponse> => {
  const { data } = await ApiClient.post<CloudImageResourceListResponse>(
    `/api/cloud/image/resource/list`,
    request,
  );
  return data;
};
/**************************************************/
/*   Расписание                                   */
/**************************************************/
export const saveCloudSchedule = async (request: ItemCloudScheduleRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/schedule', request);
  return data;
};

export const getCloudScheduleList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudScheduleListResponse> => {
  const { data } = await ApiClient.post<CloudScheduleListResponse>(
    `/api/cloud/schedule/list`,
    request,
  );
  return data;
};

export const getCloudSchedule = async (id: string) => {
  const { data } = await ApiClient.get<CloudSchedule>(`/api/cloud/schedule/${id}`);
  return data;
};

/**************************************************/
/*   Справочник действий по расписанию            */
/**************************************************/

export const getCloudDeploymentActionList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDeploymentActionListResponse> => {
  const { data } = await ApiClient.post<CloudDeploymentActionListResponse>(
    `/api/cloud/deployment/action/list`,
    request,
  );
  return data;
};

/**************************************************/
/*   Операции над облаком                         */
/**************************************************/

/**************************************************/
/*   Операции над инфраструктурой                 */
/**************************************************/
// Построить инфраструктуру
export const buildCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/infrastructure/operation/build/${id}`,
  );
  return data;
};
// Разрушть инфраструктуру
export const destroyCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/infrastructure/operation/destroy/${id}`,
  );
  return data;
};

/**************************************************/
/*   Операции над правилами развертывания         */
/**************************************************/
// Развернуть виртуальные машины
export const buildCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/build/${id}`,
  );
  return data;
};
// Разрушить виртуальные машины
export const destroyCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/destroy/${id}`,
  );
  return data;
};
// Запустить виртуальные машины
export const startCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/start/${id}`,
  );
  return data;
};
// Остановть виртуальные машины
export const stopCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/stop/${id}`,
  );
  return data;
};
// Развернуть и запустить виртуальные машины
export const buildAndStartCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/build/start/${id}`,
  );
  return data;
};

/**************************************************/
/*   Операции над виртуальной машиной             */
/**************************************************/
// Развернуть виртуальную машину
export const buildCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/build/${id}`,
  );
  return data;
};
// Разрушить виртуальную машину
export const destroyCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/destroy/${id}`,
  );
  return data;
};
// Запустить виртуальную машину
export const startCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/start/${id}`,
  );
  return data;
};
// Остановть виртуальную машину
export const stopCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/stop/${id}`,
  );
  return data;
};
// Развернуть и запустить виртуальную машину
export const buildAndStartCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/build/start/${id}`,
  );
  return data;
};
