import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useRequestBody } from '@hooks/useRequestBody';
import { useSelectableRows } from '@hooks/useSelectableRows';
import { useUrlParams } from '@hooks/useUrlParams';
import { Checkbox } from '@mui/material';
import { useGetProfilesQuery } from '@store/user/user.slice';
import { Field, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './ApplicantsTable.module.scss';
import { Button } from '@components/MUI/Button';
import { useSendOfferMutation } from '@store/project/project.slice';

type ApplicantsValues = {
  selected: string[];
};

export const ApplicantsTable = () => {
  const { vacancyId } = useParams();
  const { getParam, updateParams } = useUrlParams();
  const form = useFormikContext<ApplicantsValues>();

  const [limit, setLimit] = useState<number>(Number(getParam('limit', 10)));
  const [page, setPage] = useState<number>(Number(getParam('page', 1)));

  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: users, isLoading } = useGetProfilesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    sortField: sortColumn,
    ...requestBody,
  });

  const [sendInvite] = useSendOfferMutation();

  const {
    headerCheckboxProps,
    isSelected,
    toggleAllSelection,
    toggleRowSelection,
    clearAllSelections,
  } = useSelectableRows({
    rows: users?.data || [],
    fieldName: 'selected',
    getValue: (row) => row.id,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    updateParams({ page: newPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    updateParams({ limit: newLimit, page: 1 });
    setLimit(newLimit);
    setPage(1);
  };

  const handleInviteUser = useCallback(
    async (id: string) => {
      await sendInvite({
        vacancyId: vacancyId!,
        selectedUsers: [id],
      });
    },
    [sendInvite, vacancyId],
  );

  const handleInviteSelectedUsers = useCallback(async () => {
    await sendInvite({
      vacancyId: vacancyId!,
      selectedUsers: form.values.selected,
    })
      .unwrap()
      .then(() => {
        clearAllSelections();
      });
  }, [sendInvite, clearAllSelections, vacancyId, form.values]);

  return (
    <>
      <AdaptiveTableMUI
        rows={users?.data || []}
        isLoading={isLoading}
        columns={{
          id: {
            label: '',
            additionalItem: (
              <div className={styles.headerCheckbox}>
                <Checkbox {...headerCheckboxProps} onChange={toggleAllSelection} />
              </div>
            ),
            children: (value) => {
              const isChecked = isSelected(value);
              return (
                <>
                  <Field
                    name="selected"
                    value={value}
                    component={Checkbox}
                    checked={isChecked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      toggleRowSelection(e, value)
                    }
                  />
                </>
              );
            },
          },
          firstName: {
            label: 'ФИО',
            children: (_, row) => {
              const firstName = row?.about?.firstName;
              const lastName = row?.about?.lastName;
              const fullName = firstName && lastName ? `${lastName} ${firstName}` : 'Не указано';
              return (
                <>
                  <Link to={`/userPage/${row?.id}`} className={styles.name}>
                    {fullName}
                  </Link>
                </>
              );
            },
            filterInput: true,
          },
          stack: {
            label: 'Стек',
            children: (_, row) => {
              const userStack = row?.about?.stack ? row?.about?.stack.join(', ') : 'Не указан';

              return <>{userStack}</>;
            },
          },
          experience: {
            label: 'Опыт',
            children: (_, row) => {
              const userExperience = row?.about?.experience ?? 'Не указан';
              return <>{userExperience}</>;
            },
          },
          hourPrice: {
            label: 'Оплата',
            children: (_, row) => {
              const userPrice = row?.work?.hourPrice ?? 'Не указано';

              return <>{userPrice}</>;
            },
          },
          invite: {
            additionalItem: (
              <Button
                disabled={form.values.selected.length === 0}
                variant="contained"
                onClick={handleInviteSelectedUsers}
                className={styles.headerButton}>
                Пригласить
              </Button>
            ),
            children: (_, row) => {
              return (
                <Button
                  disabled={form.values.selected.length > 0}
                  variant="contained"
                  onClick={() => handleInviteUser(row.id)}>
                  Пригласить
                </Button>
              );
            },
          },
        }}
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
      />
      <TablePaginationMUI
        limit={limit}
        page={page}
        count={users?.totalCount}
        entityLabel="соискателей"
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
