import { useEffect, useState } from 'react';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { FavoriteNavigationBar } from './FavoriteNavigationBar';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { getFavoriteCounts } from '@store/favorite/favorite.slice';

export const FavoriteLayout = () => {
  const dispatsch = useAppDispatch();
  const [messagesCount, setMessagesCount] = useState(0);

  useEffect(() => {
    dispatsch(getFavoriteCounts());
  }, []);

  return (
    <MainContentLayout>
      <FavoriteNavigationBar messagesCount={messagesCount || ''} />
      <Outlet context={[{ setMessagesCount: setMessagesCount }]} />
    </MainContentLayout>
  );
};
