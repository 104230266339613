import { List, styled, useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { ReactComponent as Cloud } from '@assets/img/cloud.svg';
import { ReactComponent as Dashboard } from '@assets/img/dashboard.svg';
import { ReactComponent as Tasks } from '@assets/img/tasks.svg';
import { ReactComponent as Messages } from '@assets/img/messages.svg';
import { ReactComponent as Projects } from '@assets/img/projects.svg';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { ButtonSidebar } from '@components/Sidebar/ButtonSidebar';
import { Can } from '@access-control';
import { toggleSidebar } from '@store/ui/ui.slice';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Sidebar.module.scss';
import { keyframes } from '@mui/system';

const expand = keyframes`
  from { max-width: 82px; }
  to { max-width: 188px; }
`;

const collapse = keyframes`
  from { max-width: 188px; }
  to { max-width: 82px; }
`;

export const Sidebar = () => {
  const open = useAppSelector((state) => state.ui.sidebarVisibility);
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery('(max-width: 991px)');

  function toggleSidebarVisibility() {
    dispatch(toggleSidebar());
  }

  const Drawer = styled(MuiDrawer)({
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      position: isTablet ? 'absolute' : 'sticky',
      height: '100%',
      maxWidth: open ? '188px' : isTablet ? '0px' : '82px',
      animation: `${open ? expand : collapse} 0.3s ease-in-out`,
      willChange: 'max-width',
      transition: 'max-width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      zIndex: 1101,
    },
    '&.MuiDrawer-docked.MuiDrawer-root': {
      position: isTablet ? 'static' : 'sticky',
      top: 0,
    },
  });

  return (
    <>
      {isTablet && open && (
        <div
          onClick={toggleSidebarVisibility}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: '#78858B',
            opacity: 0.5,
            zIndex: 1,
          }}
        />
      )}
      <Drawer variant="permanent" open={open}>
        <List className={styles.sidebarList}>
          {isTablet && (
            <div className={styles.closeIcon}>
              <CloseIcon
                onClick={toggleSidebarVisibility}
                sx={{ color: '#78858B', width: '32px', height: '32px' }}
              />
            </div>
          )}
          <Can I="read" a="Dashboard">
            <ButtonSidebar href={`/dashboard/projects`} text="Дашборд" open={open}>
              <Dashboard />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/projects" text="Проекты" open={open}>
            <Projects />
          </ButtonSidebar>
          <Can I="create" a="Task">
            <ButtonSidebar href="/tasks" text="Задачи" open={open}>
              <Tasks />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/clouds" text="Облачный ДЦ" open={open}>
            <Cloud />
          </ButtonSidebar>
          <ButtonSidebar href="/messages" text="Сообщения" open={open}>
            <Messages />
          </ButtonSidebar>
        </List>
      </Drawer>
    </>
  );
};
