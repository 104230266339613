import React, { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import styles from './DeploymentResourceForm.module.scss';
import { Select } from '@components/MUI/Select';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';

import {
  loadInfrastructures,
  selectInfrastructureOptions,
  loadTemplates,
  selectTemplateOptions,
  loadCloudDeploymentRules,
  selectDeploymentRuleOptions,
  loadCloudInstances,
  selectCloudInstanceOptions,
  loadImages,
  selectImageOptions,
  loadSchedules,
  selectScheduleOptions,
  loadDeploymentActions,
  selectDeploymentActionOptions,
  loadZones,
  selectZoneOptions,
  loadPlatforms,
  selectPlatformOptions,
  loadInstanceGroups,
  selectInstanceGroupOptions,
  selectInfrastructures,
} from '@store/cloud/cloud.slice';

export type DeploymentResourceFormProps = {
  value: DeploymentResourceFormValue;
  submit: (
    value: DeploymentResourceFormValue,
    form: FormikBag<DeploymentResourceFormProps, DeploymentResourceFormValue>,
  ) => void;
};

type DeploymentResourceFormValue = {
  name: string; // Наименование
  infrastructureId: string; // Инфраструктура
  instanceParamsId: string; // Шаблон виртуальной машины
  instances: string[]; // Список виртуальных машин
  ruleId: string; // Правила развертывания
  numberInstance: number; // Количество виртуальных машин для развертывания
  useMasterInstance: boolean; // Использовать мастер машину для образа
  masterInstanceId: string; // Мастер виртуальная машина, с которой копируется образ
  imageId: string; // Образ вртуальной машины
  sheduleId: string; // Расписание
  startActionId: string; // Действие на старте расписания
  endActionId: string; // Действие на окончании расписания
  zoneId: string; // Ресурс зоны
  zoneName: string; // Ресурс зоны
  platformId: string; // Ресурс платформы
  platformName: string; // Ресурс платформы
  instanceGroupId: string; //Ресурс группы виртуальных машин
  instanceGroupName: string; //Ресурс группы виртуальных машин
  instanceGroupParentId: string; // Ресурс группы виртуальных машин
  preemptible: boolean;
  positionId: string; //Позиция
};

const DeploymentResourceFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  infrastructureId: Yup.string().required('Поле обязательно для заполнения'),
  instanceParamsId: Yup.string().required('Поле обязательно для заполнения'),
  ruleId: Yup.string().required('Поле обязательно для заполнения'),
  zoneId: Yup.string(),
  platformId: Yup.string(),

  numberInstance: Yup.number().when('ruleId', {
    is: (ruleId: string) => {
      return (
        ruleId === '594265c2-b98f-43aa-a3ee-98a11d8f7b18' ||
        ruleId === '42ce2ff5-50bb-448a-9d12-4d3f9c94d9bb'
      );
    },
    then: Yup.number().min(1, 'Количество виртуальных машин должно быть больше 0'),
  }),
  instanceGroupId: Yup.string(),
  instanceGroupName: Yup.string().when('ruleId', {
    is: '42ce2ff5-50bb-448a-9d12-4d3f9c94d9bb',
    then: Yup.string()
      .required('Поле обязательно для заполнения')
      .matches(
        /^[a-z][-a-z0-9]{1,61}[a-z0-9]$/,
        'Название группы виртуальных машин не соответствует требованиям, например назовите: instanceGroup2025 или instanceGroup',
      ),
  }),
  useMasterInstance: Yup.boolean(),
  masterInstanceId: Yup.string().when('useMasterInstance', {
    is: (useMasterInstance: boolean) => useMasterInstance,
    then: Yup.string().required('Поле обязательно для заполнения'),
  }),
  imageId: Yup.string().when('useMasterInstance', {
    is: (useMasterInstance: boolean) => !useMasterInstance,
    then: Yup.string().required('Поле обязательно для заполнения'),
  }),

  preemptible: Yup.boolean(),
  sheduleId: Yup.string(),
  startActionId: Yup.string(),
  endActionId: Yup.string(),
});

const FormComponent = () => {
  const form = useFormikContext<DeploymentResourceFormValue>();

  const dispatch = useAppDispatch();
  const infrastructureOptions = useAppSelector(selectInfrastructureOptions);
  const templateOptions = useAppSelector(selectTemplateOptions);
  const deploymentRuleOptions = useAppSelector(selectDeploymentRuleOptions);
  const cloudInstanceOptions = useAppSelector(selectCloudInstanceOptions);
  const imageOptions = useAppSelector(selectImageOptions);
  const scheduleOptions = useAppSelector(selectScheduleOptions);
  const deploymentActionOptions = useAppSelector(selectDeploymentActionOptions);
  const zoneOptions = useAppSelector(selectZoneOptions);
  const platformOptions = useAppSelector(selectPlatformOptions);
  const instanceGroupOptions = useAppSelector(selectInstanceGroupOptions);
  const [newInstanceGroup, setNewInstanceGroup] = useState<boolean>(false);
  const infrastructures = useAppSelector(selectInfrastructures);
  const ruleIdWithNumberInstances = deploymentRuleOptions?.find(
    (rule) => rule.label === 'Указать количество экземпляров',
  )?.value;

  const ruleIdWithInstanceGroup = deploymentRuleOptions?.find(
    (rule) => rule.label === 'Группа виртуальных машин',
  )?.value;

  useEffect(() => {
    if (form.values.instanceGroupId === '') setNewInstanceGroup(true);

    if (form.values.instanceGroupId && form.values.instanceGroupId !== '') {
      setNewInstanceGroup(false);
      let selected = instanceGroupOptions.find(
        (item) => item.value === form.values.instanceGroupId,
      );
      if (selected !== undefined) form.setFieldValue('instanceGroupName', selected.label);
      else setNewInstanceGroup(true);
    }
  }, [form.values.instanceGroupId]);

  const disableUseMasterInstance = useCallback(async () => {
    form.setFieldValue('useMasterInstance', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffUseMasterInstance = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить образ виртуальной машины?"
          btnProp="Отключить"
          onSubmit={disableUseMasterInstance}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleUseMasterInstance = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffUseMasterInstance();
    } else form.setFieldValue('useMasterInstance', event.target.checked);
  };

  const disableIsPreemptible = useCallback(async () => {
    form.setFieldValue('preemptible', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffIsPreemptible = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить прерываемость виртуальной машины?"
          btnProp="Отключить"
          onSubmit={disableIsPreemptible}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleIsPreemptible = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffIsPreemptible();
    } else form.setFieldValue('preemptible', event.target.checked);
  };

  form.validationSchema = DeploymentResourceFormSchema;

  useEffect(() => {
    dispatch(loadInfrastructures({}));
    dispatch(loadTemplates({}));
    dispatch(loadCloudDeploymentRules({}));
    dispatch(loadCloudInstances({}));
    dispatch(loadImages({}));
    dispatch(loadSchedules({}));
    dispatch(loadDeploymentActions({}));
    dispatch(
      loadZones({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
      }),
    );
    dispatch(
      loadPlatforms({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    let folderResourceId = '';
    if (form.values.infrastructureId) {
      const infrastructure = infrastructures.find(
        (item) => item.id === form.values.infrastructureId,
      );

      if (
        infrastructure !== undefined &&
        infrastructure !== null &&
        infrastructure.folder !== undefined
      )
        folderResourceId = infrastructure.folder.resourceId;
      form.setFieldValue('instanceGroupParentId', folderResourceId);
    }
    dispatch(
      loadInstanceGroups({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
        parentId: folderResourceId,
      }),
    );
  }, [form.values.infrastructureId]);

  useEffect(() => {
    form.setFieldValue('zoneName', form.values.zoneId);
  }, [form.values.zoneId]);

  useEffect(() => {
    form.setFieldValue('platformName', form.values.platformId);
  }, [form.values.platformId]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название правила"
          type="text"
          className={styles.form__field}
        />
        <Select
          name="infrastructureId"
          values={infrastructureOptions}
          label={
            infrastructureOptions.length === 0
              ? 'Создайте инфраструктуру в разделе инфраструктура'
              : 'Инфраструктура'
          }
          isOutlined
          disabled={infrastructureOptions.length === 0}
          className={styles.form__field}
        />
        <Select
          name="platformId"
          values={platformOptions}
          label={platformOptions.length === 0 ? 'Отсутствует интеграция с Яндекс' : 'Платформа'}
          disabled={platformOptions.length === 0}
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="zoneId"
          values={zoneOptions}
          label={zoneOptions.length === 0 ? 'Выполните интеграцию' : 'Зона'}
          disabled={zoneOptions.length === 0}
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="instanceParamsId"
          values={templateOptions}
          label={templateOptions.length === 0 ? 'Создайте шаблон в разделе Шаблоны' : 'Шаблон'}
          disabled={templateOptions.length === 0}
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="ruleId"
          values={deploymentRuleOptions}
          label="Правила"
          isOutlined
          className={styles.form__field}
        />
        {form.values.ruleId === ruleIdWithInstanceGroup && (
          <Select
            name="instanceGroupId"
            values={instanceGroupOptions}
            label="Группа виртуальных машин"
            isOutlined
            className={styles.form__field}
          />
        )}

        {newInstanceGroup && form.values.ruleId === ruleIdWithInstanceGroup && (
          <InputField
            name="instanceGroupName"
            placeholder="Название группы виртуальных машины"
            type="text"
            className={styles.form__field}
          />
        )}
        {(form.values.ruleId === ruleIdWithNumberInstances ||
          form.values.ruleId === ruleIdWithInstanceGroup) && (
          <InputField
            name="numberInstance"
            placeholder="Количество виртуальных машин"
            type="number"
            className={styles.form__field}
          />
        )}
        <div className="d-flex gap-4 align-items-center mb-4">
          Использовать образ виртуальной машины{' '}
          <FormControlLabel
            control={
              <MuiSwitch
                checked={form.values.useMasterInstance}
                onChange={toggleUseMasterInstance}
                name="useMasterInstance"
              />
            }
            label=""
          />
        </div>
        {form.values.useMasterInstance ? (
          <Select
            name="masterInstanceId"
            values={cloudInstanceOptions}
            label="Мастер машина образа"
            isOutlined
            className={styles.form__field}
          />
        ) : (
          <></>
        )}
        {!form.values.useMasterInstance ? (
          <Select
            name="imageId"
            values={imageOptions}
            label="Образ машины"
            isOutlined
            className={styles.form__field}
          />
        ) : (
          <></>
        )}
        <div className="d-flex gap-4 align-items-center">
          Прерываемая машина
          <FormControlLabel
            control={
              <MuiSwitch
                checked={form.values.preemptible}
                onChange={toggleIsPreemptible}
                name="preemptible"
              />
            }
            label=""
          />
        </div>
        <Select
          name="sheduleId"
          values={scheduleOptions}
          label="Расписание"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="startActionId"
          values={deploymentActionOptions}
          label="Действие на старте расписание"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="endActionId"
          values={deploymentActionOptions}
          label="Действие на окончании расписания"
          isOutlined
          className={styles.form__field}
        />
      </div>

      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        Сохранить
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const DeploymentResourceForm = withFormik<
  DeploymentResourceFormProps,
  DeploymentResourceFormValue
>({
  displayName: 'DeploymentResourceForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      infrastructureId: props.value.infrastructureId,
      instanceParamsId: props.value.instanceParamsId,
      instances: props.value.instances,
      ruleId: props.value.ruleId,
      numberInstance: props.value.numberInstance,
      useMasterInstance: props.value.useMasterInstance,
      masterInstanceId: props.value.masterInstanceId,
      imageId: props.value.imageId,
      sheduleId: props.value.sheduleId,
      startActionId: props.value.startActionId,
      endActionId: props.value.endActionId,
      zoneId: props.value.zoneId,
      zoneName: props.value.zoneName,
      platformId: props.value.platformId,
      platformName: props.value.platformName,
      instanceGroupId: props.value.instanceGroupId,
      instanceGroupName: props.value.instanceGroupName,
      instanceGroupParentId: props.value.instanceGroupParentId,
      preemptible: props.value.preemptible,
      positionId: props.value.positionId,
    };
  },
  enableReinitialize: true,
  validationSchema: DeploymentResourceFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
