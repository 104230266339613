import { closeModal } from '@store/ui/ui.slice';
import { useAppDispatch } from '.';

export function useNavigateToUserChat() {
  const dispatch = useAppDispatch();
  function navigateToChat(userId?: string) {
    if (userId) {
      dispatch(closeModal());
    }
  }

  return navigateToChat;
}
