import { Button as MButton, styled } from '@mui/material';

export const Button = styled(MButton)(({ theme, variant }) => ({
  textTransform: 'none',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  '&.Mui-disabled': {
    color: '#FFFFFF',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor:
      variant === 'outlined' ? theme.palette.primary.main : theme.palette.secondary.main,
    color: `${theme.palette.background.paper}`,
    transition: 'background-color 0.7s, color 0.7s',
    '& svg path': {
      fill:
        !(theme.palette.background.paper === '#ffffff' && variant === 'contained') &&
        theme.palette.background.paper,
      transition: 'fill 0.7s',
    },
  },
}));
