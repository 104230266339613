import { FormWrapper } from '@components/Form/Form';
import { Modal } from '@components/MUI/Modal/Modal';
import { useParams } from 'react-router-dom';
import { useAddPositionToProjectMutation } from '@store/project/project.slice';
import { useAppDispatch } from '@hooks/index';
import { useCallback } from 'react';
import { openModal } from '@store/ui/ui.slice';
import { AddPositionModal } from '../AddPositionModal';
import { AddVacancyValue } from '../AddPositionForm';
import { AddPositionToVacancyForm, AddPositionToVacancyProps } from './AddPositionToVacancyForm';

type AddPositionToVacancyModalProps = {
  savedValues: AddVacancyValue;
};

export const AddPositionToVacancy = ({ savedValues }: AddPositionToVacancyModalProps) => {
  const { directionId } = savedValues;
  const dispatch = useAppDispatch();

  const { projectId } = useParams();
  const [addPosition] = useAddPositionToProjectMutation();

  const handleSubmit: AddPositionToVacancyProps['submit'] = async (value) => {
    await addPosition({ projectId: projectId!, payload: { ...value, skills: [] } })
      .unwrap()
      .then(() => {
        dispatch(openModal(<AddPositionModal initialValues={savedValues} />));
      });
  };

  const handleDismiss = useCallback(() => {
    dispatch(openModal(<AddPositionModal initialValues={savedValues} />));
  }, []);

  return (
    <Modal>
      <FormWrapper label="Создание новой должности">
        <AddPositionToVacancyForm
          submit={handleSubmit}
          value={{ name: '', directionId }}
          onDismiss={handleDismiss}
        />
      </FormWrapper>
    </Modal>
  );
};
