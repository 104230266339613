import { PROJECT_STATUS_ID, PROJECT_STATUS } from '@store/project/contracts';
import { ManagerProjectsTable } from '../components/ManagerProjectsTable';
import { useUrlPagination } from '@hooks/useUrlPagination';
import { useRequestBody } from '@hooks/useRequestBody';
import { useGetProjectsQuery } from '@store/project/project.slice';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { RoleCodes } from '@store/user/contracts';

const rolesToShow = [
  PROJECT_STATUS_ID[PROJECT_STATUS.FINISHED],
  PROJECT_STATUS_ID[PROJECT_STATUS.NOT_ACTIVE],
];

const managerRoleCode = RoleCodes.Manager;

export const ProjectsArchive = () => {
  const { limit, page, handleChangePage, handleChangeRowsPerPage, start } = useUrlPagination({
    defaultLimit: 10,
    defaultPage: 1,
  });

  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: projects } = useGetProjectsQuery({
    limit: limit,
    start: start,
    sortOrder: sortOrder,
    roles: [managerRoleCode],
    projectStatusId: rolesToShow,
    ...requestBody,
  });

  return (
    <>
      <ManagerProjectsTable
        rows={projects?.data || []}
        sortColumn={sortColumn}
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
      />
      <TablePaginationMUI
        limit={limit}
        count={projects?.totalCount}
        entityLabel="проектов"
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
