import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import styles from './DecisionForm.module.scss';
import { Select } from '@components/MUI/Select';
import {
  paymentVariants,
  workloadVariants,
} from '@pages/project/Vacancies/components/AddPosition/AddPositionForm';
import { SelectChangeEvent } from '@mui/material';
import classNames from 'classnames';
import { TYPE_OF_PAYMENTS } from '@store/project/contracts';
import { InputField } from '@components/MUI/InputField/InputField';
import { ModalFooter } from '@components/MUI/Modal/Modal';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { number, object, string } from 'yup';

export type DecisionFormValue = {
  typeOfPayment: string;
  workloadPerMonth: number;
  payment: number;
  message: string;
};

export type DecisionFormProps = {
  value: DecisionFormValue;
  submit: (value: DecisionFormValue, form: FormikBag<DecisionFormProps, DecisionFormValue>) => void;
  buttonLabel: string;
  checkForPristine?: boolean;
};

const decisionFormSchema = object({
  typeOfPayment: string().required('Обязательно для заполнения'),
  workloadPerMonth: number().when('typeOfPayment', {
    is: (value: string) => value === TYPE_OF_PAYMENTS.HOURLY,
    then: number().required('Обязательно для заполнения'),
    otherwise: number().notRequired(),
  }),
  payment: number().when('typeOfPayment', {
    is: (value: string) => value === TYPE_OF_PAYMENTS.UNPAID,
    then: number().notRequired(),
    otherwise: number().required('Обязательно для заполнения').min(1, 'Обязательно для заполнения'),
  }),
  message: string(),
});

const Decision = ({ buttonLabel, checkForPristine }: DecisionFormProps) => {
  const form = useFormikContext<DecisionFormValue>();
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  // Нужно для кейса, когда нам нужно отправить новые условия
  const isPristine =
    form.values.typeOfPayment === form.initialValues.typeOfPayment &&
    form.values.workloadPerMonth === form.initialValues.workloadPerMonth &&
    form.values.payment === form.initialValues.payment;

  const isDisabled = checkForPristine ? !form.isValid || isPristine : !form.isValid;

  form.validationSchema = decisionFormSchema;
  return (
    <Form>
      <div className={styles.container}>
        <p>Условия сотрудничества</p>
        <div className={styles.container__fields}>
          <Select
            name="typeOfPayment"
            values={paymentVariants}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('typeOfPayment', event.target.value);
            }}
            label={'Вид оплаты'}
            disabled={!checkForPristine}
            className={styles.form_field}
          />
          <Select
            name="workloadPerMonth"
            values={workloadVariants}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('workloadPerMonth', event.target.value);
            }}
            label={'Занятость в месяц'}
            disabled={!checkForPristine}
            className={classNames({
              [styles.hidden]: form.values.typeOfPayment !== TYPE_OF_PAYMENTS.MONTHLY,
            })}
          />
          <InputField
            name="payment"
            type="number"
            placeholder="Оплата ₽"
            disabled={!checkForPristine}
            className={classNames({
              [styles.hidden]: form.values.typeOfPayment === TYPE_OF_PAYMENTS.UNPAID,
            })}
          />
          <InputField
            name="message"
            type="text"
            placeholder="Текст сообщения"
            multiline
            maxRows={4}
          />
        </div>
        <ModalFooter>
          <div className={styles.container__fields__buttons}>
            <Button
              variant="text"
              className={styles.container__fields__buttons_cancel}
              onClick={handleCloseModal}>
              Отменить
            </Button>
            <Button variant="contained" type="submit" disabled={isDisabled}>
              {buttonLabel}
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Form>
  );
};

export const DecisionForm = withFormik<DecisionFormProps, DecisionFormValue>({
  displayName: 'DecisionForm',
  mapPropsToValues: (props) => {
    return {
      message: props.value.message,
      payment: props.value.payment,
      typeOfPayment: props.value.typeOfPayment,
      workloadPerMonth: props.value.workloadPerMonth,
    };
  },
  isInitialValid: true,
  enableReinitialize: true,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(Decision);
