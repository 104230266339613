import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import {
  useAcceptOfferMutation,
  useDenyOfferMutation,
  useGetPendingOffersQuery,
  useSuggestNewOfferConditionsMutation,
} from '@store/project/project.slice';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AgreeIcon } from '@assets/img/checkIcon.svg';
import { ReactComponent as DisagreeIcon } from '@assets/img/deleteRed.svg';
import { IconButton } from '@mui/material';
import styles from './Offers.module.scss';
import {
  DecisionModal,
  DecisionOnSubmitValues,
  decisionTextPresets,
} from '@components/DecisionModal';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { useUrlPagination } from '@hooks/useUrlPagination';
import { formatPaymentType } from '@pages/project/Vacancies/helpers';
import { ReactComponent as EditIcon } from '@assets/img/edit.svg';
import { TYPE_OF_PAYMENTS } from '@store/project/contracts';

export const Offers = () => {
  const dispatch = useAppDispatch();
  const { limit, page, handleChangePage, handleChangeRowsPerPage, start } = useUrlPagination({
    defaultLimit: 10,
    defaultPage: 1,
  });

  const { data: projects } = useGetPendingOffersQuery({
    limit: limit,
    start: start,
  });

  const [acceptOffer] = useAcceptOfferMutation();
  const [denyOffer] = useDenyOfferMutation();
  const [sendMessage] = useSuggestNewOfferConditionsMutation();

  const handleAgree = useCallback(
    async (values: DecisionOnSubmitValues) => {
      await acceptOffer({
        requestId: values.id,
      })
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        });
    },
    [acceptOffer, dispatch, closeModal],
  );

  const handleDisagree = useCallback(
    async (values: DecisionOnSubmitValues) => {
      await denyOffer({
        requestId: values.id,
      })
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        });
    },
    [dispatch, closeModal, denyOffer],
  );

  const showAgreeModal = useCallback(
    (row: any) => {
      dispatch(
        openModal(
          <DecisionModal
            onSubmit={handleAgree}
            values={{
              projectName: row.projectName,
              payment: row.payment,
              typeOfPayment: row.typeOfPayment,
              workloadPerMonth: row.workloadPerMonth,
              requestId: row?.id,
              employerName: row?.customerName,
            }}
            headerLabel="Сообщение работодателю"
            buttonLabel="Согласовать условия"
          />,
        ),
      );
    },
    [dispatch, openModal, handleAgree],
  );

  const showDisagreeModal = useCallback(
    (row: any) => {
      dispatch(
        openModal(
          <DecisionModal
            onSubmit={handleDisagree}
            values={{
              projectName: row.projectName,
              payment: row.payment,
              typeOfPayment: row.typeOfPayment,
              workloadPerMonth: row.workloadPerMonth,
              requestId: row?.id,
              employerName: row?.customerName,
            }}
            headerLabel="Сообщение работодателю"
            buttonLabel="Отклонить условия"
          />,
        ),
      );
    },
    [dispatch, openModal, handleDisagree],
  );

  const handleSuggestConditions = useCallback(
    async (values: DecisionOnSubmitValues) => {
      try {
        await sendMessage({
          requestId: values.id,
          payload: {
            ...values.payload,
            workloadPerMonth:
              values.payload.typeOfPayment !== TYPE_OF_PAYMENTS.MONTHLY
                ? 0
                : values.payload.workloadPerMonth,
            payment:
              values.payload.typeOfPayment === TYPE_OF_PAYMENTS.UNPAID ? 0 : values.payload.payment,
          },
        });
      } catch (err) {}
    },
    [sendMessage],
  );

  const showNewConditionsModal = useCallback(
    (row: any) => {
      dispatch(
        openModal(
          <DecisionModal
            onSubmit={handleSuggestConditions}
            values={{
              projectName: row.projectName,
              payment: row.payment,
              typeOfPayment: row.typeOfPayment,
              workloadPerMonth: row.workloadPerMonth,
              requestId: row?.id,
              employerName: row?.customerName,
            }}
            headerLabel="Сообщение соискателю"
            buttonLabel="Предложить новые условия"
            initialMessage={'Прошу согласовать новые условия'}
            shouldCheckForPristine
          />,
        ),
      );
    },
    [handleSuggestConditions, decisionTextPresets],
  );

  return (
    <AdaptiveTableMUI
      rows={projects?.data || []}
      columns={{
        startDate: {
          label: 'Начало переговоров',
        },
        projectName: {
          label: 'Проект',
          children: (value, row) => <Link to={`/projects/${row?.projectId}/details`}>{value}</Link>,
        },
        customerName: {
          label: 'Заказчик',
          children: (value, row) => <Link to={`/userPage/${row?.customerId}`}>{value}</Link>,
        },
        typeOfPayment: {
          label: 'Вид оплаты',
          children: (value, row) => (
            <>
              {formatPaymentType({
                typeOfPayment: value,
                hours: row?.workloadPerMonth,
              })}
            </>
          ),
        },
        payment: {
          label: 'Оплата',
        },
        status: {
          label: 'Статус',
        },
        id: {
          label: '',
          children: (_, row) => (
            <div className={styles.buttons}>
              <IconButton onClick={() => showAgreeModal(row)}>
                <AgreeIcon />
              </IconButton>
              <IconButton onClick={() => showDisagreeModal(row)}>
                <DisagreeIcon />
              </IconButton>
              <IconButton onClick={() => showNewConditionsModal(row)}>
                <EditIcon />
              </IconButton>
            </div>
          ),
        },
      }}>
      <TablePaginationMUI
        limit={limit}
        count={projects?.totalCount}
        entityLabel="проектов"
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
