import styles from '@pages/main/JobsWithoutRegistration.module.scss';
import { JobCharacteristicsForm } from '@components/JobCharacteristicsForm/JobCharacteristicsForm';
import { useAppSelector } from '@hooks/index';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';

export const JobsWithoutRegistrationExecutor = () => {
  const roles = useAppSelector(selectDictionary(DictionaryName.ROLE));

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div className={styles.main}>
          <div className={styles.settingSearch}>
            <JobCharacteristicsForm
              value={{
                start: 0,
                limit: 10,
                sortOrder: 'desc',
                stack: [],
                experienceFrom: 1,
                experienceTo: 2,
                hourPriceFrom: 0,
                hourPriceTo: 1000,
                monthPriceFrom: 1000,
                monthPriceTo: 5000,
                statusId: [],
                rating: 10,
                location: 'Russia',
                sortField: '',
                roleId: roles.find((role) => role.code === 'EXECUTOR')!.id,
              }}
              submit={() => {}}
            />
          </div>
          {/*Todo: добавить поля на бэке, которые будут принимать "Направления", "Стоимость" "Стек", затем сделать
       интеграцию
       */}
          <div className={styles.jobBox}>
            <div className="d-flex justify-content-center text-secondary fs-6 fw-bold">
              Нет доступных вакансий
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
