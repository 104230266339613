import React, { useCallback, useState } from 'react';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { PictureSelector } from '@components/PictureSelector/PictureSelector';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { closeModal, openModal, openModalError } from '@store/ui/ui.slice';
import {
  selectCurrentUserId,
  selectShortNameUser,
  useRemoveProfileIconMutation,
  useSetProfileMutation,
  useUploadProfilePhotoMutation,
} from '@store/user/user.slice';
import { useParams } from 'react-router-dom';
import { AvatarMenu } from './AvatarMenu';
import { AvatarImage } from './AvatarImage';
import { ProfileAboutMeFormRequest } from '@store/user/contracts';

export type AvatarProps = {
  iconUrl?: string;
  iconFile?: File;
  width?: number;
  height?: number;
  classNameImgAvatar?: string;
};

export const Avatar: React.FC<AvatarProps> = ({
  iconUrl,
  iconFile,
  width,
  height,
  classNameImgAvatar,
}) => {
  const { userID } = useParams();
  const [showOverlay, setShowOverlay] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const myShortName = useAppSelector(selectShortNameUser);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const [uploadProfileAvatar, { isLoading }] = useUploadProfilePhotoMutation();
  const [setProfile] = useSetProfileMutation();
  const [removeAvatar, { isLoading: loadDeleting }] = useRemoveProfileIconMutation();
  const myId = useAppSelector(selectCurrentUserId);

  const handleOverlay = useCallback(() => {
    if (!userID || userID === myId) {
      setShowOverlay(true);
    }
  }, [showOverlay]);

  const handleOverlayLeave = useCallback(() => {
    setShowOverlay(false);
  }, [showOverlay]);

  const handlePhotoHover = (event: React.SyntheticEvent<EventTarget>) => {
    if (!userID || userID === myId) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (value?: File) => {
    dispatch(
      openModal(
        <PictureSelector
          value={value}
          allowedExtensions={['image/png', 'image/jpeg', 'image/jpg', 'image/webp']}
          allowedSize={20_000_000}
          onSelect={handleFilesSelect}
        />,
      ),
    );
    handleMenuClose();
  };

  const handleFilesSelect = useCallback(
    async (value: { full: File; mini: File; roles: string[] }) => {
      try {
        const fileFormData = new FormData();
        fileFormData.append('file', value.full);
        await uploadProfileAvatar({ formData: fileFormData, tag: 'PROFILE' });

        const iconFormData = new FormData();
        iconFormData.append('file', value.mini);
        const iconRes = await uploadProfileAvatar({ formData: fileFormData, tag: 'ICON' });

        if ('data' in iconRes && !iconRes.data.id)
          throw new Error('Не удалось загрузить миниатюру');

        if ('data' in iconRes) {
          const profile = {
            icon: iconRes.data.id,
            roles: value.roles,
          } as ProfileAboutMeFormRequest;
          setProfile(profile);
        }
      } catch (e: any) {
        dispatch(openModalError(e));
      }
    },
    [dispatch],
  );

  const openDeletePhotoModal = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы уверены, что хотите удалить фотографию?"
          btnProp="Удалить"
          onSubmit={handleDeleteAvatar}>
          <div>Отменить это действие будет невозможно.</div>
        </ConfirmDeleteModal>,
      ),
    );
    handleMenuClose();
  }, [dispatch]);

  const handleDeleteAvatar = useCallback(() => {
    removeAvatar({});
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <div>
      <AvatarImage
        iconUrl={iconUrl}
        showOverlay={showOverlay}
        myShortName={myShortName || ''}
        handleOverlay={handleOverlay}
        handleOverlayLeave={handleOverlayLeave}
        handlePhotoHover={handlePhotoHover}
        userId={userID || null}
        loading={isLoading || loadDeleting}
        width={width}
        height={height}
        classNameImgAvatar={classNameImgAvatar}
      />
      <AvatarMenu
        iconUrl={iconUrl}
        iconFile={iconFile}
        open={open}
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        handleOpenModal={handleOpenModal}
        openDeletePhotoModal={openDeletePhotoModal}
      />
    </div>
  );
};
