import { Card } from 'react-bootstrap';
import { useCreateTaskMutation } from '@store/tasks/tracker.slice';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TaskForm, TaskFormProps } from './TaskForm';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import styles from './TaskForm.module.scss';

export const TaskCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createTask] = useCreateTaskMutation();

  const submitTask: TaskFormProps['submit'] = useCallback(
    async (TaskForm, _) => {
      try {
        await createTask({
          data: {
            ...TaskForm,
          },
        })
          .unwrap()
          .then(() => navigate(`/tasks`));
      } catch (error) {}
    },
    [dispatch],
  );

  return (
    <>
      <Card className="py-5 px-5 form-container mb-5">
        <div className={styles.form__container}>
          <div className={styles.form__container__title}>
            <h2 className="form-title m-0">Новая задача</h2>
          </div>
          <div className={styles.form__container__fields}>
            <TaskForm
              value={{
                projectId: '',
                description: '',
                name: '',
                priority: null,
                type: null,
                queue: null,
                tags: [],
                deadline: null,
                estimation: null,
                executors: [],
                provider: null,
                statusId: null,
              }}
              submit={submitTask}
            />
            <RequiredFieldsIndicator />
          </div>
        </div>
      </Card>
    </>
  );
};
