import React, { useMemo, useCallback } from 'react';
import { Box, CircularProgress, FormControl, MenuItem } from '@mui/material';
import { withFormik } from 'formik';
import { TaskTransition } from '@store/tasks/contracts';
import { FormikObserver } from '@helpers/FormikObserver';
import { Select } from '@components/MUI/Select';
import { SelectOption } from '@components/ui/Select/Select';
import styles from './TaskSelectTransitions.module.scss';

type currentStatus = {
  id: string;
  key: string;
  display: string;
};

type TaskSelectTransitionsProps = {
  transitions: TaskTransition[];
  currentStatus: currentStatus;
  onChange: (transition: TaskTransition) => void;
  isLoading: boolean;
};

type TaskTransitionValue = {
  transitionId: string;
};

const FormComponent: React.FC<TaskSelectTransitionsProps> = ({
  transitions,
  currentStatus,
  onChange,
  isLoading,
}) => {
  const options = useMemo(() => {
    return transitions.map((status) => ({
      value: status.id ?? '',
      label: status.display ?? '',
    }));
  }, [transitions]);

  const handleChange = useCallback(
    (values: TaskTransitionValue) => {
      const result = transitions.find((el) => el.id === values.transitionId);
      if (result) {
        onChange(result);
      }
    },
    [onChange, transitions],
  );

  function renderOption(options: SelectOption[]) {
    if (isLoading) {
      return <CircularProgress />;
    }
    return [
      ...options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ borderTop: '1px solid #DCDCDC' }}
          style={{ padding: '16px 11px 15px', minWidth: '241px' }}>
          {option.label}
        </MenuItem>
      )),
      <MenuItem value={currentStatus.key}>{currentStatus.display}</MenuItem>,
    ];
  }

  return (
    <Box
      sx={{
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
      <FormikObserver onChange={handleChange} />
      {currentStatus && <div className="color-gray fs-6 fw-normal">Статус задачи</div>}
      <FormControl
        size="small"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Select
            name="transitionId"
            values={options}
            fullWidth={false}
            customRenderOptions={renderOption}
            classNameSelect={styles.selectTransitionTask}
          />
        )}
      </FormControl>
    </Box>
  );
};

export const TaskSelectTransitionsForm = withFormik<
  TaskSelectTransitionsProps,
  TaskTransitionValue
>({
  displayName: 'TaskSelectTransitions',
  mapPropsToValues: (props) => {
    return {
      transitionId: props.currentStatus.key || '',
    };
  },
  enableReinitialize: true,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: () => {},
})(FormComponent);
