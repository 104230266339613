import React, { useMemo } from 'react';
import { ReactComponent as EditIconGreen } from '@assets/img/editIconGreen.svg';
import { Button } from '@components/MUI/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from '@components/Avatar/Avatar';
import styles from './ProfileMain.module.scss';
import { useGetFile } from '@hooks/useGetFile';

type ProfileMainProps = {
  icon?: string;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  direction?: string;
  phones?: string[];
  email?: string;
  telegram?: string;
  isFavorite?: boolean;
};

export const ProfileMain: React.FC<ProfileMainProps> = ({
  icon,
  firstName,
  lastName,
  patronymic,
  direction,
  phones,
  email,
  telegram,
}) => {
  const navigate = useNavigate();
  const { userID } = useParams();
  const avatarFile = useGetFile(icon);

  const fullName = useMemo(() => {
    const input = [firstName, lastName, patronymic];
    return input.filter(Boolean).join(' ').trim();
  }, [firstName, lastName, patronymic]);

  return (
    <div className={styles.main__info}>
      <div className={styles.main__info__profile}>
        <Avatar iconUrl={icon} iconFile={avatarFile || new File([], '')} />
        <div className={styles.main__info__content}>
          <div className={styles.main__info__top}>
            <div>
              <div className="d-flex">
                <h5 className="m-0 p-0">
                  <b className={styles.main__info__name}>{fullName}</b>
                </h5>
              </div>
              <div className={styles.main__info__role}>
                <p className="m-0 p-0 color-gray">{direction ? direction : '-'}</p>
              </div>
            </div>
          </div>

          <p className="mt-3 m-0 p-0 color-gray">Телефон</p>
          <span style={{ fontWeight: 500 }}>{phones ? phones[0] : '-'}</span>
          <div className="d-flex mt-3">
            <div className="d-flex flex-column">
              <p className="m-0 p-0 color-gray">Почта</p>
              <a style={{ fontWeight: 500 }} href={`mailto:${email}`}>
                {email ? email : '-'}
              </a>
            </div>
            <div className={styles.main__info__telegram}>
              <p className="m-0 p-0 color-gray">Telegram</p>
              <a style={{ fontWeight: 500 }} href={`https://t.me/${telegram?.replace('@', '')}`}>
                {telegram !== null ? telegram : '-'}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container__edit__button}>
        {userID ? (
          <></>
        ) : (
          <Button
            variant="outlined"
            className={styles.edit__button}
            startIcon={<EditIconGreen />}
            onClick={() => navigate('/edit')}>
            Редактировать &nbsp;
            <span className={styles.edit__text}> профиль</span>
          </Button>
        )}
      </div>
    </div>
  );
};
