import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import * as Yup from 'yup';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { Paper, SelectChangeEvent } from '@mui/material';
import { Select } from '@components/MUI/Select';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import styles from './ProfileWorkForm.module.scss';

type ProfileWorkFormValue = {
  workStatusId: string;
  hourPrice: number;
  monthPrice?: number;
  currency?: string;
  manager?: boolean;
};

export type ProfileFormWorkProps = {
  value: ProfileWorkFormValue;
  submit: (
    value: ProfileWorkFormValue,
    form: FormikBag<ProfileFormWorkProps, ProfileWorkFormValue>,
  ) => void;
};

const ProfileFormWorkSchema = Yup.object({
  workStatusId: Yup.string().required('Поле обязательно для заполнения'),
  hourPrice: Yup.number().min(0).required('Поле обязательно для заполнения'),
  monthPrice: Yup.number().min(0).required('Поле обязательно для заполнения'),
  currency: Yup.string().required('Поле обязательно для заполнения'),
});

const FormComponentProfileWork = () => {
  const profileWorkForm = useFormikContext<ProfileWorkFormValue>();
  const workStatuses = useDictionary(DictionaryName.WORK_STATUS, fromDictionaryToSelectOption);
  const currencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption);

  profileWorkForm.validationSchema = ProfileFormWorkSchema;

  return (
    <Paper elevation={0} className={styles.profile__work__wrapper} variant="outlined">
      <div className={styles.profile__work__title}>
        <h3>Работа</h3>
      </div>
      <Form className={styles.profile__work__form}>
        <div className="d-flex flex-column">
          <Select
            name="workStatusId"
            values={workStatuses}
            isOutlined={true}
            label={'Статус'}
            onChange={(event: SelectChangeEvent) => {
              profileWorkForm.setFieldValue('workStatusId', event.target.value);
            }}
            className={styles.form_field}
            value={profileWorkForm?.values?.workStatusId}
          />

          <InputField
            name="hourPrice"
            type="number"
            placeholder="Стоимость часа работы ₽"
            className={styles.form_field}
          />

          <InputField
            name="monthPrice"
            type="number"
            placeholder="Стоимость месяца работы"
            className={styles.form_field}
          />

          <Select
            name="currency"
            values={currencies}
            isOutlined={true}
            label={'Валюта'}
            onChange={(event: SelectChangeEvent) => {
              profileWorkForm.setFieldValue('currency', event.target.value);
            }}
            disabled
            className={styles.form_field}
            value={profileWorkForm?.values?.currency}
          />
        </div>
        <Button type="submit" variant="contained" disabled={!profileWorkForm.isValid} size="large">
          Сохранить
        </Button>
        <RequiredFieldsIndicator className={styles.footer} />
      </Form>
      <div></div>
    </Paper>
  );
};

export const ProfileWorkForm = withFormik<ProfileFormWorkProps, ProfileWorkFormValue>({
  displayName: 'ProfileWorkForm',
  mapPropsToValues: (props) => {
    return {
      workStatusId: props.value.workStatusId,
      hourPrice: props.value.hourPrice,
      monthPrice: props.value.monthPrice,
      currency: props.value.currency,
      manager: props.value.manager,
    };
  },
  enableReinitialize: true,
  validationSchema: ProfileFormWorkSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponentProfileWork);
