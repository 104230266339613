import styles from '@pages/main/JobsWithoutRegistration.module.scss';
import { JobCharacteristicsForm } from '@components/JobCharacteristicsForm/JobCharacteristicsForm';
export const JobsWithoutRegistrationCustomer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div className={styles.main}>
          <div className={styles.settingSearch}>
            <JobCharacteristicsForm
              value={{
                start: 0,
                limit: 10,
                sortOrder: 'desc',
                stack: [],
                experienceFrom: 1,
                experienceTo: 2,
                hourPriceFrom: 0,
                hourPriceTo: 1000,
                monthPriceFrom: 1000,
                monthPriceTo: 5000,
                statusId: [],
                rating: 10,
                location: 'Russia',
                sortField: '',
                roleId: 'customer',
              }}
              submit={() => {}}
            />
          </div>
          <div className={styles.jobBox}>
            <div className="d-flex justify-content-center text-secondary fs-6 fw-bold">
              Нет доступных вакансий
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
