import { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useAppDispatch } from '@hooks/index';
import { getCloudInstanceResource, removeCloudInstanceResource } from '@store/cloud/cloud.service';
import { CloudInstanceResource } from '@store/cloud/contracts';
import styles from './InstanceResourceForm.module.scss';
import { ReactComponent as EditIcon } from '@assets/img/pencil.svg';
import { ReactComponent as EyeIcon } from '@assets/img/eye.svg';
import { CardButton } from '@components/ui/CardButton';
import { CircularProgress } from '@mui/material';
import { closeModal, openModal, openModalError } from '@store/ui/ui.slice';
import { convertBitsToGigabytes } from '@store/docs/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as DeleteIcon } from '@assets/img/wastebasket.svg';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';

const InstanceResourceDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instanceResourceId } = useParams();
  const fetched = useRef(false);
  const [instanceResource, setInstanceResource] = useState<CloudInstanceResource>();
  const memoryInGB = convertBitsToGigabytes(instanceResource?.memory || 0);
  const diskSizeInGB = convertBitsToGigabytes(instanceResource?.diskSize || 0);

  const handleCloseModal = useCallback(async () => {
    if (instanceResourceId !== undefined) await removeCloudInstanceResource(instanceResourceId);
    dispatch(closeModal());
    navigate(`/clouds`);
  }, [dispatch]);

  const deleteInstance = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите виртуальную машину?"
          btnProp="Удалить"
          onSubmit={handleCloseModal}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!fetched.current && instanceResourceId) {
      (async () => {
        try {
          const item = await getCloudInstanceResource(instanceResourceId);
          setInstanceResource(item);
        } catch (e: any) {
          dispatch(openModalError(e));
        } finally {
          fetched.current = true;
        }
      })();
    }
  }, [dispatch, instanceResourceId]);

  return (
    <div className={`col common_bc container-content ${styles.containerContent}`}>
      {fetched.current ? (
        <>
          <div className="mt-4 d-flex gap-2">
            <NavigationBack back={{ to: '/clouds' }} />
          </div>
          <Card className={styles.card}>
            {!instanceResource?.deployment &&
              (instanceResource?.status == null || instanceResource?.status?.code === -2) && (
                <div className={`row ${styles.titleInstanceName}`}>
                  <div className={`col ${styles.blockMenu}`}>
                    <h2 className={styles.title}>{instanceResource?.name}</h2>
                    <CardButton
                      title="Редактировать"
                      icon={<EditIcon />}
                      onClick={() => navigate(`/clouds/${instanceResourceId}/edit`)}
                    />
                    <CardButton title="Удалить" icon={<DeleteIcon />} onClick={deleteInstance} />
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-12 col-lg-6">
                <Card className={styles.cardContent}>
                  <div className="mb-4">
                    <div className="color-gray">Название</div>
                    <div className="fw-bold">{instanceResource?.name}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Инфраструктура</div>
                    <div className="fw-bold">{instanceResource?.infrastructure?.name}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Платформа</div>
                    <div className="fw-bold">{instanceResource?.platform?.resourceName}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Зона</div>
                    <div className="fw-bold">{instanceResource?.zone?.resourceName}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Виртуальная машина</div>
                    <div className="fw-bold">{instanceResource?.instance?.resourceName}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Прерываемая</div>
                    <div className="fw-bold">{instanceResource?.preemptible ? 'Да' : 'Нет'}</div>
                  </div>

                  <div className="mb-4">
                    <div className="color-gray">Шаблон</div>
                    <div className="fw-bold">{instanceResource?.instanceParams?.name}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Правила развертывания</div>
                    <div className="fw-bold">{instanceResource?.deployment?.name}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Логин</div>
                    <div className="fw-bold">
                      {instanceResource?.login}
                      <CopyToClipboard
                        text={instanceResource?.login ? instanceResource?.login : 'no login'}>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Пароль</div>
                    <div className="fw-bold">
                      {instanceResource?.password}
                      <CopyToClipboard
                        text={
                          instanceResource?.password ? instanceResource?.password : 'no password'
                        }>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-12 col-lg-6">
                <Card className="p-4 mb-4 h-100">
                  <div className="mb-4">
                    <div className="color-gray">Число ядер процессора</div>
                    <div className="fw-bold">{instanceResource?.cores}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Оперативная память</div>
                    <div className="fw-bold">{memoryInGB} Gb</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Жесткий диск</div>
                    <div className="fw-bold">{diskSizeInGB} Gb</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Образ машины</div>
                    <div className="fw-bold">{instanceResource?.image?.imageName}</div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">IP адрес</div>
                    <div className="fw-bold">
                      {instanceResource?.ipAddress}
                      <CopyToClipboard
                        text={
                          instanceResource?.ipAddress
                            ? instanceResource?.ipAddress
                            : 'no ip address'
                        }>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Строка соединения</div>
                    <div className="fw-bold">
                      {instanceResource?.ipAddress
                        ? 'ssh ' + instanceResource?.login + '@' + instanceResource?.ipAddress
                        : 'не доступна'}
                      <CopyToClipboard
                        text={
                          instanceResource?.ipAddress
                            ? 'ssh ' + instanceResource?.login + '@' + instanceResource?.ipAddress
                            : 'не доступна'
                        }>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Удаленный адрес</div>
                    <div className="fw-bold">
                      {instanceResource?.remoteName}
                      <CopyToClipboard
                        text={
                          instanceResource?.remoteName ? instanceResource?.remoteName : 'no address'
                        }>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Внутренний IP адрес</div>
                    <div className="fw-bold">
                      {instanceResource?.ipInnerAddress}
                      <CopyToClipboard
                        text={
                          instanceResource?.ipInnerAddress
                            ? instanceResource?.ipInnerAddress
                            : 'no address'
                        }>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="color-gray">Внутренний FQDN</div>
                    <div className="fw-bold">
                      {instanceResource?.fqdn}
                      <CopyToClipboard
                        text={instanceResource?.fqdn ? instanceResource?.fqdn : 'no address'}>
                        <CardButton title="Копировать" icon={<EyeIcon />} onClick={() => {}} />
                      </CopyToClipboard>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Card>
        </>
      ) : (
        <div className={styles.loadingBlock}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export { InstanceResourceDetail };
