import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export function useUrlParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = useCallback(
    (key: string, defaultValue: any = null): any => {
      const value = searchParams.get(key);
      return value !== null ? value : defaultValue;
    },
    [searchParams],
  );

  const updateParams = useCallback(
    (newParams: Record<string, any>) => {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        Object.entries(newParams).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            updatedParams.set(key, value.toString());
          } else {
            updatedParams.delete(key);
          }
        });
        return updatedParams;
      });
    },
    [setSearchParams],
  );

  return {
    getParam,
    updateParams,
  };
}
