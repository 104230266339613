import { InputAdornment, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FavoriteMessageItem } from './FavoriteMessageItem';
import { ReactComponent as SearchGray } from '@assets/img/searchGray.svg';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import styles from './FavorieMessages.module.scss';

export const FavoriteMessages: React.FC = () => {
  const [limit, setLimit] = useState<number>(10);
  const [favoriteMessages] = useState([]);
  const [page, setPage] = useState(1);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <>
      <Paper elevation={0} variant="outlined" className={styles.wrapper}>
        <TextField
          size="small"
          placeholder="Поиск"
          className={styles.search__message}
          onChange={() => {}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchGray />
              </InputAdornment>
            ),
          }}
        />
        <ul className={styles.message__list}>
          {favoriteMessages?.map((message: any) => (
            <FavoriteMessageItem
              key={message.id}
              id={message.id}
              authorName={message.author.userName}
              autorId={message.author.userId}
              messageText={message.text}
              sendDate={message.date}
              refetch={() => {}}
              isLastMessage={false}
              totalMessages={0}
              chatId={message.chatId}
            />
          ))}
        </ul>
      </Paper>
      <TablePaginationMUI
        limit={limit}
        count={0}
        entityLabel="сообщений"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
