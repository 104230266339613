import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useAppDispatch } from '@hooks/index';
import { useRequestBody } from '@hooks/useRequestBody';
import { Star } from '@components/Star/Star';
import upload from '@assets/img/upload.svg';
import { setDocumentsCount } from '@store/favorite/favorite.slice';
import { Document } from '@store/docs/contracts';
import { downloadFile } from '@store/docs/utils';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetDocsFavoriteQuery, useSetDocsFavoriteMutation } from '@store/docs/docs.slice';

export const FavoriteDocuments: React.FC = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [setDocsFavorite] = useSetDocsFavoriteMutation();

  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: docsFavorites } = useGetDocsFavoriteQuery({
    limit: limit,
    start: (page - 1) * limit,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div style={{ width: '100%' }}>
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={{
          name: {
            label: 'Вложения',
            filterInput: true,
            isSortColumn: false,
          },
          projectName: {
            label: 'Проект',
            children: (value, row) => {
              return <NavLink to={`/projects/${row.projectId}/details`}>{value}</NavLink>;
            },
          },
          star: {
            children: (_, row: Document) => (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Star
                  checked={true}
                  onChange={() =>
                    setDocsFavorite({ docId: row.id }).then(() =>
                      dispatch(
                        setDocumentsCount(
                          docsFavorites?.totalCount ? docsFavorites.totalCount - 1 : 0,
                        ),
                      ),
                    )
                  }
                />
                <div>
                  <button onClick={() => downloadFile(row.id)} style={{ marginRight: '0.5rem' }}>
                    <img src={upload} alt="upload" />
                  </button>
                </div>
              </div>
            ),
          },
        }}
        rows={docsFavorites?.data || []}
        emptyLabel={'Документов в избранном нет'}>
        <TablePaginationMUI
          limit={limit}
          count={docsFavorites?.totalCount}
          entityLabel="документов"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </div>
  );
};
