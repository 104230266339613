import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTemplateRequest,
  ProjectWithCloud,
  Template,
  PureCloudResource,
  CloudInfrastructure,
  ItemCloudDelegationRequest,
  ItemCloudInfrastructureRequest,
  ItemCloudDeploymentResourceRequest,
  ItemCloudInstanceResourceRequest,
  ItemCloudScheduleRequest,
  CloudDelegation,
  CloudDeploymentRule,
  CloudInstanceResource,
  CloudImageResource,
  CloudSchedule,
  CloudDeploymentAction,
  CloudDeploymentResource,
  ResourceRequest,
  InfrastructureNotification,
  InstanceNotification,
  DeploymentNotification,
} from './contracts';
import * as CloudService from '@store/cloud/cloud.service';
import { PaginationQuery } from '@store/common';
import { RootState } from '@store/index';
import { apiErrorHandler } from '@store/api-client';
import { enqueueSnackbar } from 'notistack';

import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const PROJECT_STATUS_SUCCESS = 'Выделены права для сервисного аккаунта';

type CloudState = {
  instances: PureCloudResource[];
  templates: Template[];
  projects: ProjectWithCloud[];
  organizations: PureCloudResource[];
  clouds: PureCloudResource[];
  accounts: PureCloudResource[];
  folders: PureCloudResource[];
  networks: PureCloudResource[];
  infrastructures: CloudInfrastructure[];
  delegations: CloudDelegation[];
  deploymentRules: CloudDeploymentRule[];
  images: CloudImageResource[];
  schedules: CloudSchedule[];
  deploymentActions: CloudDeploymentAction[];
  deployments: CloudDeploymentResource[];
  cloudInstances: CloudInstanceResource[];
  zones: PureCloudResource[];
  platforms: PureCloudResource[];
  instanceGroups: PureCloudResource[];
};

const initialState: CloudState = {
  instances: [],
  templates: [],
  projects: [],
  organizations: [],
  clouds: [],
  accounts: [],
  folders: [],
  networks: [],
  infrastructures: [],
  delegations: [],
  deploymentRules: [],
  images: [],
  schedules: [],
  deploymentActions: [],
  deployments: [],
  cloudInstances: [],
  zones: [],
  platforms: [],
  instanceGroups: [],
};

const cloudSlice = createSlice({
  name: 'cloud',
  initialState,
  reducers: {
    setInstances: (state, action: PayloadAction<{ instances: PureCloudResource[] }>) => {
      state.instances = [...action.payload.instances];
    },
    setTemplates: (state, action: PayloadAction<{ templates: Template[] }>) => {
      state.templates = [...action.payload.templates].map((template) => ({
        ...template,
        memory: Math.ceil(template.memory / 1024 / 1024 / 1024),
        diskSize: Math.ceil(template.diskSize / 1024 / 1024 / 1024),
      }));
    },
    setProjects: (state, action: PayloadAction<{ projects: ProjectWithCloud[] }>) => {
      state.projects = [...action.payload.projects].filter(
        (project) => project.status === PROJECT_STATUS_SUCCESS,
      );
    },
    setOrganizations: (state, action: PayloadAction<{ organizations: PureCloudResource[] }>) => {
      state.organizations = [...action.payload.organizations];
    },
    setClouds: (state, action: PayloadAction<{ clouds: PureCloudResource[] }>) => {
      state.clouds = [...action.payload.clouds];
    },
    setBillingAccounts: (state, action: PayloadAction<{ accounts: PureCloudResource[] }>) => {
      state.accounts = [...action.payload.accounts];
    },
    setFolders: (state, action: PayloadAction<{ folders: PureCloudResource[] }>) => {
      state.folders = [...action.payload.folders];
    },
    setNetworks: (state, action: PayloadAction<{ networks: PureCloudResource[] }>) => {
      state.networks = [...action.payload.networks];
    },
    setInfrastuctures: (
      state,
      action: PayloadAction<{ infrastructures: CloudInfrastructure[] }>,
    ) => {
      state.infrastructures = [...action.payload.infrastructures];
    },
    setDelegations: (state, action: PayloadAction<{ delegations: CloudDelegation[] }>) => {
      state.delegations = [...action.payload.delegations];
    },
    setDeploymentRules: (
      state,
      action: PayloadAction<{ deploymentRules: CloudDeploymentRule[] }>,
    ) => {
      state.deploymentRules = [...action.payload.deploymentRules];
    },
    setImages: (state, action: PayloadAction<{ images: CloudImageResource[] }>) => {
      state.images = [...action.payload.images];
    },
    setSchedules: (state, action: PayloadAction<{ schedules: CloudSchedule[] }>) => {
      state.schedules = [...action.payload.schedules];
    },
    setDeploymentActions: (
      state,
      action: PayloadAction<{ deploymentActions: CloudDeploymentAction[] }>,
    ) => {
      state.deploymentActions = [...action.payload.deploymentActions];
    },
    setCloudInstances: (
      state,
      action: PayloadAction<{ cloudInstances: CloudInstanceResource[] }>,
    ) => {
      state.cloudInstances = [...action.payload.cloudInstances].map((cloudInstance) => ({
        ...cloudInstance,
        memory: Math.ceil(cloudInstance.memory / 1024 / 1024 / 1024),
        diskSize: Math.ceil(cloudInstance.diskSize / 1024 / 1024 / 1024),
      }));
    },
    setZones: (state, action: PayloadAction<{ zones: PureCloudResource[] }>) => {
      state.zones = [...action.payload.zones];
    },
    setPlatforms: (state, action: PayloadAction<{ platforms: PureCloudResource[] }>) => {
      state.platforms = [...action.payload.platforms];
    },
    setInstanceGroups: (state, action: PayloadAction<{ instanceGroups: PureCloudResource[] }>) => {
      state.instanceGroups = [...action.payload.instanceGroups];
    },
  },
});

export const loadTemplates = createAsyncThunk(
  'cloud/templates/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getTemplateList(request);
    await dispatch(setTemplates({ templates: result.data }));
  },
);

export const createTemplate = createAsyncThunk(
  'cloud/templates/create',
  async (request: CreateTemplateRequest) => {
    try {
      const response = await CloudService.createTemplate({
        name: request.name,
        memory: request.memory * 1024 * 1024 * 1024,
        cores: request.cores,
        diskSize: request.diskSize * 1024 * 1024 * 1024,
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editTemplate = createAsyncThunk('cloud/templates/edit', async () => {
  try {
    const response = await CloudService.editTemplate();
    return response;
  } catch (error) {
    apiErrorHandler(error);
  }
});

export const selectTemplates = (state: RootState) => state.cloud.templates;

export const selectTemplateOptions = (state: RootState) => {
  return state.cloud.templates.map((template) => ({
    value: template.id,
    label: template.name,
  }));
};

export const selectDetailTemplate = createSelector(
  (state: RootState, templateId: string) => state.cloud.templates.find((t) => t.id === templateId),
  (template) => template,
);

export const loadOrganizations = createAsyncThunk(
  'cloud/organizations/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getOrganizationsWithClouds(params.delegationId);
    await dispatch(setOrganizations({ organizations: result }));
  },
);

export const selectOrganizationOptions = (state: RootState) =>
  state.cloud.organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }));

export const loadClouds = createAsyncThunk(
  'cloud/clouds/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getCloudsWithClouds(params.delegationId, params.parentId);
    await dispatch(setClouds({ clouds: result }));
  },
);

export const selectCloudOptions = (state: RootState) => {
  const options = state.cloud.clouds.map((cloud) => ({
    value: cloud.id,
    label: cloud.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};
/*
  state.cloud.clouds.map((cloud) => ({
    value: cloud.id,
    label: cloud.name,
  }));
*/
export const loadFolders = createAsyncThunk(
  'cloud/folders/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setFolders({ folders: [] }));
      return;
    }
    const result = await CloudService.getFoldersWithClouds(params.delegationId, params.parentId);
    await dispatch(setFolders({ folders: result }));
  },
);

export const selectFolderOptions = (state: RootState) => {
  const options = state.cloud.folders.map((folder) => ({
    value: folder.id,
    label: folder.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadNetworks = createAsyncThunk(
  'cloud/network/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setNetworks({ networks: [] }));
      return;
    }
    const result = await CloudService.getNetworksWithClouds(params.delegationId, params.parentId);
    await dispatch(setNetworks({ networks: result }));
  },
);

export const selectNetworkOptions = (state: RootState) => {
  const options = state.cloud.networks.map((network) => ({
    value: network.id,
    label: network.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadInstances = createAsyncThunk(
  'cloud/instance/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setInstances({ instances: [] }));
      return;
    }
    const result = await CloudService.getInstancesWithClouds(
      params.infrastructureId,
      params.parentId,
    );
    await dispatch(setInstances({ instances: result }));
  },
);

export const selectInstanceOptions = (state: RootState) => {
  const options = state.cloud.instances.map((instance) => ({
    value: instance.id,
    label: instance.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadBillingAccounts = createAsyncThunk(
  'cloud/account/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getBillingAccountsWithClouds(params.delegationId);
    await dispatch(setBillingAccounts({ accounts: result }));
  },
);

export const selectBillingAccountOptions = (state: RootState) =>
  state.cloud.accounts.map((account) => ({
    value: account.id,
    label: account.name,
  }));

export const loadInfrastructures = createAsyncThunk(
  'cloud/infrastructure/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudInfrastructureList(request);
    await dispatch(setInfrastuctures({ infrastructures: result.data }));
  },
);

export const selectInfrastructures = (state: RootState) => state.cloud.infrastructures;
export const selectInfrastructureOptions = (state: RootState) =>
  state.cloud.infrastructures.map((infrastructure) => ({
    value: infrastructure.id,
    label: infrastructure.name,
  }));

export const selectDetailInfrastructure = createSelector(
  (state: RootState, infrastructureId: string) =>
    state.cloud.infrastructures.find((i) => i.id === infrastructureId),
  (infrastructure) => infrastructure,
);

export const loadCloudDelegations = createAsyncThunk(
  'cloud/delegation/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDelegationList(request);
    await dispatch(setDelegations({ delegations: result.data }));
  },
);

/*
export const selectCloudDelegations = (state: RootState) => state.cloud.delegations;
export const selectCloudDelegationOptions = (state: RootState) => {
  const allDelegationOptions = state.cloud.delegations.map((delegation) => ({
    value: delegation.id,
    label: delegation.name,
  }));
  allDelegationOptions.push({ label: 'Без основания', value: '' });
  return allDelegationOptions;
};
*/
export const selectParentCloudDelegations = (id?: string, parentId?: string) => {
  if (id === undefined || id === null) id = '';
  if (parentId === undefined || parentId === null) parentId = '';
  return (state: RootState) =>
    state.cloud.delegations.filter(
      (d) =>
        parentId === d.id ||
        (d.id !== id &&
          d.parent === null &&
          d?.distributed !== undefined &&
          d?.budget !== undefined &&
          d?.distributed < d?.budget &&
          !d.closed),
    );
};

export const selectParentCloudDelegationOptions = (id?: string, parentId?: string) => {
  if (id === undefined || id === null) id = '';
  if (parentId === undefined || parentId === null) parentId = '';
  return (state: RootState) => {
    const allDelegationOptions = state.cloud.delegations
      .filter(
        (d) =>
          parentId === d.id ||
          (d.id !== id &&
            d.parent === null &&
            d?.distributed !== undefined &&
            d?.budget !== undefined &&
            d?.distributed < d?.budget &&
            !d.closed),
      )
      .map((delegation) => ({
        value: delegation.id,
        label: delegation.name,
      }));
    allDelegationOptions.push({ label: 'Без основания', value: '' });
    return allDelegationOptions;
  };
};

export const selectOpenedCloudDelegations = (id?: string) => {
  if (id === undefined || id === null) id = '';
  return (state: RootState) =>
    state.cloud.delegations.filter(
      (d) =>
        id === d.id ||
        (d?.distributed !== undefined &&
          d?.budget !== undefined &&
          d?.distributed === 0 &&
          !d.closed),
    );
};

export const selectOpenedCloudDelegationOptions = (id?: string) => {
  if (id === undefined || id === null) id = '';

  return (state: RootState) => {
    const allDelegationOptions = state.cloud.delegations
      .filter(
        (d) =>
          id === d.id ||
          (d?.distributed !== undefined &&
            d?.budget !== undefined &&
            d?.distributed === 0 &&
            !d.closed),
      )
      .map((delegation) => ({
        value: delegation.id,
        label: delegation.name,
      }));
    allDelegationOptions.push({ label: 'Без основания', value: '' });
    return allDelegationOptions;
  };
};

export const createDelegation = createAsyncThunk(
  'cloud/delegation/create',
  async (request: ItemCloudDelegationRequest) => {
    try {
      const response = await CloudService.saveCloudDelegation({
        name: request.name,
        parentId: request.parentId,
        organization: request.organization,
        cloud: request.cloud,
        billingAccount: request.billingAccount,
        projectId: request.projectId,
        user: request.user,
        isEnabledCreateInfrastructure: request.isEnabledCreateInfrastructure,
        allocateBudget: request.allocateBudget,
        currencyId: request.currencyId,
        budget: request.budget,
        budgetLimitAction: request.budgetLimitActionId,
        resetBudgetType: request.resetBudgetType,
        //@ts-ignore
        budgetEndDate: request.budgetEndDate,
      });
      enqueueSnackbar(`Делегирование успешно создано`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editDelegation = createAsyncThunk(
  'cloud/delegation/create',
  async (request: ItemCloudDelegationRequest) => {
    try {
      const response = await CloudService.saveCloudDelegation({
        id: request.id,
        name: request.name,
        parentId: request.parentId,
        organization: request.organization,
        cloud: request.cloud,
        billingAccount: request.billingAccount,
        projectId: request.projectId,
        user: request.user,
        isEnabledCreateInfrastructure: request.isEnabledCreateInfrastructure,
        allocateBudget: request.allocateBudget,
        currencyId: request.currencyId,
        budget: request.budget,
        budgetLimitAction: request.budgetLimitActionId,
        resetBudgetType: request.resetBudgetType,
        budgetEndDate: request.budgetEndDate, // ?.format('DD-MM-YYYY')
      });
      enqueueSnackbar(`Делегирование успешно отредактировано`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createInfrastructure = createAsyncThunk(
  'cloud/infrastucture/create',
  async (request: ItemCloudInfrastructureRequest) => {
    try {
      const response = await CloudService.saveCloudInfrastructure({
        name: request.name,
        organization: request.organization,
        cloud: request.cloud,
        folder: request.folder,
        network: request.network,
        serviceAccount: request.serviceAccount,
        delegationId: request.delegationId,
      });
      enqueueSnackbar(`Инфраструктура успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editInfrastructure = createAsyncThunk(
  'cloud/infrastucture/edit',
  async (request: ItemCloudInfrastructureRequest) => {
    try {
      const response = await CloudService.saveCloudInfrastructure({
        id: request.id,
        name: request.name,
        organization: request.organization,
        cloud: request.cloud,
        folder: request.folder,
        network: request.network,
        serviceAccount: request.serviceAccount,
        delegationId: request.delegationId,
      });
      enqueueSnackbar(`Инфраструктура отредактирована`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createDeploymentResource = createAsyncThunk(
  'cloud/deployment/create',
  async (request: ItemCloudDeploymentResourceRequest) => {
    try {
      const response = await CloudService.saveCloudDeploymentResource({
        id: request.id,
        name: request.name,
        infrastructureId: request.infrastructureId,
        instanceParamsId: request.instanceParamsId,
        instances: request.instances,
        ruleId: request.ruleId,
        numberInstance: request.numberInstance,
        useMasterInstance: request.useMasterInstance,
        masterInstanceId: request.masterInstanceId,
        imageId: request.imageId,
        sheduleId: request.sheduleId,
        startActionId: request.startActionId,
        endActionId: request.endActionId,
        zone: request.zone,
        platform: request.platform,
        preemptible: request.preemptible,
        instanceGroup: request.instanceGroup,
        positionId: request.positionId,
      });
      enqueueSnackbar(`Правило развертывания успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createInstanceResource = createAsyncThunk(
  'cloud/instance/create',
  async (request: ItemCloudInstanceResourceRequest) => {
    try {
      const response = await CloudService.saveCloudInstanceResource({
        id: request?.id,
        name: request.name,
        instance: request.instance,
        infrastructureId: request.infrastructureId,
        deploymentId: request.deploymentId,
        cores: request.cores,
        memory: request.memory * 1024 * 1024 * 1024,
        owners: request.owners,
        statusId: request.statusId,
        imageId: request.imageId,
        diskSize: request.diskSize * 1024 * 1024 * 1024,
        zone: request.zone,
        platform: request.platform,
        login: request.login,
        password: request.password,
        ipAddress: request.ipAddress,
        instanceParamsId: request.instanceParamsId,
        remoteName: request.remoteName,
        preemptible: request.preemptible,
      });
      enqueueSnackbar(`Виртуальная машина успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const loadCloudDeploymentRules = createAsyncThunk(
  'cloud/deployment/rule/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDeploymentRuleList(request);
    await dispatch(setDeploymentRules({ deploymentRules: result.data }));
  },
);

/*
export const selectDeploymentRules = (state: RootState) =>
  state.cloud.deploymentRules.filter(
    (r) =>
      r.id !== 'a920d465-0393-4337-b947-7ec1c6e74c2d' &&
      r.id !== 'b54eca12-cb16-4842-b979-b30a915d4380',
  );
*/
export const selectDeploymentRuleOptions = (state: RootState) =>
  state.cloud.deploymentRules
    .filter(
      (r) =>
        r.id !== 'a920d465-0393-4337-b947-7ec1c6e74c2d' &&
        r.id !== 'b54eca12-cb16-4842-b979-b30a915d4380',
    )
    .map((deploymentRule) => ({
      value: deploymentRule.id,
      label: deploymentRule.name,
    }));

export const loadCloudInstances = createAsyncThunk(
  'cloud/instance/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudInstanceResourceList(request);
    await dispatch(setCloudInstances({ cloudInstances: result.data }));
  },
);

export const selectCloudInstanceOptions = (state: RootState) =>
  state.cloud.cloudInstances.map((cloudInstance) => ({
    value: cloudInstance.id,
    label: cloudInstance.name,
  }));

export const loadImages = createAsyncThunk(
  'cloud/image/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudImageResourceList(request);
    await dispatch(setImages({ images: result.data }));
  },
);

export const selectImageOptions = (state: RootState) =>
  state.cloud.images.map((image) => ({
    value: image.id,
    label: image.name,
  }));

export const createSchedule = createAsyncThunk(
  'cloud/schedule/create',
  async (request: ItemCloudScheduleRequest) => {
    try {
      const response = await CloudService.saveCloudSchedule({
        id: request?.id,
        name: request.name,
        startDate: request.startDate, // Время начала расписания
        endDate: request.endDate, // Время окончания расписания
      });
      enqueueSnackbar(`Расписание успешно создано`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editSchedule = createAsyncThunk(
  'cloud/schedule/create',
  async (request: ItemCloudScheduleRequest) => {
    try {
      const response = await CloudService.saveCloudSchedule({
        id: request?.id,
        name: request.name,
        startDate: request.startDate, // Время начала расписания
        endDate: request.endDate, // Время окончания расписания
      });
      enqueueSnackbar(`Расписание успешно отредактировано`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const loadSchedules = createAsyncThunk(
  'cloud/schedule/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudScheduleList(request);
    await dispatch(setSchedules({ schedules: result.data }));
  },
);
export const selectScheduleOptions = (state: RootState) =>
  state.cloud.schedules.map((schedule) => ({
    value: schedule.id,
    label: schedule.name,
  }));

export const loadDeploymentActions = createAsyncThunk(
  'cloud/deployment/action/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDeploymentActionList(request);
    await dispatch(setDeploymentActions({ deploymentActions: result.data }));
  },
);
export const selectDeploymentActionOptions = (state: RootState) =>
  state.cloud.deploymentActions.map((deploymentAction) => ({
    value: deploymentAction.id,
    label: deploymentAction.name,
  }));

export const loadZones = createAsyncThunk(
  'cloud/zone/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getZoneWithClouds(params.infrastructureId);
    await dispatch(setZones({ zones: result }));
  },
);

export const selectZoneOptions = (state: RootState) =>
  state.cloud.zones.map((zone) => ({
    value: zone.id,
    label: zone.id,
  }));

export const loadPlatforms = createAsyncThunk(
  'cloud/platform/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getPlatformWithClouds(params.infrastructureId);
    await dispatch(setPlatforms({ platforms: result }));
  },
);

export const selectPlatformOptions = (state: RootState) =>
  state.cloud.platforms.map((platform) => ({
    value: platform.id,
    label: platform.id,
  }));

export const loadInstanceGroups = createAsyncThunk(
  'cloud/instance/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setInstanceGroups({ instanceGroups: [] }));
      return;
    }
    const result = await CloudService.getInstanceGroupsWithClouds(
      params.infrastructureId,
      params.parentId,
    );
    await dispatch(setInstanceGroups({ instanceGroups: result }));
  },
);

export const selectInstanceGroupOptions = (state: RootState) => {
  const options = state.cloud.instanceGroups.map((instanceGroup) => ({
    value: instanceGroup.id,
    label: instanceGroup.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

const getHost = () => {
  const host = process.env.REACT_APP_API_URL;
  if (host === undefined) return '';
  if (host.slice(-1) === '/') return host;
  return host + '/';
};
export const stompInfrastructure = (
  onSubscribe: (notification: InfrastructureNotification) => void,
) => {
  const socket = new SockJS(getHost() + 'cloud', null, {
    transports: ['xhr-polling', 'xhr-streaming'],
  });
  const stompClient = new Client({
    webSocketFactory: () => socket,
    reconnectDelay: 1000,
    onConnect: () => {
      stompClient.subscribe('/topic/infrastructure', (response: any) => {
        onSubscribe(JSON.parse(response.body));
      });
    },
  });
  return stompClient;
};

export const stompInstance = (onSubscribe: (notification: InstanceNotification) => void) => {
  const socket = new SockJS(getHost() + 'cloud', null, {
    transports: ['xhr-polling', 'xhr-streaming'],
  });
  const stompClient = new Client({
    webSocketFactory: () => socket,
    reconnectDelay: 1000,
    onConnect: () => {
      stompClient.subscribe('/topic/instance', (response: any) => {
        onSubscribe(JSON.parse(response.body));
      });
    },
  });
  return stompClient;
};

export const stompDeployment = (onSubscribe: (notification: DeploymentNotification) => void) => {
  const socket = new SockJS(getHost() + 'cloud', null, {
    transports: ['xhr-polling', 'xhr-streaming'],
  });
  const stompClient = new Client({
    webSocketFactory: () => socket,
    reconnectDelay: 1000,
    onConnect: () => {
      stompClient.subscribe('/topic/deployment', (response: any) => {
        onSubscribe(JSON.parse(response.body));
      });
    },
  });
  return stompClient;
};

const {
  setInstances,
  setTemplates,
  setOrganizations,
  setClouds,
  setBillingAccounts,
  setInfrastuctures,
  setDelegations,
  setFolders,
  setNetworks,
  setDeploymentRules,
  setImages,
  setSchedules,
  setDeploymentActions,
  setCloudInstances,
  setZones,
  setPlatforms,
  setInstanceGroups,
} = cloudSlice.actions;
export const cloudReducer = cloudSlice.reducer;
