import {
  DeploymentResourceForm,
  DeploymentResourceFormProps,
} from '@pages/cloud/deployments/DeploymentResourceForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate } from 'react-router-dom';
import { createDeploymentResource } from '@store/cloud/cloud.slice';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './DeploymentResourceForm.module.scss';

function CreateDeploymentResourceForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createSaveDeploymentResource: DeploymentResourceFormProps['submit'] = async (
    DeploymentResourceForm,
    form,
  ) => {
    try {
      await dispatch(
        createDeploymentResource({
          name: DeploymentResourceForm.name,
          infrastructureId: DeploymentResourceForm.infrastructureId,
          instanceParamsId: DeploymentResourceForm.instanceParamsId,
          instances: DeploymentResourceForm.instances,
          ruleId: DeploymentResourceForm.ruleId,
          numberInstance: DeploymentResourceForm.numberInstance,
          useMasterInstance: DeploymentResourceForm.useMasterInstance,
          masterInstanceId: DeploymentResourceForm.masterInstanceId,
          imageId: DeploymentResourceForm.imageId,
          sheduleId: DeploymentResourceForm.sheduleId,
          startActionId: DeploymentResourceForm.startActionId,
          endActionId: DeploymentResourceForm.endActionId,
          zone: {
            resourceId: DeploymentResourceForm.zoneId,
            resourceName: DeploymentResourceForm.zoneName,
            resourceType: 'zone',
          },
          platform: {
            resourceId: DeploymentResourceForm.platformId,
            resourceName: DeploymentResourceForm.platformName,
            resourceType: 'platform',
          },
          instanceGroup: {
            resourceId: DeploymentResourceForm.instanceGroupId,
            resourceName: DeploymentResourceForm.instanceGroupName,
            resourceType: 'instanceGroup',
            parentResourceId: DeploymentResourceForm.instanceGroupParentId,
          },
          preemptible: DeploymentResourceForm.preemptible,
          positionId: DeploymentResourceForm.positionId,
        }),
      ).unwrap();
      navigate(`/clouds/deployments`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Правила развертывания</h2>
          </div>
          <DeploymentResourceForm
            value={{
              name: '',
              infrastructureId: '',
              instanceParamsId: '',
              instances: [],
              ruleId: '',
              numberInstance: 0,
              useMasterInstance: false,
              masterInstanceId: '',
              imageId: '',
              sheduleId: '',
              startActionId: '',
              endActionId: '',
              zoneId: '',
              zoneName: '',
              platformId: '',
              platformName: '',
              instanceGroupId: '',
              instanceGroupName: '',
              instanceGroupParentId: '',
              preemptible: true,
              positionId: '',
            }}
            submit={createSaveDeploymentResource}
          />
        </div>
      </div>
    </Card>
  );
}

export { CreateDeploymentResourceForm };
