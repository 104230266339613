import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProjectsHeader } from '@components/ui/ProjectsHeader/ProjectsHeader';
import styles from '../projects/Project/Project.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { useMemo } from 'react';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';

export const ProjectDetail = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const showHeader = useMemo(() => {
    return !location.pathname.includes('vacancies/');
  }, [location]);

  const { data: project } = useDetailProjectQuery(projectId || '');

  if (!projectId) {
    navigate('/projects');
    return null;
  }

  if (project) {
    return (
      <div className="col container-content">
        <div className={styles.wrapper}>
          {!showHeader && (
            <div className={styles.navBack}>
              <NavigationBack
                back={{
                  to: `/projects/${projectId}/vacancies`,
                }}
              />
            </div>
          )}
          <div className={styles.container}>
            {showHeader && <ProjectsHeader projectId={projectId} />}
            <Outlet />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};
