import { Button } from '@components/MUI/Button';
import { InputField } from '@components/MUI/InputField/InputField';
import { ModalFooter } from '@components/MUI/Modal/Modal';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { number, object, string } from 'yup';
import styles from './AddPositionForm.module.scss';
import { DictionaryName } from '@store/dictionary/contracts';
import { useDictionary } from '@hooks/useDictionary';
import { fromDictionaryToSelectOption, SelectOption } from '@components/ui/Select/Select';
import { Select } from '@components/MUI/Select';
import { CircularProgress, MenuItem, SelectChangeEvent } from '@mui/material';
import { useGetProjectPositionsQuery } from '@store/project/project.slice';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as AddIcon } from '@assets/img/addLine.svg';
import { HOURS_IN_MONTH, TYPE_OF_PAYMENTS } from '@store/project/contracts';
import { AddPositionToVacancy } from '../AddPositionToVacancy';
import { getLocalizedPaymentType } from '@pages/project/Vacancies/helpers';

export type AddVacancyValue = {
  positionNames: string;
  directionId: string;
  projectPositionId: string;
  typeOfPayment: string;
  workloadPerMonth: number;
  payment: number;
  requiredNumberPositions: number;
  description: string;
};

export type AddVacancyFormProps = {
  value: AddVacancyValue;
  submit: (value: AddVacancyValue, form: FormikBag<AddVacancyFormProps, AddVacancyValue>) => void;
  isEditing?: boolean;
};

export const paymentVariants = [
  {
    value: TYPE_OF_PAYMENTS.UNPAID,
    label: getLocalizedPaymentType(TYPE_OF_PAYMENTS.UNPAID),
  },
  {
    value: TYPE_OF_PAYMENTS.HOURLY,
    label: getLocalizedPaymentType(TYPE_OF_PAYMENTS.HOURLY),
  },
  {
    value: TYPE_OF_PAYMENTS.MONTHLY,
    label: getLocalizedPaymentType(TYPE_OF_PAYMENTS.MONTHLY),
  },
  {
    value: TYPE_OF_PAYMENTS.PERTASK,
    label: getLocalizedPaymentType(TYPE_OF_PAYMENTS.PERTASK),
  },
];

export const workloadVariants = [
  {
    value: 40,
    label: `40 часов из ${HOURS_IN_MONTH}`,
  },
  {
    value: 80,
    label: `80 часов из ${HOURS_IN_MONTH}`,
  },
  {
    value: 120,
    label: `120 часов из ${HOURS_IN_MONTH}`,
  },
  {
    value: 160,
    label: `160 часов из ${HOURS_IN_MONTH}`,
  },
];

function curriedRenderOptions(isLoading: boolean, handleClick: () => void) {
  return function renderOption(options: SelectOption[]) {
    if (isLoading) {
      return (
        <CircularProgress
          sx={{
            color: '#007236',
            width: '24px',
            height: '24px',
          }}
        />
      );
    }
    return [
      <MenuItem
        key="add"
        sx={{ borderTop: '1px solid #DCDCDC' }}
        style={{ padding: '16px 11px 15px' }}
        onClick={handleClick}
        className={styles.addPositionBtn}>
        <AddIcon />
        <p>Добавить должность</p>
      </MenuItem>,
      ...options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ borderTop: '1px solid #DCDCDC' }}
          style={{ padding: '16px 11px 15px' }}>
          {option.label}
        </MenuItem>
      )),
    ];
  };
}

const addPositionSchema = object({
  directionId: string().required('Обязательно для заполнения'),
  projectPositionId: string().required('Обязательно для заполнения'),
  payment: number().when('typeOfPayment', {
    is: (value: string) => value === TYPE_OF_PAYMENTS.UNPAID,
    then: number().notRequired(),
    otherwise: number().required('Обязательно для заполнения').min(1, 'Обязательно для заполнения'),
  }),
  typeOfPayment: string().required('Обязательно для заполнения'),
  workloadPerMonth: number().when('typeOfPayment', {
    is: (value: string) => value === TYPE_OF_PAYMENTS.HOURLY,
    then: number().required('Обязательно для заполнения'),
    otherwise: number().notRequired(),
  }),
  requiredNumberPositions: number()
    .required('Обязательно для заполнения')
    .min(1, 'Обязательно для заполнения'),
  description: string(),
});

const AddVacancyToProject = ({ isEditing = false }: AddVacancyFormProps) => {
  const dispatch = useAppDispatch();
  const form = useFormikContext<AddVacancyValue>();
  const { projectId } = useParams();

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const { data: executionPositions, isLoading } = useGetProjectPositionsQuery(
    {
      projectId: projectId!,
      directionId: form.values.directionId,
    },
    {
      skip: !form.values.directionId,
    },
  );

  const executionPositionsForSelect = useMemo(() => {
    return executionPositions?.map((position) => {
      return {
        value: position.id!,
        label: position.positionName!,
      };
    });
  }, [executionPositions]);

  const directions = useDictionary(DictionaryName.DIRECTION, fromDictionaryToSelectOption);

  const handleClick = useCallback(() => {
    dispatch(openModal(<AddPositionToVacancy savedValues={form.values} />));
  }, [form.values]);

  const renderOptions = curriedRenderOptions(isLoading, handleClick);

  form.validationSchema = addPositionSchema;

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.form__fields}>
          <Select
            name="directionId"
            values={directions}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('directionId', event.target.value);
            }}
            label={'Направление'}
            className={styles.form_field}
          />
          <Select
            name="projectPositionId"
            values={executionPositionsForSelect ?? []}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('projectPositionId', event.target.value);
            }}
            label={'Наименование должности'}
            className={styles.form_field}
            disabled={form.values.directionId === ''}
            customRenderOptions={renderOptions}
          />
          <Select
            name="typeOfPayment"
            values={paymentVariants}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('typeOfPayment', event.target.value);
            }}
            label={'Вид оплаты'}
            className={styles.form_field}
          />
          <Select
            name="workloadPerMonth"
            values={workloadVariants}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('workloadPerMonth', event.target.value);
            }}
            label={'Занятость в месяц'}
            className={classNames({
              [styles.hidden]: form.values.typeOfPayment !== TYPE_OF_PAYMENTS.MONTHLY,
            })}
          />
          <InputField
            name="payment"
            type="number"
            placeholder="Оплата ₽"
            value={form.values.payment}
            className={classNames({
              [styles.hidden]: form.values.typeOfPayment === TYPE_OF_PAYMENTS.UNPAID,
            })}
          />
          {/*           
          // ! Нужен поиск юзеров по email.
          <div className={styles.form__fields__checkboxField}>
            <Checkbox
              fieldClassName={`align-items-start m-0 ${styles.formMain__container__agreement_checkbox}`}
              sx={checkboxStyle}
              name="agreement"
            />
            <p>Вакансия по приглашению</p>
          </div>
          <InputField
            name="jobSeekerIds"
            type="text"
            label="Email пользователя Egile"
            placeholder=""
          /> */}
          <InputField
            name="requiredNumberPositions"
            type="number"
            placeholder="Количество вакантных мест на должность"
            value={form.values.requiredNumberPositions}
          />
          <InputField
            name="description"
            type="text"
            placeholder="Описание вакансии"
            value={form.values.description}
          />
        </div>
        <ModalFooter>
          <div className={styles.form__buttons}>
            <Button variant="text" className={styles.form__buttons_cancel} onClick={handleCancel}>
              Отменить
            </Button>
            <Button variant="contained" type="submit" disabled={!form.isValid || !form.dirty}>
              {isEditing ? 'Редактировать' : 'Сохранить'}
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Form>
  );
};

export const AddVacancyForm = withFormik<AddVacancyFormProps, AddVacancyValue>({
  displayName: 'AddVacancyForm',
  mapPropsToValues: (props) => {
    return {
      positionNames: props.value.positionNames,
      directionId: props.value.directionId,
      projectPositionId: props.value.projectPositionId,
      typeOfPayment: props.value.typeOfPayment,
      workloadPerMonth: props.value.workloadPerMonth,
      payment: props.value.payment,
      requiredNumberPositions: props.value.requiredNumberPositions,
      description: props.value.description,
      submit: props.submit,
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: addPositionSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddVacancyToProject);
