import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '@store/user/user.slice';
import { LoginForm, LoginFormProps } from './LoginForm';
import { IdentifierType } from '@store/user/contracts';
import styles from './Authentication.module.scss';

export function Authorization() {
  const navigate = useNavigate();

  const [userLogin] = useLoginMutation();

  const login: LoginFormProps['submit'] = async (loginForm, form) => {
    try {
      form.setSubmitting(true);
      await userLogin({
        identifierTypeId: IdentifierType.Email,
        identifierValue: loginForm.email,
        password: loginForm.password,
      }).unwrap();
      navigate('/');
    } catch (e: any) {
      form.setErrors({
        email: e.message,
      });
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Войти</div>
      <div style={{ marginBottom: '24px' }}>
        Новый пользователь?
        <Link
          to="/registration"
          className="ms-1 color-green color-green_hover fw-500 text-decoration-underline">
          Создать учетную запись
        </Link>
      </div>
      <LoginForm
        value={{
          email: '',
          password: '',
        }}
        submit={login}
      />
    </div>
  );
}
