import { priceFormatter } from '@helpers/priceFormatter';
import styles from './VacancyConditions..module.scss';
import { HOURS_IN_MONTH, TYPE_OF_PAYMENTS } from '@store/project/contracts';
import { Skeleton } from '@mui/material';
import { getLocalizedPaymentType } from '../../helpers';

type VacancyConditionsProps = {
  workloadPerMonth?: number;
  payment?: number;
  typeOfPayment: TYPE_OF_PAYMENTS;
  combinedTypeOfPayment?: boolean;
  isLoading?: boolean;
};

type TypeOfPaymentBlockProps = {
  combinedTypeOfPayment?: boolean;
} & Pick<VacancyConditionsProps, 'typeOfPayment' | 'workloadPerMonth'>;

const TypeOfPaymentBlock = ({
  combinedTypeOfPayment = false,
  typeOfPayment,
  workloadPerMonth,
}: TypeOfPaymentBlockProps) => {
  if (combinedTypeOfPayment) {
    return (
      <div>
        <p>Вид оплаты</p>
        {typeOfPayment === TYPE_OF_PAYMENTS.MONTHLY ? (
          <p>{`${workloadPerMonth} часов в месяц`}</p>
        ) : (
          <p>{getLocalizedPaymentType(typeOfPayment)}</p>
        )}
      </div>
    );
  } else
    return (
      <>
        <div>
          <p>Вид оплаты</p>
          <p>{getLocalizedPaymentType(typeOfPayment)}</p>
        </div>
        {typeOfPayment === TYPE_OF_PAYMENTS.MONTHLY && (
          <div>
            <p>Занятость в месяц</p>
            <p>
              {workloadPerMonth} часов из {HOURS_IN_MONTH}
            </p>
          </div>
        )}
      </>
    );
};

export const VacancyConditions = ({
  typeOfPayment,
  payment,
  combinedTypeOfPayment,
  workloadPerMonth,
  isLoading,
}: VacancyConditionsProps) => {
  return (
    <div className={styles.container}>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width={135} height={62} />
          <Skeleton variant="rounded" width={135} height={62} />
          <Skeleton variant="rounded" width={135} height={62} />
        </>
      ) : (
        <>
          <TypeOfPaymentBlock
            combinedTypeOfPayment={combinedTypeOfPayment}
            typeOfPayment={typeOfPayment}
            workloadPerMonth={workloadPerMonth}
          />
          {payment ? (
            <div>
              <p>Оплата</p>
              <p>
                {priceFormatter({
                  amount: payment,
                  currency: 'RUB',
                })}
              </p>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
