import React, { useState } from 'react';
import { ChatLink } from './ChatLink';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton } from '@mui/material';
import styles from './ChatList.module.scss';

export const ChatList: React.FC = () => {
  const [start, setStart] = useState(0);
  const [chats] = useState([]);
  const [totalCount] = useState(0);

  if (chats?.length === 0)
    return (
      <div className={styles.empty__chat__list}>
        <div>У вас пока нет ни одного чата</div>
      </div>
    );

  return (
    <div style={{ height: '650px', overflowY: 'scroll' }}>
      <InfiniteScroll
        dataLength={chats.length}
        next={() => setStart(start + 10)}
        hasMore={totalCount !== chats.length}
        loader={<Skeleton />}
        inverse={false}>
        {chats.map(() => (
          <ChatLink
            chatId={'id'}
            unreadMessagesCount={0}
            lastMessage={false}
            key={'id'}
            chatName={'Название'}
            author={'Автор'}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
