import { MainContentLayout } from '@layouts/MainContentLayout';
import { Outlet, useLocation, matchRoutes } from 'react-router-dom';
import { Can } from '@access-control';
import { ProjectsHeader } from '@pages/projects/ProjectsHeader';
import { ProjectsWithoutRoles } from '@pages/projects/ProjectsWithoutRoles';

const routesToHideHeader = [{ path: 'projects/new' }];

export const ProjectsLayout = () => {
  const location = useLocation();
  const showHeader = !matchRoutes(routesToHideHeader, location);

  return (
    <MainContentLayout>
      {showHeader && <ProjectsHeader />}
      <Outlet />

      <Can not I="control" a="Project">
        <Can not I="execute" a="Project">
          <ProjectsWithoutRoles />
        </Can>
      </Can>
    </MainContentLayout>
  );
};
