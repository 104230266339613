import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MSelect,
  styled,
} from '@mui/material';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnySchema, reach } from 'yup';

export type SelectOption = { value: any; label: string };

type SelectProps = {
  values: SelectOption[];
  onChange?: (event: any, child: React.ReactNode) => void;
  value?: any;
  name: string;
  className?: string;
  classNameSelect?: string;
  isOutlined?: boolean;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  multiline?: boolean;
  multiple?: boolean;
  renderValue?: (value: unknown) => React.ReactNode | undefined;
  fullWidth?: boolean;
  displayEmpty?: boolean;
  endAdornment?: JSX.Element;
  customRenderOptions?: (
    options: SelectOption[],
    field: FieldInputProps<any>,
  ) => JSX.Element[] | JSX.Element;
};

const StyledSelect = styled(MSelect)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-iconOpen': {
    color: '#007236',
  },
  '& .MuiSelect-select': {
    '&[aria-expanded="true"]': {
      color: 'green',
    },
  },
  '& .MuiSelect-icon': {
    right: '-30px',
  },
  position: 'relative',
  '&.Mui-disabled': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7A7A7',
    },
    opacity: 0.6,
    pointerEvents: 'none',
    background: '#A7A7A7',
  },
});

const StyledOutlinedSelect = styled(MSelect)({
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#007236',
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #007236',
    boxShadow: '-2px -2px 4px 0 hsla(174, 71%, 88%, 0.3), 2px 2px 4px 0 hsla(174, 71%, 88%, 0.3)',
  },
  outline: 'none',
  '&.Mui-disabled': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7A7A7',
    },
    opacity: 0.6,
    pointerEvents: 'none',
    background: '#A7A7A7',
  },
});

const renderOptionsByDefault = (options: SelectOption[]) => {
  return options.map((option) => (
    <MenuItem
      value={option.value}
      sx={{ borderTop: '1px solid #DCDCDC' }}
      style={{ padding: '16px 11px 15px' }}
      key={option.value}>
      {option.label}
    </MenuItem>
  ));
};

export const Select: React.FC<SelectProps> = ({
  values,
  name,
  className,
  classNameSelect,
  isOutlined,
  label,
  style,
  disabled,
  multiline,
  multiple,
  renderValue,
  customRenderOptions,
  ...props
}) => {
  const SelectComponent = isOutlined ? StyledOutlinedSelect : StyledSelect;

  const [field, meta] = useField(name);
  const contextForm = useFormikContext();

  const fieldSchema: AnySchema | null = useMemo(() => {
    return contextForm.validationSchema ? reach(contextForm.validationSchema, name) : null;
  }, [contextForm.validationSchema, name]);

  const isRequiredField = useMemo(
    () => Boolean(fieldSchema?.tests.find((test) => test.OPTIONS.name === 'required')),
    [fieldSchema],
  );

  const hasError = useMemo(() => {
    return meta.touched && meta.error !== undefined;
  }, [meta.touched, meta.error]);

  const textFieldPlaceholder = useMemo(() => {
    return isRequiredField ? `${label}*` : `${label}`;
  }, [isRequiredField, label]);

  const renderOptions = useMemo(
    () => customRenderOptions ?? renderOptionsByDefault,
    [customRenderOptions, renderOptionsByDefault],
  );

  return (
    <FormControl fullWidth className={className} style={style}>
      <InputLabel
        id={name}
        sx={{
          top: '0',
          '&.MuiInputLabel-shrink': {
            top: '15px',
          },
        }}
        error={hasError}>
        {label ? textFieldPlaceholder : ''}
      </InputLabel>
      <SelectComponent
        inputProps={{
          className: classNameSelect,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          autoFocus: false,
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'rgba(25, 155, 62, 0.05)',
              },
              '&.MuiPaper-root': {
                maxHeight: '350px',
              },
            },
            elevation: 0,
            style: {
              border: '1px solid #DCDCDC',
            },
          },
        }}
        onBlur={field.onBlur}
        error={hasError}
        value={field.value}
        IconComponent={ExpandMoreIcon}
        onChange={field.onChange}
        name={name}
        placeholder={label ? textFieldPlaceholder : ''}
        renderValue={renderValue}
        disabled={disabled}
        multiple={multiple}
        {...props}>
        {renderOptions(values, field)}
      </SelectComponent>
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
