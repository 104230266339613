import { MainContentLayout } from '@layouts/MainContentLayout';
import { Outlet } from 'react-router-dom';
import styles from './OffersLayout.module.scss';

export const OffersLayout = () => {
  return (
    <MainContentLayout>
      <div className={styles.container}>
        <h2>Предложения</h2>
        <Outlet />
      </div>
    </MainContentLayout>
  );
};
