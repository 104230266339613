import React, { useEffect, useMemo } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useRequestBody } from '@hooks/useRequestBody';
import { Task } from '@store/tasks/contracts';
import { Link, useOutletContext } from 'react-router-dom';
import { StatusLabel, statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';
import { Dayjs } from 'dayjs';
import { TaskContext } from '@layouts/task/TasksLayout';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { CreateTaskButton } from './CreateTaskButton';
import { useGetTasksQuery } from '@store/tasks/tracker.slice';
import styles from './TasksList.module.scss';
import { useUrlPagination } from '@hooks/useUrlPagination';

export const TasksList: React.FC = () => {
  const { handleChangeRowsPerPage, handleChangePage, getParam, updateParams, limit, page } =
    useUrlPagination({ defaultPage: 1, defaultLimit: 10 });
  const { setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder, query } =
    useRequestBody();
  const [context] = useOutletContext<TaskContext>();

  const projectIds = useMemo(() => {
    const projectIds = context.projectIds;
    const projectIdFromUrl = getParam('project');
    if (projectIdFromUrl) {
      return [projectIdFromUrl];
    }

    if (context.projectIds?.length && context.projectIds?.length === 0) {
      return undefined;
    }
    return projectIds;
  }, [context.projectIds]);

  const { data: tasksList, isLoading } = useGetTasksQuery({
    limit: limit,
    start: (page - 1) * limit,
    projects: projectIds,
    ...(sortColumn && { sortField: sortColumn }),
    sortOrder: sortOrder,
    ...query,
  });

  useEffect(() => {
    let projectFromUrl = getParam('project');
    if (!projectIds || projectIds.length === 0) return;

    let defaultProject = projectIds[0];
    if (projectFromUrl) {
      updateParams({ limit, page, project: projectFromUrl });
    } else {
      updateParams({ limit, page, project: defaultProject });
    }
  }, [getParam, updateParams, projectIds]);

  return (
    <div className="col">
      <div className="mt-4">
        <AdaptiveTableMUI
          setRequestBody={setRequestBody}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={isLoading}
          columns={{
            key: {
              label: 'Ключ Задачи',
              children: (value, row: Task) => <Link to={`/tasks/${row.id}`}>{value}</Link>,
              filterInput: true,
              isSortColumn: false,
            },
            name: {
              label: 'Задача',
              children: (value, row: Task) => (
                <Link className={styles.task_name} to={`/tasks/${row.id}`}>
                  {value}
                </Link>
              ),
              filterInput: true,
              isSortColumn: false,
            },
            status: {
              label: 'Статус',
              children: (value: any, row: Task) => (
                <StatusLabel text={value?.display} color={statusTaskColors.get(row?.statusType)} />
              ),
              filterInput: true,
              isSortColumn: false,
            },
            executor: {
              label: 'Исполнитель',
              children: (value) => <Link to={`/userPage/`}>{value}</Link>,
              filterInput: true,
              isSortColumn: false,
            },
            created: {
              label: 'Дата создания',
              format: (value: Dayjs) => value.format('DD.MM.YYYY'),
              filterInput: true,
              isSortColumn: true,
            },
            deadline: {
              label: 'Дата завершения',
              format: (value: Dayjs) => (value ? value?.format('DD.MM.YYYY') : '-'),
              filterInput: true,
              isSortColumn: true,
            },
          }}
          rows={tasksList?.data || []}
          emptyLabel={'У вас пока нет задач'}
          additionalElement={<CreateTaskButton />}>
          <TablePaginationMUI
            limit={limit}
            count={tasksList?.totalCount}
            entityLabel="задач"
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};
