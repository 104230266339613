import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import styles from './AddPositionToVacancyForm.module.scss';
import { ModalFooter } from '@components/MUI/Modal/Modal';
import { Button } from '@components/MUI/Button';
import { InputField } from '@components/MUI/InputField/InputField';
import { object, string } from 'yup';

type AddPositionToVacancyValue = {
  name: string;
  directionId: string;
};

export type AddPositionToVacancyProps = {
  value: AddPositionToVacancyValue;
  submit: (
    value: AddPositionToVacancyValue,
    form: FormikBag<AddPositionToVacancyProps, AddPositionToVacancyValue>,
  ) => void;
  onDismiss: () => void;
};

const addPositionTOVacancySchema = object({
  directionId: string(),
  name: string()
    .required('Обязательно для заполнения')
    .min(5, 'Название должно быть не менее 5 символов'),
});

const AddPositionToVacancy = ({ onDismiss }: AddPositionToVacancyProps) => {
  const form = useFormikContext<AddPositionToVacancyValue>();

  form.validationSchema = addPositionTOVacancySchema;
  return (
    <Form>
      <InputField name="directionId" hidden type="text" />
      <div className={styles.form}>
        <InputField name="name" type="text" placeholder="Название должности" />
        <ModalFooter>
          <div className={styles.form__buttons}>
            <Button variant="text" className={styles.form__buttons_cancel} onClick={onDismiss}>
              Отменить
            </Button>
            <Button variant="contained" type="submit" disabled={!form.isValid || !form.dirty}>
              Создать
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Form>
  );
};

export const AddPositionToVacancyForm = withFormik<
  AddPositionToVacancyProps,
  AddPositionToVacancyValue
>({
  displayName: 'AddPositionToVacancyForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      directionId: props.value.directionId,
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validateOnMount: false,
  validateOnChange: true,
  validationSchema: addPositionTOVacancySchema,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddPositionToVacancy);
