import { useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';

import {
  SelectAutocompleteForm,
  SelectAutocompleteFormProps,
} from '@pages/task/SelectAutocompleteForm';
import { ProjectsIds } from './TasksLayout';
import classNames from 'classnames';
import tasksStyles from './TasksNavBar.module.scss';
import { useUrlParams } from '@hooks/useUrlParams';
import { TaskHeader } from '@layouts/task/TaskHeader';

type TasksNavigationBarProps = {
  setContext: React.Dispatch<React.SetStateAction<ProjectsIds>>;
};

export function TasksNavigationBar({ setContext }: TasksNavigationBarProps) {
  const [initialValue, setInitialValue] = useState('');
  const { taskId } = useParams();
  const location = useLocation();
  const { getParam, updateParams } = useUrlParams();

  useEffect(() => {
    const projectIdFromUrl = getParam('project');
    if (projectIdFromUrl) {
      setInitialValue(projectIdFromUrl);
      setContext({ projectIds: [projectIdFromUrl] });
    }
  }, [location.search, setContext]);

  const handleSubmitProjectId: SelectAutocompleteFormProps['submit'] = async (selectForm) => {
    const { projectId } = selectForm;
    setContext({ projectIds: [projectId] });
    setInitialValue(projectId);
    updateParams({ project: projectId });
  };

  if (taskId || location.pathname === '/tasks/new') {
    return (
      <header className="header">
        <NavigationBack
          back={{
            to: `/tasks`,
            options: {
              state: { projectId: initialValue },
            },
          }}
        />
      </header>
    );
  }

  return (
    <>
      <header className={classNames(tasksStyles.tasks__header)}>
        <SelectAutocompleteForm
          value={{ projectId: initialValue }}
          submit={handleSubmitProjectId}
          setContext={setContext}
        />
      </header>
      <TaskHeader />
    </>
  );
}
