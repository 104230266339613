import styles from './Participants.module.scss';
import { useParams } from 'react-router-dom';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { ParticipantsTable } from './components/ParticipantsTable';

export function Participants() {
  const { projectId } = useParams();
  const { data: project } = useDetailProjectQuery(projectId || '');

  if (!project) return <></>;

  return (
    <div className={styles.participants}>
      <div className={styles.title}>
        <h3 className={styles.title}>{project?.name}</h3>
      </div>
      <div className={styles.manager}>
        <h6 className={styles.commonCardLabel}>Менеджер</h6>
        <div>
          <p>{project?.teamLeadName ?? '-'}</p>
        </div>
      </div>
      <div className={styles.table}>
        <ParticipantsTable />
      </div>
    </div>
  );
}
