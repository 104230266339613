import { useCallback, useState } from 'react';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FormWrapper } from '@components/Form/Form';

const ModalReleases = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={cancel}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '480px', width: '100%' }}>
          <FormWrapper label={'Создание релиза'}>
            <h6 style={{ color: 'red' }}>
              На данный момент создание релизов
              <br />
              находится в разработке, пожалуйста ожидайте...
            </h6>
          </FormWrapper>
        </Box>
      </Modal>
    </>
  );
};
export { ModalReleases };
