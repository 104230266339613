import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

interface UseSelectableRowsOptions<T, S = any> {
  rows: T[];
  fieldName: string;
  getValue: (row: T) => S;
  getKey?: (item: S) => string | number;
}

/**
 * Хук для возможности выбрать строки в таблице.
 * 
 * @param 
 * getKey - функция для получения ключа из элемента. По умолчанию возвращает сам элемент
 * 
 * @example 
 * Пример использования в header-е таблицы
 * 
 *  <Checkbox
      {...headerCheckboxProps}
      onChange={toggleAllSelection}
    />
 * 
 * @example
 * Пример использования в теле таблицы
 * 
 * children: (value) => {
    const isChecked = isSelected(value);
    return (
      <>
        <Field
          name='selected'
          value={value}
          component={Checkbox}
          checked={isChecked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          toggleRowSelection(e, value)
           }
          />
      </>
    );
   },
  },
 */
export function useSelectableRows<T, S = any>({
  rows,
  fieldName,
  getValue,
  getKey,
}: UseSelectableRowsOptions<T, S>) {
  const { values, setFieldValue } = useFormikContext<any>();
  const selected = values[fieldName];

  if (!Array.isArray(selected)) {
    throw new Error('Поле должно быть массивом');
  }

  const keyExtractor = getKey ? getKey : (item: S) => item as string | number;

  const rowKeys = useMemo(
    () => rows.map((row) => keyExtractor(getValue(row))),
    [rows, keyExtractor, getValue],
  );

  const selectedKeys = useMemo(
    () => new Set(selected.map((item: S) => keyExtractor(item))),
    [selected, keyExtractor],
  );

  const allSelected = useMemo(
    () => rowKeys.every((key) => selectedKeys.has(key)),
    [rowKeys, selectedKeys],
  );

  const noneSelected = useMemo(
    () => rowKeys.every((key) => !selectedKeys.has(key)),
    [rowKeys, selectedKeys],
  );

  const headerCheckboxProps = {
    checked: allSelected,
    indeterminate: !allSelected && !noneSelected,
  };

  const toggleRowSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: S) => {
      if (event.target.checked) {
        setFieldValue(fieldName, [...selected, value]);
      } else {
        const valueKey = keyExtractor(value);

        setFieldValue(
          fieldName,
          selected.filter((item: S) => keyExtractor(item) !== valueKey),
        );
      }
    },
    [selected, setFieldValue, fieldName, keyExtractor],
  );

  const toggleAllSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const union = [...selected];
        const unionKeys = new Set(selected.map((item: S) => keyExtractor(item)));

        rows.forEach((row) => {
          const value = getValue(row);
          const key = keyExtractor(value);
          if (!unionKeys.has(key)) {
            union.push(value);
          }
        });
        setFieldValue(fieldName, union);
      } else {
        setFieldValue(
          fieldName,
          selected.filter((item: S) => !rowKeys.includes(keyExtractor(item))),
        );
      }
    },
    [selected, rows, getValue, rowKeys, setFieldValue, fieldName, keyExtractor],
  );

  const isSelected = useCallback(
    (value: S) => {
      return selectedKeys.has(keyExtractor(value));
    },
    [selectedKeys, keyExtractor],
  );

  const clearAllSelections = useCallback(() => {
    setFieldValue(fieldName, []);
  }, [setFieldValue, fieldName]);
  return {
    headerCheckboxProps,
    toggleRowSelection,
    toggleAllSelection,
    isSelected,
    clearAllSelections,
    selected,
  };
}
