import React, { useEffect, useRef, useState } from 'react';
import styles from './EditDeploymentResource.module.scss';
import { Card } from '@mui/material';
import classNames from 'classnames';
import { DeploymentResourceForm, DeploymentResourceFormProps } from '../DeploymentResourceForm';
import { useNavigate, useParams } from 'react-router-dom';
import { CloudDeploymentResource } from '@store/cloud/contracts';
import { getCloudDeploymentResource } from '@store/cloud/cloud.service';
import { useAppDispatch } from '@hooks/index';
import { createDeploymentResource } from '@store/cloud/cloud.slice';

export const EditDeploymentResource: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { deploymentResourceId } = useParams();
  const fetched = useRef(false);
  const [deploymentResource, setDeploymentResource] = useState<CloudDeploymentResource>();

  useEffect(() => {
    if (!fetched.current) {
      (async () => {
        if (deploymentResourceId) {
          const item = await getCloudDeploymentResource(deploymentResourceId);
          setDeploymentResource(item);
        }
      })();
      fetched.current = true;
    }
  }, []);

  const editDeploymentResource: DeploymentResourceFormProps['submit'] = async (
    DeploymentResourceForm,
    form,
  ) => {
    try {
      await dispatch(
        createDeploymentResource({
          id: deploymentResourceId!,
          name: DeploymentResourceForm.name,
          infrastructureId: DeploymentResourceForm.infrastructureId,
          instanceParamsId: DeploymentResourceForm.instanceParamsId,
          instances: DeploymentResourceForm.instances,
          ruleId: DeploymentResourceForm.ruleId,
          numberInstance: DeploymentResourceForm.numberInstance,
          useMasterInstance: DeploymentResourceForm.useMasterInstance,
          masterInstanceId: DeploymentResourceForm.masterInstanceId,
          imageId: DeploymentResourceForm.imageId,
          sheduleId: DeploymentResourceForm.sheduleId,
          startActionId: DeploymentResourceForm.startActionId,
          endActionId: DeploymentResourceForm.endActionId,
          zone: {
            resourceId: DeploymentResourceForm.zoneId,
            resourceName: DeploymentResourceForm.zoneName,
            resourceType: 'zone',
          },
          platform: {
            resourceId: DeploymentResourceForm.platformId,
            resourceName: DeploymentResourceForm.platformName,
            resourceType: 'platform',
          },
          instanceGroup: {
            resourceId: DeploymentResourceForm.instanceGroupId,
            resourceName: DeploymentResourceForm.instanceGroupName,
            resourceType: 'instanceGroup',
            parentResourceId: DeploymentResourceForm.instanceGroupParentId,
          },
          preemptible: DeploymentResourceForm.preemptible,
          positionId: DeploymentResourceForm.positionId,
        }),
      ).unwrap();
      navigate(`/clouds/deployments`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Редактирование правила развертывания</h2>
          </div>
          <DeploymentResourceForm
            value={{
              name: deploymentResource?.name || '',
              infrastructureId: deploymentResource?.infrastructure?.id || '',
              instanceParamsId: deploymentResource?.instanceParams?.id || '',
              instances: deploymentResource?.instances?.map((instance) => instance?.id) || [],
              ruleId: deploymentResource?.rule.id || '',
              numberInstance: deploymentResource?.numberInstance || 0,
              useMasterInstance: deploymentResource?.useMasterInstance || false,
              masterInstanceId: deploymentResource?.masterInstance?.id || '',
              imageId: deploymentResource?.image?.id || '',
              sheduleId: deploymentResource?.shedule?.id || '',
              startActionId: deploymentResource?.startAction?.id || '',
              endActionId: deploymentResource?.endAction?.id || '',
              zoneId: deploymentResource?.zone?.resourceId || '',
              zoneName: deploymentResource?.zone?.resourceName || '',
              platformId: deploymentResource?.platform?.resourceId || '',
              platformName: deploymentResource?.platform?.resourceName || '',
              instanceGroupId: deploymentResource?.instanceGroup?.id || '',
              instanceGroupName: deploymentResource?.instanceGroup?.resourceName || '',
              instanceGroupParentId: deploymentResource?.instanceGroup?.parentResourceId || '',
              preemptible: deploymentResource?.preemptible || false,
              positionId: deploymentResource?.position?.id || '',
            }}
            submit={editDeploymentResource}
          />
        </div>
      </div>
    </Card>
  );
};
