import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { Link } from 'react-router-dom';
import { statusColors, StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { AdaptiveTableMUIProps } from '@components/Table/TableMUI';

type ManagerProjectsTableProps = Pick<
  AdaptiveTableMUIProps,
  'setRequestBody' | 'sortColumn' | 'setSortOrder' | 'setSortColumn' | 'rows'
>;

export const ManagerProjectsTable = ({
  rows,
  setRequestBody,
  setSortColumn,
  setSortOrder,
  sortColumn,
}: ManagerProjectsTableProps) => {
  return (
    <AdaptiveTableMUI
      setRequestBody={setRequestBody}
      sortColumn={sortColumn}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      rows={rows}
      columns={{
        name: {
          label: 'Проект',
          filterInput: true,
          children: (value, row) => <Link to={`/projects/${row.id}/details`}>{value}</Link>,
        },
        customerName: {
          label: 'Заказчик',
          filterInput: true,
        },
        teamLeadName: {
          label: 'Менеджер',
          filterInput: true,
          children: (value, row) => <Link to={`/userPage/${row.teamLeadId}`}>{value}</Link>,
        },
        created: {
          label: 'Старт проекта',
          format: (value) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        deadLine: {
          label: 'Дедлайн',
          format: (value) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        projectStatusName: {
          label: 'Статус',
          children: (value: string) => <StatusLabel color={statusColors.get(value)} text={value} />,
        },
      }}
    />
  );
};
