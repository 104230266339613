import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useUrlPagination } from '@hooks/useUrlPagination';
import { formatPaymentType } from '@pages/project/Vacancies/helpers';
import { useGetAcceptedOffersQuery } from '@store/project/project.slice';
import { Link } from 'react-router-dom';

export const ProjectsExecuting = () => {
  const { limit, page, handleChangePage, handleChangeRowsPerPage, start } = useUrlPagination({
    defaultPage: 1,
    defaultLimit: 10,
  });
  const { data: projects } = useGetAcceptedOffersQuery({
    limit: limit,
    start: start,
  });

  return (
    <AdaptiveTableMUI
      rows={projects?.data || []}
      columns={{
        accepted_at: {
          label: 'Присоединение к проекту',
        },
        projectName: {
          label: 'Проект',
          children: (value, row) => <Link to={`/projects/${row.projectId}/details`}>{value}</Link>,
        },
        customerName: {
          label: 'Заказчик',
          children: (value, row) => <Link to={`/userPage/${row.customerId}`}>{value}</Link>,
        },
        typeOfPayment: {
          label: 'Вид оплаты',
          children: (value, row) => (
            <>
              {formatPaymentType({
                typeOfPayment: value,
                hours: row?.workloadPerMonth,
              })}
            </>
          ),
        },
        payment: {
          label: 'Оплата',
        },
        status: {
          label: 'Статус',
        },
      }}>
      <TablePaginationMUI
        limit={limit}
        count={projects?.totalCount}
        entityLabel="проектов"
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
