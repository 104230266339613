import {
  InstanceResourceForm,
  InstanceResourceFormProps,
} from '@pages/cloud/instances/InstanceResourceForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate } from 'react-router-dom';
import { createInstanceResource } from '@store/cloud/cloud.slice';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './InstanceResourceForm.module.scss';

function CreateInstanceResourceForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const defaultTemplate = { name: '', memory: 2, cores: 1, diskSize: 20 };

  const createSaveInstanceResource: InstanceResourceFormProps['submit'] = async (
    InstanceResourceForm,
    form,
  ) => {
    try {
      await dispatch(
        createInstanceResource({
          name: InstanceResourceForm.name,
          instance: {
            resourceId: InstanceResourceForm.instanceId,
            resourceName: InstanceResourceForm.instanceName,
            resourceType: 'instance',
            parentResourceId: InstanceResourceForm.instanceParentId,
          },
          infrastructureId: InstanceResourceForm.infrastructureId,
          deploymentId: InstanceResourceForm.deploymentId,
          cores: InstanceResourceForm.cores,
          memory: InstanceResourceForm.memory,
          owners: InstanceResourceForm.owners,
          statusId: InstanceResourceForm.statusId,
          imageId: InstanceResourceForm.imageId,
          diskSize: InstanceResourceForm.diskSize,
          zone: {
            resourceId: InstanceResourceForm.zoneId,
            resourceName: InstanceResourceForm.zoneName,
            resourceType: 'zone',
          },
          platform: {
            resourceId: InstanceResourceForm.platformId,
            resourceName: InstanceResourceForm.platformName,
            resourceType: 'platform',
          },
          login: InstanceResourceForm.login,
          password: InstanceResourceForm.password,
          ipAddress: InstanceResourceForm.ipAddress,
          instanceParamsId: InstanceResourceForm.instanceParamsId,
          remoteName: InstanceResourceForm.remoteName,
          preemptible: InstanceResourceForm.preemptible,
        }),
      ).unwrap();
      navigate(`/clouds`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Виртуальная машина</h2>
          </div>
          <InstanceResourceForm
            value={{
              name: '',
              instanceResourceId: '',
              instanceId: '',
              instanceName: '',
              instanceParentId: '',
              infrastructureId: '',
              deploymentId: '',
              cores: defaultTemplate.cores,
              memory: defaultTemplate.memory,
              diskSize: defaultTemplate.diskSize,
              owners: [],
              statusId: '',
              imageId: '',
              zoneId: '',
              zoneName: '',
              platformId: '',
              platformName: '',
              login: '',
              password: '',
              ipAddress: '',
              instanceParamsId: '',
              remoteName: '',
              preemptible: true,
            }}
            submit={createSaveInstanceResource}
          />
        </div>
      </div>
    </Card>
  );
}

export { CreateInstanceResourceForm };
