import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { Sidebar } from '@components/Sidebar/Sidebar';
import styles from './MainLayout.module.scss';
import { Header } from '@components/Header/Header';
import classNames, { ArgumentArray } from 'classnames';
import { useAppSelector } from '@hooks/index';
import { isAuthenticated, useMeQuery } from '@store/user/user.slice';

type MainLayoutProps = {
  classes?: ArgumentArray;
};

const MainLayout = ({ classes }: MainLayoutProps = { classes: [] }) => {
  const mainLayoutBackground = classNames(styles.container, ...(classes || []));
  const isAuth = useAppSelector(isAuthenticated);
  useMeQuery();

  if (!isAuth) {
    return <Navigate to="/authorization" replace={true} />;
  }

  return (
    <div className={mainLayoutBackground}>
      <Sidebar />
      <Header />
      <ScrollRestoration />
      <Outlet />
    </div>
  );
};

export { MainLayout };
