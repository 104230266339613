import { useMeQuery } from '@store/user/user.slice';
import { Role } from '@store/user/contracts';

export const useGetMyRoles = () => {
  const myRoles = useMeQuery()
    ?.data?.roles?.map((myRole) => myRole.name)
    .filter((role) => role !== Role.Admin && role !== Role.Default);

  const getFormattedRoles = (roles?: Role[]) => {
    if (roles) {
      return roles.map((role) =>
        Object.keys(Role)[Object.values(Role).indexOf(role as unknown as Role)].toLowerCase(),
      );
    } else return [];
  };

  const formattedRoles = getFormattedRoles(myRoles);

  return { formattedRoles, getFormattedRoles, myRoles };
};
