import React, { useCallback, useEffect, useState } from 'react';
import { Messages } from './Messages';
import { MessageForm } from './InputMessage';
import { ChatHeader } from './ChatHeader';
import { useParams } from 'react-router-dom';

export const Chat: React.FC = () => {
  const { chatId, page, messageId } = useParams();

  const [start, setStart] = useState(0);
  const [messages, setMessages] = useState([]);
  const [totalCount] = useState(0);
  const [id, setId] = useState(chatId);

  useEffect(() => {
    setId(chatId);

    if (chatId !== id) {
      setStart(0);
      setMessages([]);
    }

    if (messageId && page) {
      const messagePage = Number(page);
      setStart((messagePage - 1) * 10);
    }
  }, [id]);

  const sendMessageAtChat = useCallback(() => {
    if (chatId === undefined) return;
  }, [chatId]);

  if (chatId === undefined) {
    return <></>;
  }

  return (
    <div
      className="col-sm-12 col-lg-8 d-none d-md-block border-start p-0  position-relative"
      style={{ background: '#FAFAFA' }}>
      <ChatHeader title={'Not implemented'} />
      <Messages messages={messages} start={start} setStart={setStart} totalCount={totalCount} />
      <MessageForm value={{ message: '' }} submit={sendMessageAtChat} />
    </div>
  );
};
