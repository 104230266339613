import { HOURS_IN_MONTH, TYPE_OF_PAYMENTS } from '@store/project/contracts';

type FormatContextTypeOfPayment =
  | { typeOfPayment: TYPE_OF_PAYMENTS.MONTHLY; hours: number }
  | {
      typeOfPayment: TYPE_OF_PAYMENTS.UNPAID | TYPE_OF_PAYMENTS.PERTASK | TYPE_OF_PAYMENTS.HOURLY;
      hours: null;
    };

export const getLocalizedPaymentType = (typeOfPayment: TYPE_OF_PAYMENTS) => {
  switch (typeOfPayment) {
    case TYPE_OF_PAYMENTS.MONTHLY:
      return 'В месяц';
    case TYPE_OF_PAYMENTS.UNPAID:
      return 'Без оплаты';
    case TYPE_OF_PAYMENTS.PERTASK:
      return 'По выполненному объёму';
    case TYPE_OF_PAYMENTS.HOURLY:
      return 'Почасовая';
  }
};

export const formatPaymentType = (context: FormatContextTypeOfPayment) => {
  switch (context.typeOfPayment) {
    case TYPE_OF_PAYMENTS.MONTHLY:
      return `${context.hours} из ${HOURS_IN_MONTH} часов в месяц`;
    case TYPE_OF_PAYMENTS.PERTASK:
      return `${getLocalizedPaymentType(TYPE_OF_PAYMENTS.PERTASK)}`;
    case TYPE_OF_PAYMENTS.HOURLY:
      return `${getLocalizedPaymentType(TYPE_OF_PAYMENTS.HOURLY)}`;
    default:
      return 'Без оплаты';
  }
};
