import { useAppDispatch } from '@hooks/index';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InstanceResourceForm, InstanceResourceFormProps } from './InstanceResourceForm';
import { createInstanceResource } from '@store/cloud/cloud.slice';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './InstanceResourceForm.module.scss';
import { CloudInstanceResource } from '@store/cloud/contracts';
import { getCloudInstanceResource } from '@store/cloud/cloud.service';

export const EditInstanceResourceForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instanceResourceId } = useParams();
  const fetched = useRef(false);
  const [instanceResource, setInstanceResource] = useState<CloudInstanceResource | null>(null);
  const [memory, setMemory] = useState(0);
  const [diskSize, setDiskSize] = useState(0);

  useEffect(() => {
    if (!fetched.current) {
      (async () => {
        if (instanceResourceId) {
          const item = await getCloudInstanceResource(instanceResourceId);
          setInstanceResource(item);
          setMemory(item?.memory / (1024 * 1024 * 1024));
          setDiskSize(item?.diskSize / (1024 * 1024 * 1024));
        }
      })();
      fetched.current = true;
    }
  }, [dispatch]);

  const editInstanceResource: InstanceResourceFormProps['submit'] = async (
    InstanceResourceForm,
    form,
  ) => {
    try {
      await dispatch(
        createInstanceResource({
          id: instanceResourceId || '',
          name: InstanceResourceForm.name,
          instance: {
            resourceId: InstanceResourceForm.instanceId,
            resourceName: InstanceResourceForm.instanceName,
            resourceType: 'instance',
            parentResourceId: InstanceResourceForm.instanceParentId,
          },
          infrastructureId: InstanceResourceForm.infrastructureId,
          deploymentId: InstanceResourceForm.deploymentId,
          cores: InstanceResourceForm.cores,
          memory: InstanceResourceForm.memory,
          owners: InstanceResourceForm.owners,
          statusId: InstanceResourceForm.statusId,
          imageId: InstanceResourceForm.imageId,
          diskSize: InstanceResourceForm.diskSize,
          zone: {
            resourceId: InstanceResourceForm.zoneId,
            resourceName: InstanceResourceForm.zoneName,
            resourceType: 'zone',
          },
          platform: {
            resourceId: InstanceResourceForm.platformId,
            resourceName: InstanceResourceForm.platformName,
            resourceType: 'platform',
          },
          login: InstanceResourceForm.login,
          password: InstanceResourceForm.password,
          ipAddress: InstanceResourceForm.ipAddress,
          instanceParamsId: InstanceResourceForm.instanceParamsId,
          remoteName: InstanceResourceForm.remoteName,
          preemptible: InstanceResourceForm.preemptible,
        }),
      ).unwrap();
      navigate(`/clouds`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Редактирование виртуальной машины</h2>
          </div>
          <InstanceResourceForm
            value={{
              name: instanceResource?.name || '',
              instanceParentId: instanceResource?.instance?.parentResourceId || '',
              instanceResourceId: instanceResource?.instance?.id || '',
              instanceId: instanceResource?.instance?.resourceId || '',
              instanceName: instanceResource?.instance?.resourceName || '',
              infrastructureId: instanceResource?.infrastructure?.id || '',
              deploymentId: instanceResource?.deployment?.id || '',
              cores: instanceResource?.cores || 0,
              memory: memory || 0,
              diskSize: diskSize || 0,
              owners: instanceResource?.owners?.map((owner) => owner.id) || [],
              statusId: instanceResource?.status?.id || '',
              imageId: instanceResource?.image?.id || '',
              zoneId: instanceResource?.zone.resourceId || '',
              zoneName: instanceResource?.zone.resourceName || '',
              platformId: instanceResource?.platform.resourceId || '',
              platformName: instanceResource?.platform.resourceName || '',
              login: instanceResource?.login || '',
              password: instanceResource?.password || '',
              ipAddress: instanceResource?.ipAddress || '',
              instanceParamsId: instanceResource?.instanceParams.id || '',
              remoteName: instanceResource?.remoteName || '',
              preemptible: instanceResource?.preemptible || false,
            }}
            submit={editInstanceResource}
          />
        </div>
      </div>
    </Card>
  );
};
