import { useGetProjectVacancyQuery } from '@store/project/project.slice';
import { useParams } from 'react-router-dom';
import styles from './Applicants.module.scss';
import { statusColors, StatusLabel } from '@components/ui/StatusLabel/StatusLabel';

import { Formik } from 'formik';
import { ApplicantsTable } from './components/ApplicantsTable';

export const Applicants = () => {
  const { vacancyId } = useParams();

  const { data: vacancy } = useGetProjectVacancyQuery({
    vacancyId: vacancyId!,
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Соискатели на вакансию {vacancy?.positionName}</h2>
        <StatusLabel color={statusColors.get(vacancy?.statusName!)} text={vacancy?.statusName!} />
      </div>
      <p className={styles.description}>
        В настоящий момент на платформе разместили свои резюме следующие пользователи.
      </p>
      <Formik
        initialValues={{
          selected: [],
        }}
        onSubmit={() => {}}>
        {() => <ApplicantsTable />}
      </Formik>
    </div>
  );
};
