import { Link, useNavigate, useParams } from 'react-router-dom';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import styles from './VacanciesTable.module.scss';
import { statusColors, StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { Tooltip } from '@mui/material';
import { DropdownMenu } from '@components/ui/DropdownMenu';
import { Button } from '@components/MUI/Button';
import { ReactComponent as UserIcon } from '@assets/img/users.svg';
import { ReactComponent as VisibilityIcon } from '@assets/img/visibility_off.svg';
import { useUrlParams } from '@hooks/useUrlParams';
import { useGetProjectVacanciesQuery } from '@store/project/project.slice';
import { useCallback } from 'react';
import { VACANCY_STATUS } from '@store/project/contracts';

const actionButtonsSx = {
  justifyContent: 'flex-start',
  borderRadius: '0px',
  color: '#39393A',
};

export const VacanciesTable = () => {
  const { projectId } = useParams();
  const { getParam } = useUrlParams();
  const navigate = useNavigate();

  const { data: vacancies, isLoading } = useGetProjectVacanciesQuery(
    {
      limit: Number(getParam('limit', 10)),
      start: (Number(getParam('page', 1)) - 1) * Number(getParam('limit', 10)),
      projectId: projectId!,
    },
    {
      skip: !projectId,
    },
  );

  const goToApplicants = useCallback(
    (id: string) => {
      navigate(`/projects/${projectId}/vacancies/${id}/applicants`);
    },
    [navigate],
  );
  return (
    <>
      <div className={styles.container}>
        <AdaptiveTableMUI
          rows={vacancies?.data ?? []}
          withHeader={false}
          emptyLabel="Нет вакансий"
          isLoading={isLoading}
          columns={{
            positionName: {
              label: '',
              children: (value, row) => {
                const applicantsAmount = row?.jobSeekersCount;
                return (
                  <Link to={`/projects/${projectId}/vacancies/${row.id}`}>
                    <div className={styles.vacancyName}>
                      <UserIcon />
                      <p>
                        Соискатели на должность {value} <span>{applicantsAmount}</span>
                      </p>
                    </div>
                  </Link>
                );
              },
            },
            requiredExecutorsCount: {
              label: '',
              children: (value, row) => {
                return (
                  <Link to={`/projects/${projectId}/vacancies/${row.id}`}>
                    <div className={styles.requiredExecutorsCount}>
                      <p>
                        Набрано {row?.acceptedExecutorsCount} из {value}
                      </p>
                    </div>
                  </Link>
                );
              },
            },
            statusName: {
              label: 'Статус',
              children: (value, row) => {
                return (
                  <Link to={`/projects/${projectId}/vacancies/${row.id}`}>
                    <div className={styles.status}>
                      <StatusLabel color={statusColors.get(value)} text={value} />
                      {value === VACANCY_STATUS.UNPUBLISHED && (
                        <Tooltip title="Скрытая вакансия" arrow>
                          <VisibilityIcon />
                        </Tooltip>
                      )}
                    </div>
                  </Link>
                );
              },
            },
            additionalButtons: {
              children: (_, row) => (
                <Button variant="contained" onClick={() => goToApplicants(row.id)}>
                  Предложить вакансию
                </Button>
              ),
            },
            actions: {
              children: () => (
                <div onClick={(e) => e.stopPropagation()} className={styles.more}>
                  <DropdownMenu>
                    <Button variant="text" sx={actionButtonsSx}>
                      Редактировать вакансию
                    </Button>
                    <Button variant="text" sx={actionButtonsSx}>
                      Закрыть вакансию
                    </Button>
                  </DropdownMenu>
                </div>
              ),
            },
          }}
        />
      </div>
    </>
  );
};
