import { FavoriteUsersResponse, ProfileMeResponse } from '@store/user/contracts';
import { ApiClient } from '@store/api-client';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';

export const getFavoriteProfiles = async (
  request: Api.operations['getFavoriteProfiles']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteProfiles']['responses']['200']['content']['application/json']
  >(`/api/profile/favorite`, request);
  return plainToInstance(FavoriteUsersResponse, data);
};

export const me = async () => {
  const { data } = await ApiClient.get<
    Api.operations['getProfileMe']['responses']['200']['content']['application/json']
  >('api/profile/me');
  return plainToInstance(ProfileMeResponse, data);
};
