import { useGetVacancyOfferLogQuery } from '@store/project/project.slice';
import styles from './Conversation.module.scss';
import { MessageBlock } from './components/MessageBlock';

type ConversationProps = {
  requestId: string;
  eventTypes?: string[];
};

export const Conversation = ({ requestId }: ConversationProps) => {
  const { data: messages } = useGetVacancyOfferLogQuery(
    {
      requestId: requestId!,
    },
    {
      skip: !requestId,
    },
  );

  if (!messages || messages?.length === 0) return null;
  else
    return (
      <div className={styles.container}>
        <p>История сообщений</p>
        <div className={styles.container__messages}>
          {messages.map((message) => (
            <MessageBlock message={message} key={message.id} />
          ))}
        </div>
      </div>
    );
};
