import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useAppDispatch } from '@hooks/index';
import {
  getCloudDeploymentResource,
  removeCloudDeploymentResource,
} from '@store/cloud/cloud.service';
import { CloudDeploymentResource } from '@store/cloud/contracts';
import { Card, CircularProgress } from '@mui/material';
import { AttributeBlock } from './AttributeBlock';
import styles from './DeploymentResourceDetail.module.scss';
import { closeModal, openModal, openModalError } from '@store/ui/ui.slice';
import { ReactComponent as DeleteIcon } from '@assets/img/wastebasket.svg';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { CardButton } from '@components/ui/CardButton';
import { ReactComponent as EditIcon } from '@assets/img/pencil.svg';

const DeploymentResourceDetail = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { deploymentResourceId } = useParams();
  const fetched = useRef(false);

  const [deploymentResource, setDeploymentResource] = useState<CloudDeploymentResource>();

  const handleCloseModal = useCallback(async () => {
    if (deploymentResourceId !== undefined)
      await removeCloudDeploymentResource(deploymentResourceId);
    dispatch(closeModal());
    navigate(`/clouds/deployments`);
  }, [dispatch]);

  const deleteDeployment = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите правило?"
          btnProp="Удалить"
          onSubmit={handleCloseModal}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!fetched.current && deploymentResourceId) {
      (async () => {
        try {
          const item = await getCloudDeploymentResource(deploymentResourceId);
          setDeploymentResource(item);
        } catch (e: any) {
          dispatch(openModalError(e));
        } finally {
          fetched.current = true;
        }
      })();
    }
  }, [dispatch, deploymentResourceId]);

  return (
    <div className={`col common_bc container-content ${styles.containerContent}`}>
      {fetched.current ? (
        <>
          <div className="mt-4 ms-4 d-flex gap-2">
            <NavigationBack back={{ to: '/clouds/deployments/' }} />
          </div>
          <Card className="p-4 ms-4 me-4" elevation={0} variant="outlined">
            {(deploymentResource?.status == null ||
              deploymentResource?.status?.code === -2 ||
              deploymentResource?.status?.code === 200) && (
              <div className={`row ${styles.titleInstanceName}`}>
                <div className={`col ${styles.blockMenu}`}>
                  <h2 className={styles.title}>{deploymentResource?.name}</h2>
                  <CardButton
                    title="Редактировать"
                    icon={<EditIcon />}
                    onClick={() => navigate(`/clouds/deployments/${deploymentResourceId}/edit`)}
                  />
                  <CardButton title="Удалить" icon={<DeleteIcon />} onClick={deleteDeployment} />
                </div>
              </div>
            )}

            <div className={styles.resource__detail__cards}>
              <Card className={styles.resource__detail__cards__1} elevation={0} variant="outlined">
                <AttributeBlock
                  title="Платформа"
                  value={deploymentResource?.platform?.resourceName || 'Нет'}
                />
                <AttributeBlock
                  title="Инфраструктура"
                  value={deploymentResource?.infrastructure?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Зона"
                  value={deploymentResource?.zone?.resourceName || 'Нет'}
                />
                <AttributeBlock
                  title="Шаблон"
                  value={deploymentResource?.instanceParams?.name || 'Нет'}
                />
                <AttributeBlock title="Правила" value={deploymentResource?.rule?.name || 'Нет'} />
                <AttributeBlock
                  title="Группа виртуальных машин"
                  value={deploymentResource?.instanceGroup?.resourceName || 'Нет'}
                />
                <AttributeBlock
                  title="Количество виртуальных машин"
                  value={deploymentResource?.numberInstance?.toString() || '0'}
                />
                <AttributeBlock
                  title="Ядро"
                  value={deploymentResource?.instanceParams?.cores?.toString() || '0'}
                />
              </Card>
              <Card className={styles.resource__detail__cards__2} elevation={0} variant="outlined">
                <AttributeBlock
                  title="Использовать образ виртуальной машины"
                  value={deploymentResource?.useMasterInstance ? 'Да' : 'Нет'}
                />
                <AttributeBlock
                  title="Мастер машина образа"
                  value={deploymentResource?.masterInstance?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Образ машины"
                  value={deploymentResource?.image?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Прерываемая"
                  value={deploymentResource?.preemptible ? 'Да' : 'Нет'}
                />
                <AttributeBlock
                  title="Расписание"
                  value={deploymentResource?.shedule?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Действие на старте расписания"
                  value={deploymentResource?.startAction?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Действие на окончании расписания"
                  value={deploymentResource?.endAction?.name || 'Нет'}
                />
              </Card>
            </div>
          </Card>
        </>
      ) : (
        <div className={styles.loadingBlock}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export { DeploymentResourceDetail };
