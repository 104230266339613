import React from 'react';
import { AvatarProps } from './Avatar';
import { ReactComponent as EditIcon } from '@assets/img/editIcon.svg';
import styles from './Avatar.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { CircularProgress } from '@mui/material';

type AvatarImageProps = AvatarProps & {
  iconUrl?: string;
  myShortName: string;
  showOverlay: boolean;
  handleOverlay: () => void;
  handleOverlayLeave: () => void;
  handlePhotoHover: (event: React.SyntheticEvent<EventTarget>) => void;
  userId: string | null;
  loading?: boolean;
  width?: number;
  height?: number;
  classNameImgAvatar?: string;
};

export const AvatarImage: React.FC<AvatarImageProps> = ({
  iconUrl,
  myShortName,
  showOverlay,
  handleOverlay,
  handleOverlayLeave,
  handlePhotoHover,
  userId,
  loading,
  width = 180,
  height = 180,
  classNameImgAvatar,
}) => {
  const myId = useAppSelector(selectCurrentUserId);
  return (
    <div>
      <div
        onMouseEnter={handleOverlay}
        onMouseLeave={handleOverlayLeave}
        className={classNames(
          styles.default__icon,
          { [styles.icon]: userId === myId || !userId },
          classNameImgAvatar,
        )}
        onClick={handlePhotoHover}>
        {showOverlay && (
          <div className={styles.editIcon}>
            <EditIcon />
          </div>
        )}

        {loading ? (
          <div className={styles.circularProgressContainer}>
            <CircularProgress />
          </div>
        ) : iconUrl ? (
          <img
            className={classNameImgAvatar}
            src={iconUrl}
            alt="avatar"
            width={width}
            height={height}
            onMouseEnter={handleOverlay}
            onMouseLeave={handleOverlayLeave}
          />
        ) : (
          myShortName
        )}
      </div>
    </div>
  );
};
