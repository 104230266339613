import styles from './ParticipantsTable.module.scss';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as MessageIcon } from '@assets/img/messageIcon.svg';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { Button } from '@components/MUI/Button';
import { DropdownMenu } from '@components/ui/DropdownMenu';
import { IconButton } from '@components/ui/Button/IconButton';
import { AbilityContext } from '@access-control';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { useAbility } from '@casl/react';
import { subject } from '@casl/ability';

export const ParticipantsTable = () => {
  const createChat = useNavigateToUserChat();

  const { projectId } = useParams();
  const { data: project } = useDetailProjectQuery(projectId || '');

  const handleChatClick = (id: string) => {
    createChat(id);
  };

  const ability = useAbility(AbilityContext);

  const canUpdate = ability.can('update', subject('Project', { teamLeadId: project?.teamLeadId }));

  return (
    <div className={styles.container}>
      <h4>Исполнители</h4>
      <AdaptiveTableMUI
        emptyLabel="Участники отсутствуют"
        rows={project?.projectPositions || []}
        columns={{
          userName: {
            label: 'Исполнитель',
            children: (value, row) => (
              <Link to={`/userPage/${row.userId}`} className={styles.executor}>
                {value}
              </Link>
            ),
          },
          positionName: {
            label: 'Должность',
          },
          telegramNickname: {
            label: 'Ник в Telegram',
          },
          userId: {
            label: '',
            children: (value) => (
              <IconButton onClick={() => handleChatClick(value)}>
                <MessageIcon />
              </IconButton>
            ),
          },
          ...(canUpdate && {
            more: {
              label: '',
              children: () => (
                <div className={styles.more}>
                  <div>
                    <DropdownMenu>
                      <Button variant="text" className={styles.more_option}>
                        Открепить исполнителя
                      </Button>
                    </DropdownMenu>
                  </div>
                </div>
              ),
            },
          }),
        }}
      />
    </div>
  );
};
