import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import avatar from '@assets/img/avatar.svg';
import { Dayjs } from 'dayjs';
import { Button } from '@components/MUI/Button';
import styles from './FavoriteMessageItem.module.scss';

type FavoriteMessageItemProps = {
  id: string;
  authorAvatar?: string;
  authorName: string;
  autorId: string;
  messageText: string;
  sendDate: Dayjs;
  refetch: () => void;
  isLastMessage: boolean;
  totalMessages: number;
  chatId: string;
};

export const FavoriteMessageItem: React.FC<FavoriteMessageItemProps> = ({
  authorAvatar,
  authorName,
  autorId,
  messageText,
  sendDate,
  isLastMessage,
}) => {
  const handleGoToMessage = () => {
    return null;
  };

  return (
    <>
      <li className={styles.favoriteMessage}>
        <div className={styles.author}>
          <img
            src={authorAvatar ? authorAvatar : avatar}
            className={styles.authorAvatar}
            alt="avatar"
          />
          <Link to={`/userPage/${autorId}`} className={styles.authorName}>
            {authorName}
          </Link>
        </div>
        <span className={styles.messageText}>{messageText}</span>
        <span className={styles.sendDate}>{sendDate.format('DD.MM.YYYY HH:mm')}</span>
        <Button variant="outlined" className={styles.button} onClick={handleGoToMessage}>
          Перейти к сообщению
        </Button>
      </li>
      {!isLastMessage ? (
        <Divider style={{ borderColor: '#78858b', marginRight: '1.5rem' }} />
      ) : (
        <></>
      )}
    </>
  );
};
