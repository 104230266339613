import { useState } from 'react';
import { useUrlParams } from '@hooks/useUrlParams';

interface UsePaginationParams {
  defaultPage?: number;
  defaultLimit?: number;
}

export const useUrlPagination = ({ defaultPage = 1, defaultLimit = 10 }: UsePaginationParams) => {
  const { updateParams, getParam } = useUrlParams();

  const [limit, setLimit] = useState<number>(Number(getParam('limit', defaultLimit)));
  const [page, setPage] = useState<number>(Number(getParam('page', defaultPage)));

  const handleChangeRowsPerPage = (newLimit: number) => {
    updateParams({ limit: newLimit, page: 1 });
    setLimit(newLimit);
    setPage(1);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    updateParams({ page: newPage });
    setPage(newPage);
  };

  const start = (page - 1) * limit;

  return {
    limit,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getParam,
    updateParams,
    start,
  };
};
