import { FormWrapper } from '@components/Form/Form';
import { Modal } from '@components/MUI/Modal/Modal';
import styles from './DecisionModal.module.scss';
import { DecisionForm, DecisionFormProps, DecisionFormValue } from './DecisionForm';
import { Conversation } from './Conversation';
import { useCallback } from 'react';
import { TYPE_OF_PAYMENTS } from '@store/project/contracts';

export const decisionTextPresets = Object.freeze({
  agree: (name: string) => `Здравствуйте, ${name}! Условия согласованы.`,
  disagree: (name: string, directionName: string, positionName: string) =>
    `Здравствуйте, ${name}! Спасибо за интерес, проявленный к данной вакансии. К сожалению, на данный момент мы не готовы пригласить вас на данный проект в должности «${positionName}» по направлению «${directionName}».`,
  newConditions: (name: string, directionName: string, positionName: string) =>
    `Здравствуйте, ${name}! Прошу согласовать изменение условий сотрудничества в рамках вакансии «${positionName}» по направлению «${directionName}»`,
});

export type DecisionOnSubmitValues = {
  id: string;
  payload: DecisionFormValue;
};

type DecisionModalPropsValue = {
  employerName: string;
  projectName: string;
  payment: number;
  typeOfPayment: TYPE_OF_PAYMENTS;
  workloadPerMonth: number;
  requestId: string;
};

type DecisionModalProps = {
  values: DecisionModalPropsValue;
  headerLabel: string;
  buttonLabel: string;
  onSubmit: (values: DecisionOnSubmitValues) => void;
  initialMessage?: string;
  shouldCheckForPristine?: boolean;
};

export const DecisionModal = ({
  values,
  buttonLabel,
  headerLabel,
  initialMessage,
  onSubmit,
  shouldCheckForPristine,
}: DecisionModalProps) => {
  const handleSubmit: DecisionFormProps['submit'] = useCallback((payload) => {
    onSubmit({
      id: values.requestId,
      payload,
    });
  }, []);
  return (
    <Modal className={styles.modal}>
      <FormWrapper
        label={headerLabel}
        wrapperClassName={styles.wrapper}
        titleClassName={styles.title}>
        <div className={styles.container}>
          <div className={styles.container__credentials}>
            <div>
              <p>ФИО инициатора</p>
              <p>{values.employerName}</p>
            </div>
            <div>
              <p>Роль инициатора на проекте</p>
              <p>Менеджер</p>
            </div>
            <div>
              <p>Наименование проекта</p>
              <p>{values.projectName}</p>
            </div>
          </div>
          <div className={styles.container__form}>
            <DecisionForm
              submit={handleSubmit}
              buttonLabel={buttonLabel}
              value={{
                message: initialMessage ?? '',
                payment: values?.payment,
                typeOfPayment: values?.typeOfPayment,
                workloadPerMonth: values?.workloadPerMonth,
              }}
              checkForPristine={shouldCheckForPristine}
            />
          </div>
          <div className={styles.container__converstaion}>
            <Conversation requestId={values.requestId} />
          </div>
        </div>
      </FormWrapper>
    </Modal>
  );
};
