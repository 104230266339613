import { NavLink } from 'react-router-dom';
import styles from './ProjectsHeader.module.scss';

interface ProjectHeaderProps {
  projectId: string;
}

export const ProjectsHeader = ({ projectId }: ProjectHeaderProps) => {
  return (
    <header className={styles.header}>
      <NavLink
        to={`/projects/${projectId}/details`}
        className={({ isActive }) => (isActive ? 'selected' : '')}>
        Общая информация
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/customer`}
        className={({ isActive }) => (isActive ? 'selected' : '')}>
        Заказчик
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/participants`}
        className={({ isActive }) => (isActive ? 'selected' : '')}>
        Исполнители
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/vacancies`}
        className={({ isActive }) => (isActive ? 'selected' : '')}>
        Вакансии
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/tasks`}
        className={({ isActive }) => (isActive ? 'selected' : '')}>
        Задачи
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/analytics`}
        className={({ isActive }) => (isActive ? 'selected' : '')}>
        Аналитика
      </NavLink>
    </header>
  );
};
