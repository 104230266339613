import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability';
import { ProfileMeResponse, Role } from './contracts';
import { Project } from '@store/project/contracts';

type Actions = 'create' | 'read' | 'update' | 'delete' | 'leave' | 'close' | 'execute' | 'control';

type User = ProfileMeResponse;

type DashboardSubjects =
  | 'AnalyticsRequest'
  | 'AnalyticsProjects'
  | 'InfoAboutVacancy'
  | 'InfoAboutTasks'
  | 'Dashboard'
  | 'InfoAboutPay';

type RequestSubjects =
  | 'MyAssignedRequests'
  | 'RequestList'
  | 'RequestsCreatedByMe'
  | 'Request'
  | 'ApprovedRequests';

type TaskSubjects = 'Task';

type ProjectSubjects =
  | 'CustomerProjects'
  | 'ManagerProjects'
  | 'ExecutorProjects'
  | 'Projects'
  | 'Project'
  | Partial<Project>;

type RequisiteSubjects = 'Requisite';

type Subjects =
  | DashboardSubjects
  | RequestSubjects
  | TaskSubjects
  | ProjectSubjects
  | RequisiteSubjects;

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export function defineAbilityFor(user: User) {
  const { build, can } = new AbilityBuilder<AppAbility>(createMongoAbility);
  if (!user) return;
  const userRoles = new Set(user.roles.map((role) => role.name));

  if (userRoles.has(Role.Manager)) {
    can('read', 'AnalyticsRequest');
    can('read', 'InfoAboutTasks');
    can('read', 'InfoAboutPay');
    can('read', 'MyAssignedRequests');
    can('read', 'RequestList');
    can('read', 'RequestsCreatedByMe');
    can('read', 'ApprovedRequests');
    can('read', 'ManagerProjects');
    can('read', 'Dashboard');
    can('read', 'ApprovedRequests');
    can('create', 'Task');
    can('read', 'AnalyticsProjects');
    can('create', 'Requisite');
    can('control', 'Project');
  }

  if (userRoles.has(Role.Executor)) {
    can('read', 'InfoAboutVacancy');
    can('read', 'InfoAboutTasks');
    can('read', 'ExecutorProjects');
    can('read', 'Dashboard');
    can('create', 'Task');
    can('create', 'Requisite');
    can('execute', 'Project');
  }

  if (userRoles.has(Role.Customer)) {
    can('read', 'AnalyticsRequest');
    can('read', 'AnalyticsProjects');
    can('read', 'InfoAboutPay');
    can('read', 'MyAssignedRequests');
    can('read', 'RequestList');
    can('read', 'RequestsCreatedByMe');
    can('create', 'Request');
    can('update', 'Request');
    can('read', 'CustomerProjects');
    can('read', 'Dashboard');
    can('update', 'Request');
    can('create', 'Requisite');
    can('control', 'Project');
  }

  can('update', 'Project', {
    teamLeadId: user.id,
  });

  return build();
}
