import React, { useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Link } from 'react-router-dom';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

export const FavoriteVacancies: React.FC = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } = useRequestBody();

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      columns={{
        name: {
          label: 'Название команды',
          filterInput: true,
          children: (value, row) => <Link to={`/teams/${row.teamId}`}>{value}</Link>,
        },
        positionName: {
          label: 'Роль',
          filterInput: true,
        },
        stack: {
          label: 'Стек',
          filterInput: true,
        },
        payment: {
          label: 'Ставка',
          filterInput: true,
          isSortColumn: true,
          format: (value) => `${value} р/ч`,
        },
        comment: {
          label: 'Комментарий',
          filterInput: true,
        },
        statusName: {
          children: (value: string) =>
            value ? (
              value === 'Оффер от исполнителя' || value === 'Оффер от менеджера' ? (
                <StatusLabel color={statusColors.get('На рассмотрении')} text={'На рассмотрении'} />
              ) : (
                <StatusLabel color={statusColors.get(value)} text={value} />
              )
            ) : (
              <>Создание</>
            ),
        },
      }}
      rows={[]}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}>
      <TablePaginationMUI
        limit={limit}
        count={0}
        entityLabel="вакансий"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
