import { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useAppDispatch } from '@hooks/index';
import { getCloudDelegation, removeCloudDelegation } from '@store/cloud/cloud.service';
import { CloudDelegation } from '@store/cloud/contracts';
import { ReactComponent as EditIcon } from '@assets/img/pencil.svg';
import styles from '@pages/cloud/delegations/DelegationForm.module.scss';
import { CardButton } from '@components/ui/CardButton';
import { CircularProgress } from '@mui/material';
import { closeModal, openModal, openModalError } from '@store/ui/ui.slice';
import { ReactComponent as DeleteIcon } from '@assets/img/wastebasket.svg';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';

const DelegationDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { delegationId } = useParams();
  const fetched = useRef(false);

  const [delegation, setDelegation] = useState<CloudDelegation>();

  //const infrastructureCreationAllow = delegation?.enabledCreateInfrastructure ? 'Да' : 'Нет';
  const allocateBudgetAllow = delegation?.allocateBudget ? 'Да' : 'Нет';

  const handleCloseModal = useCallback(async () => {
    //const delegationId = delegation?.id || "";
    if (delegationId !== undefined) await removeCloudDelegation(delegationId);
    dispatch(closeModal());
    navigate(`/clouds/delegations`);
  }, [dispatch]);

  const deleteDelegation = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите удалить делегирование?"
          btnProp="Удалить"
          onSubmit={handleCloseModal}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!fetched.current && delegationId) {
      (async () => {
        try {
          const item = await getCloudDelegation(delegationId);
          setDelegation(item);
        } catch (e: any) {
          dispatch(openModalError(e));
        } finally {
          fetched.current = true;
        }
      })();
    }
  }, [dispatch, delegationId]);

  return (
    <div className={`col common_bc container-content ${styles.containerContent}`}>
      {fetched.current ? (
        <>
          <div className="mt-4 d-flex gap-2">
            <NavigationBack back={{ to: '/clouds/delegations' }} />
          </div>
          <Card className={styles.card}>
            <div className={`row ${styles.titleDelegationName}`}>
              <div className={`col ${styles.blockMenu}`}>
                <h2 className={styles.title}>{delegation?.name}</h2>
                {!delegation?.closed && delegation?.distributed === 0 && (
                  <div className={styles.btnBlock}>
                    <CardButton
                      title="Редактировать"
                      icon={<EditIcon />}
                      onClick={() => navigate(`/clouds/delegations/${delegationId}/edit`)}
                    />
                    <CardButton title="Удалить" icon={<DeleteIcon />} onClick={deleteDelegation} />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <Card className={styles.cardContent}>
                  <div className="mb-4">
                    <div className={styles.subTitle}>Название делегата</div>
                    <div className={styles.textName}>{delegation?.name}</div>
                  </div>
                  <div className="mb-4">
                    <div className={styles.subTitle}>Основание</div>
                    <div className={styles.textName}>{delegation?.parent?.name || '-'}</div>
                  </div>
                  <div className="mb-4">
                    <div className={styles.subTitle}>Организация</div>
                    <div className={styles.textName}>{delegation?.organization?.resourceName}</div>
                  </div>
                  <div className="mb-4">
                    <div className={styles.subTitle}>Платежный аккаунт</div>
                    <div className={styles.textName}>
                      {delegation?.billingAccount?.resourceName || '-'}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className={styles.subTitle}>Облако</div>
                    <div className={styles.textName}>{delegation?.cloud?.resourceName}</div>
                  </div>
                  <div className="mb-4">
                    <div className={styles.subTitle}>Проект</div>
                    <div className={styles.textName}>{delegation?.project?.name}</div>
                  </div>
                </Card>
              </div>
              <div className="col-12 col-lg-6">
                <Card className="p-4 mb-4 h-100">
                  <div className="mb-4">
                    <div className={styles.subTitle}>Выделить бюджет</div>
                    <div className={styles.textName}>{allocateBudgetAllow}</div>
                  </div>
                  {delegation?.allocateBudget && (
                    <div className="mb-4">
                      <div className={styles.subTitle}>Бюджет</div>
                      <div className={styles.textName}>
                        {delegation?.budget} {delegation?.currency.name}
                      </div>
                    </div>
                  )}
                  {delegation?.allocateBudget && (
                    <div className="mb-4">
                      <div className={styles.subTitle}>Действие при достижении суммы</div>
                      <div className={styles.textName}>{delegation?.budgetLimitAction?.name}</div>
                    </div>
                  )}
                  {delegation?.allocateBudget && (
                    <div className="mb-4">
                      <div className={styles.subTitle}>Период сброса бюджетного правила</div>
                      <div className={styles.textName}>{delegation?.resetBudgetType}</div>
                    </div>
                  )}
                  {delegation?.allocateBudget && (
                    <div className="mb-4">
                      <div className={styles.subTitle}>Дата окончания бюджета</div>
                      <div className={styles.textName}>{delegation?.budgetEndDate}</div>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </Card>
        </>
      ) : (
        <div className={styles.loadingBlock}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export { DelegationDetail };
