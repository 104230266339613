import { FC, ReactElement } from 'react';
import styles from './form.module.scss';
import classNames from 'classnames';

interface FormData {
  children: ReactElement[] | ReactElement | undefined;
  label: string;
  titleClassName?: string;
  isThinTitle?: boolean;
  isSmallModal?: boolean;
  wrapperClassName?: string;
}

export const FormWrapper: FC<FormData> = ({
  children,
  label,
  titleClassName,
  isThinTitle,
  isSmallModal,
  wrapperClassName,
}) => {
  const formWrapper = classNames(
    styles.form__wrapper,
    isSmallModal ? styles.small__modal : '',
    wrapperClassName,
  );
  const labelClass = classNames(
    styles.label,
    titleClassName,
    isThinTitle ? styles.thin__label : '',
  );

  return (
    <div className={formWrapper}>
      <h2 className={labelClass}>{label}</h2>
      <div>{children}</div>
    </div>
  );
};
