import { RouterProvider } from 'react-router-dom';
import { useAppSelector } from '@hooks/index';
import { AbilityContext } from '@access-control';
import { useAppRoutes } from './routes/useAppRoutes';
import { selectProfile } from '@store/user/user.slice';
import { AppAbility, defineAbilityFor } from '@store/user/ability';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';

const { build } = new AbilityBuilder<AppAbility>(createMongoAbility);

function App() {
  const user = useAppSelector(selectProfile);
  const ability = user ? defineAbilityFor(user) : build();
  const router = useAppRoutes();

  return (
    <AbilityContext.Provider value={ability!}>
      <RouterProvider router={router} />
    </AbilityContext.Provider>
  );
}

export { App };
