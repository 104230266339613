import { RequestParams } from '@components/Table/TableHeadCell';
import { useMemo, useState } from 'react';

export const useRequestBody = (initialRequestBody: Record<string, RequestParams> = {}) => {
  const [requestBody, setRequestBody] = useState<Record<string, RequestParams>>(initialRequestBody);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const query = useMemo(() => {
    return Object.keys(requestBody).reduce((acc: { [key: string]: string }, key: string) => {
      if (requestBody[key].value !== undefined) {
        acc[key] = requestBody[key].value!;
      }
      return acc;
    }, {});
  }, [requestBody]);

  return { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder, query };
};
