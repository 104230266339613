import styles from '@pages/landing/Landing.module.scss';

import projects from '@assets/img/landing/projets.png';
import base1 from '@assets/img/landing/base1.png';
import base2 from '@assets/img/landing/base2.png';
import diploma from '@assets/img/landing/diploma.png';
import image_tracker from '@assets/img/landing/image_tracker.png';
import image_360 from '@assets/img/landing/image_360.png';
import image_cloud from '@assets/img/landing/image_cloud.png';
import actual_work from '@assets/img/landing/actual_work.png';
import computer from '@assets/img/landing/iMac.png';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ButtonMUI } from '@components/MUI/ButtonMUI';

export const Landing = () => {
  return (
    <div className={classNames(styles.landing)}>
      <section className={styles.banner}>
        <div className={classNames(styles.banner__content, styles.container)}>
          <div className={styles.banner__content__wrapper}>
            <h3 className={classNames(styles.white__text, 'mb-3')}>
              <span className={styles.green__text}>«Эджайл»</span> — многофункциональная платформа
              для ведения и мониторинга исполнения проектов
            </h3>
            <p className={classNames(styles.white__text)}>
              Используйте интеграцию различных сервисов и инструментов для управления рабочими
              процессами, командой и поиска единомышленников, чтобы повысить производительность
              проектной работы и снизить общие затраты
            </p>
          </div>
          <div className={styles.computer__block}>
            <img src={computer} alt="компьютер" />
          </div>
        </div>
      </section>

      <div className={styles.main}>
        <div className={classNames(styles.container)}>
          <section className={styles.components}>
            <h2 className={classNames('mb-4')}>
              Основные <br />
              <span className={styles.green__text}> компоненты платформы</span>
            </h2>
            <div className={styles.components__card}>
              <h4>Платформа разработана для проектной деятельности и включает в себя:</h4>
              <div className={styles.components__card__list}>
                <div className={classNames('d-flex', 'align-items-center', 'flex-nowrap')}>
                  <svg
                    className={classNames('me-3')}
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="68" height="68" rx="4" fill="#9DC2FF" />
                    <path
                      d="M20.503 47.0203C20.503 46.2391 22.4259 45.3178 23.7479 45.0173H23.8581L31.8701 42.0128C32.0718 41.9379 32.244 41.8001 32.3613 41.6197C32.4785 41.4393 32.5346 41.226 32.5211 41.0113V38.3172C32.5206 38.1284 32.4667 37.9437 32.3657 37.7842C32.2647 37.6247 32.1207 37.497 31.9503 37.4158C30.9243 36.9295 30.0571 36.1625 29.4491 35.2035C28.8412 34.2446 28.5173 33.1331 28.5151 31.9977C28.5151 31.7321 28.4096 31.4773 28.2218 31.2895C28.0339 31.1017 27.7792 30.9962 27.5136 30.9962V28.9932C27.7792 28.9932 28.0339 28.8876 28.2218 28.6998C28.4096 28.512 28.5151 28.2573 28.5151 27.9917V25.9886C28.5151 24.3949 29.1482 22.8665 30.2751 21.7396C31.402 20.6127 32.9304 19.9796 34.5241 19.9796C36.1178 19.9796 37.6463 20.6127 38.7732 21.7396C39.9001 22.8665 40.5332 24.3949 40.5332 25.9886V27.9917C40.5332 28.2573 40.6387 28.512 40.8265 28.6998C41.0144 28.8876 41.2691 28.9932 41.5347 28.9932V30.9962H43.5377V28.9932C43.5374 28.6419 43.4446 28.2969 43.2689 27.9928C43.0931 27.6887 42.8404 27.4362 42.5362 27.2605V25.9886C42.5362 23.8637 41.6921 21.8258 40.1895 20.3232C38.687 18.8207 36.6491 17.9766 34.5241 17.9766C32.3992 17.9766 30.3613 18.8207 28.8588 20.3232C27.3562 21.8258 26.5121 23.8637 26.5121 25.9886V27.2605C26.2079 27.4362 25.9552 27.6887 25.7794 27.9928C25.6036 28.2969 25.5109 28.6419 25.5106 28.9932V30.9962C25.5074 31.3508 25.5984 31.7 25.7744 32.0079C25.9504 32.3158 26.2049 32.5715 26.5121 32.7488C26.6442 34.0207 27.0761 35.2431 27.7726 36.3156C28.469 37.3881 29.4099 38.28 30.5181 38.9181V40.3102L23.2071 43.0143C22.2957 43.2446 18.5 44.3864 18.5 47.0203V49.0233C18.5 49.289 18.6055 49.5437 18.7933 49.7315C18.9812 49.9193 19.2359 50.0248 19.5015 50.0248H33.5226V48.0218H20.503V47.0203Z"
                      fill="white"
                    />
                    <path
                      d="M45.4363 42.4851C46.31 41.2573 46.6905 39.746 46.5023 38.2508C46.3141 36.7557 45.571 35.3858 44.4203 34.4127C43.2696 33.4397 41.7952 32.9345 40.2896 32.9974C38.7839 33.0603 37.3569 33.6866 36.2913 34.7522C35.2257 35.8178 34.5994 37.2449 34.5365 38.7505C34.4736 40.2562 34.9788 41.7305 35.9518 42.8812C36.9248 44.032 38.2947 44.7751 39.7899 44.9633C41.2851 45.1514 42.7964 44.771 44.0242 43.8973L48.8515 48.7245L50.2636 47.3124L45.4363 42.4851ZM40.539 43.0159C39.7466 43.0159 38.9721 42.781 38.3133 42.3408C37.6545 41.9006 37.1411 41.275 36.8379 40.543C36.5347 39.8109 36.4553 39.0055 36.6099 38.2284C36.7645 37.4513 37.146 36.7375 37.7063 36.1772C38.2665 35.617 38.9803 35.2354 39.7574 35.0809C40.5345 34.9263 41.34 35.0056 42.072 35.3088C42.804 35.612 43.4297 36.1255 43.8699 36.7843C44.31 37.4431 44.545 38.2176 44.545 39.0099C44.545 40.0724 44.1229 41.0913 43.3717 41.8426C42.6204 42.5939 41.6014 43.0159 40.539 43.0159Z"
                      fill="white"
                    />
                  </svg>
                  <p>Внутреннюю базу специалистов</p>
                </div>

                <div className={classNames('d-flex', 'align-items-center', 'flex-nowrap')}>
                  <svg
                    className={classNames('me-3')}
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="68" height="68" rx="4" fill="#FFCF70" />
                    <g clipPath="url(#clip0_15983_158652)">
                      <path
                        d="M22.9445 18.0647L33.5784 12.8094L33.5774 12.8074C33.8686 12.6634 34.1988 12.6862 34.4597 12.8387L45.092 18.0932L44.6815 18.9252L45.0935 18.0914C45.554 18.319 45.7428 18.8768 45.5153 19.3372C45.4152 19.5405 45.2442 19.7 45.0345 19.7856L34.4005 25.0409L34.4015 25.043C34.1103 25.1869 33.7802 25.1642 33.5192 25.0117L22.8869 19.7571L23.2975 18.9251L22.8854 19.7589C22.4249 19.5314 22.2361 18.9736 22.4637 18.5131C22.5637 18.3099 22.7348 18.1504 22.9445 18.0647ZM33.9895 14.676L25.3915 18.9251L33.9895 23.1743L42.5875 18.9251L33.9895 14.676Z"
                        fill="white"
                      />
                      <path
                        d="M24.2274 18.9222C24.2274 18.4086 23.8109 17.9922 23.2973 17.9922C22.7836 17.9922 22.3672 18.4086 22.3672 18.9222V31.3513C22.3672 31.865 22.7836 32.2814 23.2973 32.2814C23.8109 32.2814 24.2274 31.865 24.2274 31.3513V18.9222ZM34.9195 24.2197C34.9195 23.7061 34.5031 23.2897 33.9894 23.2897C33.4758 23.2897 33.0593 23.7061 33.0593 24.2197V36.6488C33.0593 37.1625 33.4758 37.5789 33.9894 37.5789C34.5031 37.5789 34.9195 37.1625 34.9195 36.6488V24.2197ZM45.6114 18.9222C45.6114 18.4086 45.195 17.9922 44.6814 17.9922C44.1677 17.9922 43.7512 18.4086 43.7512 18.9222V31.3513C43.7512 31.865 44.1677 32.2814 44.6813 32.2814C45.195 32.2814 45.6114 31.865 45.6114 31.3513V18.9222Z"
                        fill="white"
                      />
                      <path
                        d="M12.257 35.7835L22.8909 30.5282L22.8899 30.5261C23.1811 30.3822 23.5113 30.4049 23.7722 30.5574L34.4045 35.812L33.994 36.644L34.406 35.8102C34.8665 36.0377 35.0553 36.5955 34.8278 37.056C34.7277 37.2592 34.5567 37.4187 34.347 37.5044L23.713 42.7597L23.714 42.7617C23.4228 42.9057 23.0927 42.8829 22.8317 42.7304L12.1994 37.4759L12.61 36.6439L12.1979 37.4777C11.7374 37.2501 11.5486 36.6923 11.7762 36.2318C11.8762 36.0286 12.0473 35.8691 12.257 35.7835ZM23.302 32.3947L14.704 36.6439L23.302 40.8931L31.9 36.6439L23.302 32.3947Z"
                        fill="white"
                      />
                      <path
                        d="M13.5399 36.641C13.5399 36.1273 13.1234 35.7109 12.6098 35.7109C12.0961 35.7109 11.6797 36.1273 11.6797 36.641V49.0701C11.6797 49.4596 11.9193 49.7933 12.2591 49.9317L22.89 55.1989L22.8889 55.2009C23.1807 55.3455 23.5116 55.3227 23.773 55.1695L34.4042 49.902C34.8637 49.6753 35.0524 49.119 34.8257 48.6595C34.599 48.2 34.0427 48.0114 33.5832 48.2381L23.3018 53.3322L13.5399 48.4955V36.641V36.641Z"
                        fill="white"
                      />
                      <path
                        d="M24.2352 41.9386C24.2352 41.4249 23.8187 41.0085 23.3051 41.0085C22.7914 41.0085 22.375 41.4249 22.375 41.9386V54.3676C22.375 54.8813 22.7914 55.2977 23.3051 55.2977C23.8187 55.2977 24.2352 54.8813 24.2352 54.3676V41.9386ZM34.9269 36.641C34.9269 36.1273 34.5105 35.7109 33.9968 35.7109C33.4832 35.7109 33.0667 36.1273 33.0667 36.641V49.0701C33.0667 49.5837 33.4832 50.0001 33.9968 50.0001C34.5105 50.0001 34.9269 49.5837 34.9269 49.0701V36.641Z"
                        fill="white"
                      />
                      <path
                        d="M33.6554 35.7835L44.2894 30.5282L44.2883 30.5261C44.5796 30.3822 44.9097 30.4049 45.1706 30.5574L55.8029 35.812L55.3924 36.644L55.8045 35.8102C56.265 36.0377 56.4538 36.5955 56.2262 37.056C56.1261 37.2592 55.9551 37.4187 55.7454 37.5044L45.1115 42.7597L45.1125 42.7617C44.8213 42.9057 44.4911 42.8829 44.2302 42.7304L33.5979 37.4759L34.0084 36.6439L33.5963 37.4777C33.1358 37.2501 32.947 36.6923 33.1746 36.2318C33.2747 36.0286 33.4457 35.8691 33.6554 35.7835ZM44.7004 32.3947L36.1024 36.6439L44.7004 40.8931L53.2984 36.6439L44.7004 32.3947Z"
                        fill="white"
                      />
                      <path
                        d="M34.9383 36.641C34.9383 36.1273 34.5219 35.7109 34.0082 35.7109C33.4946 35.7109 33.0781 36.1273 33.0781 36.641V49.0701C33.0781 49.4596 33.3177 49.7933 33.6576 49.9317L44.2884 55.1989L44.2874 55.2009C44.5791 55.3455 44.9101 55.3227 45.1714 55.1695L55.8027 49.902C56.2622 49.6753 56.4508 49.119 56.2241 48.6595C55.9974 48.2 55.4411 48.0114 54.9816 48.2381L44.7003 53.3322L34.9383 48.4955V36.641V36.641Z"
                        fill="white"
                      />
                      <path
                        d="M45.6258 41.9386C45.6258 41.4249 45.2094 41.0085 44.6957 41.0085C44.1821 41.0085 43.7656 41.4249 43.7656 41.9386V54.3676C43.7656 54.8813 44.1821 55.2977 44.6957 55.2977C45.2094 55.2977 45.6258 54.8813 45.6258 54.3676V41.9386ZM56.3177 36.641C56.3177 36.1273 55.9013 35.7109 55.3876 35.7109C54.874 35.7109 54.4575 36.1273 54.4575 36.641V49.0701C54.4575 49.5837 54.874 50.0001 55.3876 50.0001C55.9013 50.0001 56.3177 49.5837 56.3177 49.0701V36.641Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15983_158652">
                        <rect width="50" height="50" fill="white" transform="translate(9 9)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>Инфраструктуру и сервисы от Яндекс для эффективной работы</p>
                </div>

                <div className={classNames('d-flex', 'align-items-center', 'flex-nowrap')}>
                  <svg
                    className={classNames('me-3')}
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="68" height="68" rx="4" fill="#A9D3AB" />
                    <path
                      d="M45.4974 52.7474H22.4974C22.2764 52.7474 22.0644 52.6596 21.9081 52.5033C21.7519 52.347 21.6641 52.1351 21.6641 51.9141V21.4974C21.6641 21.2764 21.7519 21.0644 21.9081 20.9081C22.0644 20.7519 22.2764 20.6641 22.4974 20.6641H28.6641C28.8851 20.6641 29.097 20.7519 29.2533 20.9081C29.4096 21.0644 29.4974 21.2764 29.4974 21.4974C29.4974 21.7184 29.4096 21.9304 29.2533 22.0867C29.097 22.2429 28.8851 22.3307 28.6641 22.3307H23.3307V51.0807H44.6641V22.3307H39.3307C39.1097 22.3307 38.8978 22.2429 38.7415 22.0867C38.5852 21.9304 38.4974 21.7184 38.4974 21.4974C38.4974 21.2764 38.5852 21.0644 38.7415 20.9081C38.8978 20.7519 39.1097 20.6641 39.3307 20.6641H45.4974C45.7184 20.6641 45.9304 20.7519 46.0867 20.9081C46.2429 21.0644 46.3307 21.2764 46.3307 21.4974V51.9141C46.3307 52.1351 46.2429 52.347 46.0867 52.5033C45.9304 52.6596 45.7184 52.7474 45.4974 52.7474Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.3333 30.3281C27.597 30.3281 27.8548 30.4063 28.0741 30.5528C28.2934 30.6993 28.4643 30.9076 28.5652 31.1512C28.6661 31.3948 28.6925 31.6629 28.641 31.9216C28.5896 32.1802 28.4626 32.4178 28.2761 32.6043C28.0897 32.7907 27.8521 32.9177 27.5935 32.9692C27.3348 33.0206 27.0667 32.9942 26.8231 32.8933C26.5795 32.7924 26.3712 32.6215 26.2247 32.4022C26.0782 32.183 26 31.9252 26 31.6615C26 31.3078 26.1405 30.9687 26.3905 30.7186C26.6406 30.4686 26.9797 30.3281 27.3333 30.3281Z"
                      fill="white"
                    />
                    <path
                      d="M41.9844 32.4948H30.5677C30.3467 32.4948 30.1347 32.407 29.9785 32.2507C29.8222 32.0944 29.7344 31.8825 29.7344 31.6615C29.7344 31.4404 29.8222 31.2285 29.9785 31.0722C30.1347 30.9159 30.3467 30.8281 30.5677 30.8281H41.9844C42.2054 30.8281 42.4174 30.9159 42.5736 31.0722C42.7299 31.2285 42.8177 31.4404 42.8177 31.6615C42.8177 31.8825 42.7299 32.0944 42.5736 32.2507C42.4174 32.407 42.2054 32.4948 41.9844 32.4948Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.3333 36.1641C27.597 36.1641 27.8548 36.2423 28.0741 36.3888C28.2934 36.5353 28.4643 36.7435 28.5652 36.9872C28.6661 37.2308 28.6925 37.4989 28.641 37.7575C28.5896 38.0162 28.4626 38.2537 28.2761 38.4402C28.0897 38.6267 27.8521 38.7537 27.5935 38.8051C27.3348 38.8566 27.0667 38.8302 26.8231 38.7292C26.5795 38.6283 26.3712 38.4574 26.2247 38.2382C26.0782 38.0189 26 37.7611 26 37.4974C26 37.1438 26.1405 36.8046 26.3905 36.5546C26.6406 36.3045 26.9797 36.1641 27.3333 36.1641Z"
                      fill="white"
                    />
                    <path
                      d="M41.9844 38.3307H30.5677C30.3467 38.3307 30.1347 38.2429 29.9785 38.0867C29.8222 37.9304 29.7344 37.7184 29.7344 37.4974C29.7344 37.2764 29.8222 37.0644 29.9785 36.9081C30.1347 36.7519 30.3467 36.6641 30.5677 36.6641H41.9844C42.2054 36.6641 42.4174 36.7519 42.5736 36.9081C42.7299 37.0644 42.8177 37.2764 42.8177 37.4974C42.8177 37.7184 42.7299 37.9304 42.5736 38.0867C42.4174 38.2429 42.2054 38.3307 41.9844 38.3307Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.3333 42C27.597 42 27.8548 42.0782 28.0741 42.2247C28.2934 42.3712 28.4643 42.5795 28.5652 42.8231C28.6661 43.0667 28.6925 43.3348 28.641 43.5935C28.5896 43.8521 28.4626 44.0897 28.2761 44.2761C28.0897 44.4626 27.8521 44.5896 27.5935 44.641C27.3348 44.6925 27.0667 44.6661 26.8231 44.5652C26.5795 44.4643 26.3712 44.2934 26.2247 44.0741C26.0782 43.8548 26 43.597 26 43.3333C26 42.9797 26.1405 42.6406 26.3905 42.3905C26.6406 42.1405 26.9797 42 27.3333 42Z"
                      fill="white"
                    />
                    <path
                      d="M41.9859 44.1667H30.5693C30.3483 44.1667 30.1363 44.0789 29.98 43.9226C29.8237 43.7663 29.7359 43.5543 29.7359 43.3333C29.7359 43.1123 29.8237 42.9004 29.98 42.7441C30.1363 42.5878 30.3483 42.5 30.5693 42.5H41.9859C42.207 42.5 42.4189 42.5878 42.5752 42.7441C42.7315 42.9004 42.8193 43.1123 42.8193 43.3333C42.8193 43.5543 42.7315 43.7663 42.5752 43.9226C42.4189 44.0789 42.207 44.1667 41.9859 44.1667ZM39.3359 25.1417H28.6693C28.4483 25.1417 28.2363 25.0539 28.08 24.8976C27.9237 24.7413 27.8359 24.5293 27.8359 24.3083V18.8917C27.8359 18.6707 27.9237 18.4587 28.08 18.3024C28.2363 18.1461 28.4483 18.0583 28.6693 18.0583H31.2193V17.5C31.2193 16.9033 31.4563 16.331 31.8783 15.909C32.3002 15.4871 32.8725 15.25 33.4693 15.25H34.5359C34.831 15.25 35.1232 15.3083 35.3957 15.4214C35.6682 15.5346 35.9157 15.7005 36.124 15.9095C36.3323 16.1186 36.4972 16.3667 36.6094 16.6396C36.7215 16.9125 36.7787 17.2049 36.7776 17.5V18.0583H39.3359C39.557 18.0583 39.7689 18.1461 39.9252 18.3024C40.0815 18.4587 40.1693 18.6707 40.1693 18.8917V24.3083C40.1693 24.5293 40.0815 24.7413 39.9252 24.8976C39.7689 25.0539 39.557 25.1417 39.3359 25.1417ZM29.5026 23.475H38.5026V19.725H35.9443C35.7233 19.725 35.5113 19.6372 35.355 19.4809C35.1987 19.3246 35.1109 19.1127 35.1109 18.8917V17.5C35.111 17.3467 35.0506 17.1996 34.943 17.0904C34.8354 16.9813 34.6892 16.9189 34.5359 16.9167H33.4693C33.3152 16.9188 33.1681 16.981 33.0592 17.0899C32.9502 17.1988 32.8881 17.346 32.8859 17.5V18.8917C32.8859 19.1127 32.7981 19.3246 32.6419 19.4809C32.4856 19.6372 32.2736 19.725 32.0526 19.725H29.5026V23.475Z"
                      fill="white"
                    />
                  </svg>
                  <p>Базу коммерческих и других открытых проектов</p>
                </div>

                <div className={classNames('d-flex', 'align-items-center', 'flex-nowrap')}>
                  <svg
                    className={classNames('me-3')}
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="68" height="68" rx="4" fill="#FAA9A3" />
                    <path
                      d="M33.0376 35.4769V18.854C33.0376 18.6026 32.9378 18.3616 32.7601 18.1838C32.5823 18.0061 32.3413 17.9062 32.0899 17.9062C29.063 17.908 26.0854 18.6728 23.4323 20.1299C20.7792 21.587 18.5362 23.6894 16.9107 26.2427C15.2852 28.7961 14.3295 31.718 14.1321 34.7385C13.9347 37.7589 14.5019 40.7804 15.7812 43.5236C17.0606 46.2669 19.0108 48.6433 21.4517 50.4334C23.8925 52.2234 26.7453 53.3692 29.7462 53.765C32.7471 54.1607 35.7993 53.7936 38.6208 52.6975C41.4423 51.6014 43.9419 49.8118 45.8887 47.494C46.0439 47.2948 46.1183 47.0444 46.0972 46.7927C46.0878 46.6676 46.0527 46.5457 45.994 46.4348C45.9353 46.3239 45.8542 46.2263 45.756 46.1483L33.0376 35.4769ZM32.0899 52.0241C27.817 52.1498 23.6691 50.5729 20.5588 47.6403C17.4485 44.7077 15.6305 40.6597 15.5048 36.3868C15.3792 32.1138 16.9561 27.9659 19.8886 24.8556C22.8212 21.7453 26.8692 19.9274 31.1422 19.8017V35.9129C31.142 36.0507 31.1726 36.1869 31.2316 36.3115C31.2906 36.4361 31.3766 36.5459 31.4834 36.6332L43.8037 46.9822C42.2978 48.5765 40.4819 49.846 38.4676 50.7131C36.4532 51.5801 34.2829 52.0262 32.0899 52.0241Z"
                      fill="white"
                    />
                    <path
                      d="M35.2956 32.6497C35.4477 32.6453 35.5968 32.6064 35.7316 32.5359L48.9996 25.5986C49.1127 25.5405 49.213 25.4602 49.2944 25.3625C49.3758 25.2648 49.4367 25.1517 49.4735 25.03C49.539 24.7744 49.5051 24.5035 49.3787 24.2718C47.8857 21.7292 45.8906 19.5172 43.515 17.7706C41.1394 16.024 38.4331 14.7792 35.561 14.1123C35.4225 14.085 35.2797 14.0879 35.1424 14.1206C35.005 14.1533 34.8763 14.2151 34.7649 14.3018C34.6522 14.3892 34.561 14.5012 34.4986 14.6294C34.4361 14.7576 34.404 14.8985 34.4048 15.0411V31.664C34.4 31.8261 34.4377 31.9867 34.5142 32.1296C34.5907 32.2726 34.7032 32.3931 34.8407 32.4791C34.975 32.5745 35.1318 32.6333 35.2956 32.6497ZM36.3002 16.2731C40.7998 17.6006 44.6822 20.4822 47.2558 24.4045L36.2434 30.0908L36.3002 16.2731ZM50.9519 27.1719C50.8249 26.9888 50.635 26.8587 50.4184 26.8063C50.2018 26.7538 49.9735 26.7828 49.7768 26.8875L34.9355 34.2039C34.7899 34.2697 34.6642 34.3727 34.5711 34.5024C34.4779 34.6322 34.4206 34.7842 34.4048 34.9431C34.3864 35.1025 34.408 35.2638 34.4676 35.4127C34.5271 35.5616 34.6228 35.6934 34.746 35.7961L47.597 46.0504C47.7578 46.2159 47.9735 46.3169 48.2036 46.3347C48.4088 46.3361 48.6088 46.2695 48.7722 46.1452C50.9105 44.5435 52.5105 42.3287 53.3591 39.7955C53.9668 37.649 54.0657 35.3904 53.6478 33.1991C53.23 31.0078 52.3069 28.9441 50.9519 27.1719ZM51.4637 39.1321C50.8154 41.0653 49.6483 42.7832 48.0898 44.0981L37.0015 35.2464L49.8715 28.9536C50.8546 30.4352 51.5059 32.1119 51.7807 33.8687C52.0555 35.6255 51.9474 37.4209 51.4637 39.1321Z"
                      fill="white"
                    />
                  </svg>
                  <p>Детальную аналитику по выполняемым проектам</p>
                </div>
              </div>
            </div>
          </section>

          <section
            className={classNames(
              'd-flex',
              styles.image__block,
              styles.image__block__yourProjects,
            )}>
            <div className={classNames('mr-4', styles.image__block__textblock)}>
              <h2 className={classNames('mb-4')}>
                <span className={styles.green__text}>
                  Создавайте свои проекты <br />
                </span>
                на платформе Egile
              </h2>
              <p className={classNames('mb-4')}>
                Высокая эффективность и полная прозрачность процессов на каждом этапе для тех, кому
                важно меньше ошибаться и понимать, что происходит в проекте
              </p>
              <p className={classNames('fw-600', styles.green__text)}>Работа в одном окне</p>
              <p>
                Все необходимое в одном месте — храни все материалы проекта на платформе и не
                упускай важного
              </p>
            </div>
            <div>
              <img
                className={styles.image}
                src={projects}
                alt={'Создавайте свои проекты на платформе Egile'}
              />
            </div>
          </section>

          <section className={classNames(styles.image__block)}>
            <h2 className={classNames('mb-4')}>
              Работа с{' '}
              <span className={styles.green__text}>
                внутренней базой
                <br /> специалистов Egile
              </span>
            </h2>
            <div
              className={classNames(
                'd-flex justify-content-between',
                styles.image__block__specialists,
              )}>
              <div className={classNames(styles.image__block__card)}>
                <h4 className={classNames(styles.green__text)}>
                  Не хватает ресурсов и специалистов для выхода на новый уровень?
                </h4>
                <p className={classNames('mb-4')}>
                  Дополняй команду свободными специалистами платформы Egile
                </p>
                <img
                  src={base1}
                  alt={'Дополняй команду свободными специалистами платформы Egile'}
                />
              </div>
              <div className={classNames(styles.image__block__card)}>
                <div>
                  <h4 className={classNames(styles.green__text)}>
                    Хочешь получить опыт и развитие как профессионал?
                  </h4>
                  <p className={classNames('mb-4')}>
                    Сотрудничай с известными компаниями и расширяй портфолио
                  </p>
                </div>

                <img src={base2} alt={'Сотрудничай с известными компаниями и расширяй портфолио'} />
              </div>
            </div>
          </section>

          <section className={styles.students}>
            <h2 className={classNames('mb-4')}>
              Почему <span className={styles.green__text}>Egile</span>
            </h2>
            <div className={styles.students__card}>
              <div className={styles.students__list}>
                <div className={styles.students__list__desktop}>
                  <h4>Проблемы</h4>
                </div>
                <div className={styles.students__list__desktop}>
                  <h4>Результат</h4>
                </div>
                <div className={classNames('pb-4 pt-0 pt-md-4', styles.students__list__item)}>
                  <p className={classNames(styles.students__list__mobile, 'fw-500', 'mb-3')}>
                    Проблема
                  </p>
                  <div className={classNames('d-flex')}>
                    <svg
                      className={classNames('me-2 flex-shrink-0')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16 13H8C7.73479 13 7.48043 12.8946 7.2929 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.2929 11.2929C7.48043 11.1054 7.73479 11 8 11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
                        fill="#E04040"
                      />
                    </svg>
                    <p>
                      Мало практики в процессе учебной деятельности, из-за чего проблемы с
                      трудоустройством
                    </p>
                  </div>
                </div>
                <div className={classNames('pb-4', styles.students__list__item)}>
                  <p className={classNames(styles.students__list__mobile, 'fw-500', 'mb-3')}>
                    Результат
                  </p>
                  <div className={classNames('d-flex')}>
                    <svg
                      className={classNames('me-2 flex-shrink-0')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16 13H13V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V13H8C7.73479 13 7.48043 12.8946 7.2929 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.2929 11.2929C7.48043 11.1054 7.73479 11 8 11H11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8V11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
                        fill="#10C943"
                      />
                    </svg>
                    <p>
                      Расширение компетенций и возможность сформировать портфолио, благодаря участию
                      в проектах крупных компаний
                    </p>
                  </div>
                </div>
                <div className={classNames('pb-4', styles.students__list__item)}>
                  <p className={classNames(styles.students__list__mobile, 'fw-500', 'mb-3')}>
                    Проблема
                  </p>
                  <div className={classNames('d-flex')}>
                    <svg
                      className={classNames('me-2 flex-shrink-0')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16 13H8C7.73479 13 7.48043 12.8946 7.2929 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.2929 11.2929C7.48043 11.1054 7.73479 11 8 11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
                        fill="#E04040"
                      />
                    </svg>
                    <p>
                      Работа в офисе снижает эффективность учебы и получения профильного образования
                    </p>
                  </div>
                </div>
                <div className={classNames('pb-4', styles.students__list__item)}>
                  <p className={classNames(styles.students__list__mobile, 'fw-500', 'mb-3')}>
                    Результат
                  </p>
                  <div className={classNames('d-flex')}>
                    <svg
                      className={classNames('me-2 flex-shrink-0')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16 13H13V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V13H8C7.73479 13 7.48043 12.8946 7.2929 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.2929 11.2929C7.48043 11.1054 7.73479 11 8 11H11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8V11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
                        fill="#10C943"
                      />
                    </svg>
                    <p>Возможность работать удаленно без ущерба учебной деятельности</p>
                  </div>
                </div>
                <div className={classNames('pb-4', styles.students__list__item)}>
                  <p className={classNames(styles.students__list__mobile, 'fw-500', 'mb-3')}>
                    Проблема
                  </p>
                  <div className={classNames('d-flex')}>
                    <svg
                      className={classNames('me-2 flex-shrink-0')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16 13H8C7.73479 13 7.48043 12.8946 7.2929 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.2929 11.2929C7.48043 11.1054 7.73479 11 8 11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
                        fill="#E04040"
                      />
                    </svg>
                    <p>Недоступность ресурсов для проектной и стартап-деятельности</p>
                  </div>
                </div>
                <div className={classNames('d-flex pb-4', styles.students__list__item)}>
                  <p className={classNames(styles.students__list__mobile, 'fw-500', 'mb-3')}>
                    Результат
                  </p>
                  <div className={classNames('d-flex')}>
                    <svg
                      className={classNames('me-2 flex-shrink-0')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM16 13H13V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V13H8C7.73479 13 7.48043 12.8946 7.2929 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.2929 11.2929C7.48043 11.1054 7.73479 11 8 11H11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8V11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
                        fill="#10C943"
                      />
                    </svg>
                    <p>Доступная инфраструктура для создания собственных проектов</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className={classNames('d-flex', styles.image__block, styles.image__block__diploma)}>
            <div className={classNames('mr-4', styles.image__block__textblock)}>
              <h2 className={classNames('mb-4')}>
                <span className={styles.green__text}>
                  Федеральная программа <br />
                </span>
                «Стартап как диплом»
              </h2>
              <img
                src={diploma}
                className={classNames('rounded-4 mb-4', styles.image__block__mobile)}
                alt={'Используй ресурсы Egile для создания диплома в формате стартапа'}
              />
              <p className={classNames('mb-4')}>
                Программа «Стартап как диплом» направлена на вовлечение талантливых студентов в
                развитие экосистемы технологического предпринимательства, а также на поддержку
                бизнеса, находящегося на начальной стадии
              </p>
              <p className={classNames('fw-500', styles.green__text, styles.bigger__text)}>
                Используй ресурсы Egile для создания диплома в формате стартапа
              </p>
            </div>
            <div>
              <img
                src={diploma}
                className={classNames('rounded-4', styles.image__block__desktop)}
                alt={'Используй ресурсы Egile для создания диплома в формате стартапа'}
              />
            </div>
          </section>

          <section className={styles.yandex_instruments}>
            <h2>
              Наши интеграции с <span className={styles.green__text}>Yandex</span>
            </h2>
            <div
              className={classNames(
                'd-flex justify-content-between flex-wrap',
                styles.yandex_instruments__wrapper,
              )}>
              <div className={styles.yandex_instruments__item}>
                <img src={image_cloud} alt="logo" />

                <div>
                  <p
                    className={classNames(
                      styles.bigger__text,
                      'mb-3',
                      'fw-500',
                      styles.green__text,
                    )}>
                    Yandex Cloud
                  </p>
                  <p className={classNames('mb-3 mb-md-4')}>
                    Платформа для создания, управления и масштабирования вычислительных ресурсов,
                    хранения данных и работы с ними на удаленных серверах.
                  </p>
                  <div className={styles.landing__list}>
                    <p>Хранение и обработка данных</p>
                    <p>Искусственный интеллект и машинное обучение</p>
                    <p>Базы данных</p>
                    <p>Балансировщики нагрузки и многое другое</p>
                  </div>
                </div>
              </div>

              <div className={styles.yandex_instruments__item}>
                <img src={image_360} alt="logo" />

                <div>
                  <p
                    className={classNames(
                      styles.bigger__text,
                      'mb-3',
                      'fw-500',
                      styles.green__text,
                    )}>
                    Яндекс 360
                  </p>

                  <p className={classNames('mb-3 mb-md-4')}>
                    Набор сервисов для работы с документами, почтой, календарем и контактами.
                  </p>

                  <div className={styles.landing__list}>
                    <p>Яндекс.Диск для хранения и обмена файлами</p>
                    <p>Яндекс.Почту с защитой от спама и вирусов</p>
                    <p>Яндекс.Календарь для планирования событий</p>
                    <p>Яндекс.Контакты для управления контактной информацией</p>
                  </div>
                </div>
              </div>

              <div className={styles.yandex_instruments__item}>
                <img src={image_tracker} alt="logo" />

                <div>
                  <p
                    className={classNames(
                      styles.bigger__text,
                      'mb-3',
                      'fw-500',
                      styles.green__text,
                    )}>
                    Yandex Tracker
                  </p>

                  <p className={classNames('mb-3 mb-md-4')}>
                    Сервис для управления проектами и контроля выполнения задач
                  </p>

                  <div className={styles.landing__list}>
                    <p>Создания новых задач и проектов</p>
                    <p>Контроль результатов</p>
                    <p>Автоматизации рабочих процессов</p>
                    <p>Отслеживание задач и проектов в мобильном приложении</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={classNames('d-flex', styles.image__block, styles.actual__work)}>
            <div className={classNames('mr-4', styles.image__block__textblock)}>
              <h2 className={classNames(styles.image__block__title)}>
                Будьте в курсе
                <span className={styles.green__text}>
                  {' '}
                  актуального
                  <br /> состояния работы
                </span>
              </h2>
              <h4 className={classNames('mb-3 mb-lg-5')}>
                Аналитика по Вашим работам в личном кабинете
              </h4>

              <div className={styles.landing__list}>
                <p>Современный подход к визуализации аналитической информации</p>

                <p>Графики по ключевым показателям</p>

                <p>Контроль выполнения задачи соблюдения сроков</p>

                <p>Удобное формирование отчетности</p>

                <p>Отслеживание финансовых расчетов</p>
              </div>
            </div>
            <div>
              <img className={styles.image} src={actual_work} alt={actual_work} />
            </div>
          </section>

          <section className={styles.enter}>
            <section
              className={classNames(
                styles.enter__wrapper,
                'd-flex',
                'flex-column',
                'align-items-center',
              )}>
              <h2>Откройте новые возможности и станьте частью команды</h2>
              <ButtonMUI variant="contained">
                <Link className="text-white" to="/authorization">
                  Подключиться к проекту
                </Link>
              </ButtonMUI>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};
