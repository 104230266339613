import { NavLink, useNavigate } from 'react-router-dom';
import styles from './ProjectsHeader.module.scss';
import { Button } from '@components/MUI/Button';
import { CreateRequestIcon } from '@layouts/requests/Icons/CreateRequestIcon';
import { ReactComponent as DelegationIcon } from '@assets/img/delegationIcon.svg';
import { ReactComponent as ArchiveIcon } from '@assets/img/archiveIcon.svg';
import { ReactComponent as WorkerIcon } from '@assets/img/workerIcon.svg';
import { Can } from '@access-control';

export const ProjectsHeader = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.container__links}>
        <Can I="control" a="Project">
          <NavLink
            to={`/projects/controlled`}
            className={({ isActive }) => (isActive ? styles.active : '')}>
            <DelegationIcon />Я управляю
          </NavLink>
        </Can>
        <Can I="control" a="Project">
          <NavLink
            to={`/projects/archive`}
            className={({ isActive }) => (isActive ? styles.active : '')}>
            <ArchiveIcon />
            Архив
          </NavLink>
        </Can>
        <Can I="execute" a="Project">
          <NavLink
            to={`/projects/executing`}
            className={({ isActive }) => (isActive ? styles.active : '')}>
            <WorkerIcon className={styles.widerSvg} />Я исполняю
          </NavLink>
        </Can>
        <Can not I="execute" a="Project">
          <Can not I="control" a="Project">
            <h2 className={styles.noProjects}>У вас нет проектов</h2>
          </Can>
        </Can>
      </div>
      <Button
        startIcon={<CreateRequestIcon />}
        variant="contained"
        onClick={() => navigate('/projects/new')}>
        Создать проект
      </Button>
    </div>
  );
};
