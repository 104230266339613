import {
  useAcceptOfferMutation,
  useDenyOfferMutation,
  useDetailProjectQuery,
  useGetProjectVacancyOffersQuery,
  useGetProjectVacancyQuery,
  useSuggestNewOfferConditionsMutation,
} from '@store/project/project.slice';
import { Link, useParams } from 'react-router-dom';
import styles from './VacancyOffers.module.scss';
import { statusColors, StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { useUrlParams } from '@hooks/useUrlParams';
import { useCallback, useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { TYPE_OF_PAYMENTS } from '@store/project/contracts';
import { IconButton } from '@mui/material';
import { ReactComponent as AgreeIcon } from '@assets/img/checkIcon.svg';
import { ReactComponent as DisagreeIcon } from '@assets/img/deleteRed.svg';
import {
  DecisionModal,
  DecisionOnSubmitValues,
  decisionTextPresets,
} from '@components/DecisionModal';
import { formatPaymentType } from '../../helpers';
import { VacancyConditions } from '../VacancyConditions';
import { ReactComponent as EditIcon } from '@assets/img/edit.svg';

export const VacancyOffers = () => {
  const { vacancyId, projectId } = useParams();

  const { getParam, updateParams } = useUrlParams();
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState<number>(Number(getParam('limit', 10)));
  const [page, setPage] = useState<number>(Number(getParam('page', 1)));

  const { data: vacancy, isLoading: isVacancyLoading } = useGetProjectVacancyQuery({
    vacancyId: vacancyId!,
  });

  const { data: offers, isLoading } = useGetProjectVacancyOffersQuery({
    vacancyId: vacancyId!,
    limit: limit,
    start: (page - 1) * limit,
  });

  const { data: project } = useDetailProjectQuery(projectId!);

  const [acceptOffer] = useAcceptOfferMutation();
  const [denyOffer] = useDenyOfferMutation();
  const [sendMessage] = useSuggestNewOfferConditionsMutation();

  const handleChangePage = (_: unknown, newPage: number) => {
    updateParams({ page: newPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    updateParams({ limit: newLimit, page: 1 });
    setLimit(newLimit);
    setPage(1);
  };

  const handleDisagree = useCallback(
    async (values: DecisionOnSubmitValues) => {
      await denyOffer({
        requestId: values.id,
      })
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        });
    },
    [dispatch, closeModal, denyOffer],
  );

  const handleAgree = useCallback(
    async (values: DecisionOnSubmitValues) => {
      try {
        await acceptOffer({
          requestId: values.id,
        })
          .unwrap()
          .then(() => {
            dispatch(closeModal());
          });
      } catch (err) {}
    },
    [acceptOffer, dispatch, closeModal],
  );

  const handleSuggestConditions = useCallback(
    async (values: DecisionOnSubmitValues) => {
      try {
        await sendMessage({
          requestId: values.id,
          payload: {
            ...values.payload,
            workloadPerMonth:
              values.payload.typeOfPayment !== TYPE_OF_PAYMENTS.MONTHLY
                ? 0
                : values.payload.workloadPerMonth,
            payment:
              values.payload.typeOfPayment === TYPE_OF_PAYMENTS.UNPAID ? 0 : values.payload.payment,
          },
        });
      } catch (err) {}
    },
    [sendMessage],
  );

  const showAgreeModal = useCallback(
    (row: any) => {
      dispatch(
        openModal(
          <DecisionModal
            onSubmit={handleAgree}
            values={{
              projectName: project?.name!,
              payment: row.payment,
              typeOfPayment: row.typeOfPayment,
              workloadPerMonth: row.workloadPerMonth,
              requestId: row?.id,
              employerName: row?.employerName,
            }}
            headerLabel="Сообщение соискателю"
            buttonLabel="Согласовать условия"
            initialMessage={decisionTextPresets.agree(row?.jobSeekerName)}
          />,
        ),
      );
    },
    [dispatch, openModal, handleAgree],
  );

  const showDisagreeModal = useCallback(
    (row: any) => {
      dispatch(
        openModal(
          <DecisionModal
            onSubmit={handleDisagree}
            values={{
              projectName: project?.name!,
              payment: row.payment,
              typeOfPayment: row.typeOfPayment,
              workloadPerMonth: row.workloadPerMonth,
              requestId: row?.id,
              employerName: row?.employerName,
            }}
            headerLabel="Сообщение соискателю"
            buttonLabel="Отклонить условия"
            initialMessage={decisionTextPresets.disagree(
              row?.jobSeekerName,
              '',
              vacancy?.positionName!,
            )}
          />,
        ),
      );
    },
    [dispatch, openModal, handleDisagree],
  );

  const showNewConditionsModal = useCallback(
    (row: any) => {
      dispatch(
        openModal(
          <DecisionModal
            onSubmit={handleSuggestConditions}
            values={{
              projectName: project?.name!,
              payment: row.payment,
              typeOfPayment: row.typeOfPayment,
              workloadPerMonth: row.workloadPerMonth,
              requestId: row?.id,
              employerName: row?.employerName,
            }}
            headerLabel="Сообщение соискателю"
            buttonLabel="Предложить новые условия"
            initialMessage={decisionTextPresets.newConditions(
              row?.jobSeekerName,
              '',
              vacancy?.positionName!,
            )}
            shouldCheckForPristine
          />,
        ),
      );
    },
    [vacancy, handleSuggestConditions, decisionTextPresets],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Соискатели на вакансию {vacancy?.positionName}</h2>
        <StatusLabel color={statusColors.get(vacancy?.statusName!)} text={vacancy?.statusName!} />
      </div>
      <div className={styles.conditions}>
        <p>Первоначальные условия вакансии:</p>
        <VacancyConditions
          isLoading={isVacancyLoading}
          payment={vacancy?.payment}
          typeOfPayment={vacancy?.typeOfPayment as TYPE_OF_PAYMENTS}
          workloadPerMonth={vacancy?.workloadPerMonth}
        />
      </div>
      <div className={styles.table}>
        <AdaptiveTableMUI
          emptyLabel="Нет предложений"
          rows={offers?.data || []}
          isLoading={isLoading}
          columns={{
            jobSeekerName: {
              label: 'ФИО',
              children: (value, row) => {
                return <Link to={`/userPage/${row?.jobSeekerId}`}>{value}</Link>;
              },
            },
            jobSeekerExperience: {
              label: 'Опыт',
              children: (value) => {
                return <p className={styles.text}>{value ?? 'Не указан'}</p>;
              },
            },
            typeOfPayment: {
              label: 'Вид оплаты',
              children: (value, row) => {
                return (
                  <p className={styles.text}>
                    {formatPaymentType({
                      typeOfPayment: value,
                      hours: row?.workloadPerMonth,
                    })}
                  </p>
                );
              },
            },
            payment: {
              label: 'Оплата',
              children: (value) => {
                return <p className={styles.text}>{value}</p>;
              },
            },
            status: {
              label: 'Статус',
              children: (value) => {
                return <>{<StatusLabel color={statusColors.get(value)} text={value} />}</>;
              },
            },
            id: {
              label: '',
              children: (_, row) => {
                return (
                  <div className={styles.actionButtons}>
                    <IconButton onClick={() => showAgreeModal(row)}>
                      <AgreeIcon />
                    </IconButton>
                    <IconButton onClick={() => showDisagreeModal(row)}>
                      <DisagreeIcon />
                    </IconButton>
                    <IconButton onClick={() => showNewConditionsModal(row)}>
                      <EditIcon />
                    </IconButton>
                  </div>
                );
              },
            },
          }}
        />
        <TablePaginationMUI
          limit={limit}
          page={page}
          count={offers?.totalCount}
          entityLabel="соискателей"
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};
