interface PriceFormatterProps {
  amount: number;
  currency?: string;
}

export const priceFormatter = ({ amount, currency = 'RUB' }: PriceFormatterProps) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  }).format(amount);
};
