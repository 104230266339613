import { FormWrapper } from '@components/Form/Form';
import { Modal } from '@components/MUI/Modal/Modal';
import { AddVacancyForm, AddVacancyFormProps, AddVacancyValue } from './AddPositionForm';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { useParams } from 'react-router-dom';
import { useCreateVacancyMutation } from '@store/project/project.slice';
import { TYPE_OF_PAYMENTS } from '@store/project/contracts';

type AddPositionProps = {
  initialValues?: AddVacancyValue;
};

export const AddPositionModal = ({ initialValues }: AddPositionProps) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [createVacancy] = useCreateVacancyMutation();

  const handleSubmit: AddVacancyFormProps['submit'] = async (value) => {
    await createVacancy({
      projectId: projectId!,
      public: true,
      ...value,
      ...(value.typeOfPayment !== TYPE_OF_PAYMENTS.UNPAID && {
        payment: value.payment,
      }),
      ...(value.typeOfPayment === TYPE_OF_PAYMENTS.MONTHLY && {
        workloadPerMonth: value.workloadPerMonth,
      }),
    })
      .unwrap()
      .then(() => {
        dispatch(closeModal());
      });
  };
  return (
    <Modal>
      <FormWrapper label="Добавление должности">
        <AddVacancyForm
          submit={handleSubmit}
          value={
            initialValues ?? {
              positionNames: '',
              directionId: '',
              projectPositionId: '',
              typeOfPayment: '',
              workloadPerMonth: 0,
              description: '',
              payment: 0,
              requiredNumberPositions: 0,
            }
          }
        />
      </FormWrapper>
    </Modal>
  );
};
