import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CreateRequestIcon } from './Icons/CreateRequestIcon';
import { Can } from '@access-control';
import { Button } from '@components/MUI/Button';
import { useCallback } from 'react';
import { openModal } from '@store/ui/ui.slice';
import { useAppDispatch } from '@hooks/index';
import { ModalReleases } from '@pages/task/Releases/ModalReleases';
import { ReactComponent as TaskIcon } from '@assets/img/tasks.svg';
import { ReactComponent as ReleasesIcon } from '@assets/img/release.svg';

const TaskHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isReleases = location.pathname.includes('releases');
  const dispatch = useAppDispatch();

  const handleCreateRelease = useCallback(() => {
    dispatch(openModal(<ModalReleases />));
  }, []);

  const addNewButtonLabel = isReleases ? 'Создать релиз' : 'Cоздать задачу';

  const openCreateModal = () => {
    if (isReleases) {
      handleCreateRelease();
    } else {
      navigate('/tasks/new');
    }
  };

  return (
    <header className="header">
      <ul>
        <li>
          <NavLink to="/tasks" className={({ isActive }) => (isActive ? 'active' : '')} end>
            <TaskIcon style={{ marginRight: '5px' }} />
            Задачи
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/tasks/releases"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            <ReleasesIcon style={{ marginRight: '5px' }} />
            Релизы
          </NavLink>
        </li>
      </ul>

      <Can I="create" a="Task">
        <Button startIcon={<CreateRequestIcon />} variant="contained" onClick={openCreateModal}>
          {addNewButtonLabel}
        </Button>
      </Can>
    </header>
  );
};

export { TaskHeader };
