import { Button } from '@components/MUI/Button';
import { ProjectTitle } from '../ProjectTitle';
import styles from './Vacancies.module.scss';
import { ReactComponent as AddIcon } from '@assets/img/addGreen.svg';
import { VacanciesTable } from './components/VacanciesTable';
import { useAppDispatch } from '@hooks/index';
import { useCallback } from 'react';
import { openModal } from '@store/ui/ui.slice';
import { AddPositionModal } from './components/AddPosition/AddPositionModal';

export const Vacancies = () => {
  const dispatch = useAppDispatch();

  const showAddPositionModal = useCallback(() => {
    dispatch(openModal(<AddPositionModal />));
  }, [dispatch, openModal]);
  return (
    <div className={styles.container}>
      <ProjectTitle />
      <div className={styles.title}>
        <h3>Вакансии</h3>
        <Button onClick={showAddPositionModal}>
          <AddIcon />
          Добавить вакансию
        </Button>
      </div>
      <VacanciesTable />
    </div>
  );
};
