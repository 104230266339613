import { ApiClient } from '@store/api-client';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { DocumentsResponse } from './contracts';
import { PathParameters, ResponseBody } from '@store/utility';

export const getDocsFavorite = async (
  request?: Api.operations['getFavoriteProjectAttachments']['parameters']['query'],
) => {
  const { data } = await ApiClient.get<
    ResponseBody<Api.operations['getFavoriteProjectAttachments']>
  >(`/api/document/favorite`, {
    params: request,
  });
  return plainToInstance(DocumentsResponse, data);
};

export const getFile = async (key: PathParameters<Api.operations['getFile'], 'key'>) => {
  const { data, headers } = await ApiClient.get<ResponseBody<Api.operations['getFile']>>(
    `/api/file/${key}`,
    { responseType: 'blob' },
  );
  return { data, headers };
};
