import styles from './ProjectsWithoutRoles.module.scss';
import ideaPic from '@assets/img/idea.png';
import searchPic from '@assets/img/searching.png';

export const ProjectsWithoutRoles = () => {
  return (
    <div className={styles.container}>
      <p className="">Найдите подходящий проект для примера или присоединитесь к текущему.</p>
      <div className={styles.links}>
        <a href="/">
          <img src={ideaPic} alt="idea" />
          Примеры проектов
        </a>
        <a href="/">
          <img src={searchPic} alt="idea" />
          Примеры проектов
        </a>
      </div>
    </div>
  );
};
