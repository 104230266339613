import { NavLink } from 'react-router-dom';

export const ProfileHeader = () => {
  return (
    <header className="header">
      <ul>
        <li>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} end>
            Общая информация
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/rate"
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ textDecoration: 'none' }}>
            Мой тариф
          </NavLink>
        </li>
      </ul>
    </header>
  );
};
