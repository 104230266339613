import React from 'react';
import { useAppSelector } from '@hooks/index';
import { useMeQuery } from '@store/user/user.slice';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { ProfileAboutMe } from './profileComponents/ProfileAboutMe';
import { ProfileWork } from './profileComponents/ProfileWork';
import { ProfileAboutCompany } from './profileComponents/ProfileAboutCompany';
import { ProfileFooter } from './profileComponents/profileFooter/ProfileFooter';
import { ProfileMain } from './profileComponents/ProfileMain';

export const MyProfile: React.FC = () => {
  const { data: myUser } = useMeQuery();

  const directions = useAppSelector(selectDictionary(DictionaryName.DIRECTION));
  const myDirection = directions.find(
    (direction) => direction.id === myUser?.about?.directionId,
  )?.name;

  const statuses = useAppSelector(selectDictionary(DictionaryName.WORK_STATUS));
  const myStatus = statuses.find((status) => status.id === myUser?.work?.workStatusId)?.name;

  return (
    <div>
      <ProfileMain
        icon={myUser?.about?.icon}
        firstName={myUser?.about?.firstName}
        lastName={myUser?.about?.lastName}
        patronymic={myUser?.about?.patronymic}
        direction={myDirection}
        phones={myUser?.link?.phones}
        email={myUser?.link?.email}
        telegram={myUser?.link?.telegram}
      />

      <ProfileAboutMe
        birthDate={myUser?.about?.birthDate}
        location={myUser?.about?.location}
        userName={myUser?.about?.userName}
        direction={myDirection}
        stack={myUser?.about?.stack}
        experience={myUser?.about?.experience}
        experienceDescription={myUser?.about?.experienceDescription}
        resumeUrl={myUser?.about?.resumeUrl}
        resume={myUser?.about?.resume}
        certificates={myUser?.about?.certificates}
        about={myUser?.about?.about}
      />

      {myUser?.roles?.find((role) => role.name === 'Менеджер') ||
      myUser?.roles?.find((role) => role.name === 'Исполнитель') ? (
        <ProfileWork
          status={myStatus}
          hourPrice={myUser?.work?.hourPrice}
          monthPrice={myUser?.work?.monthPrice}
        />
      ) : (
        <></>
      )}

      {myUser?.roles?.find((role) => role.name === 'Менеджер') ||
      myUser?.roles?.find((role) => role.name === 'Заказчик') ? (
        <ProfileAboutCompany
          logoUrl={myUser?.organization?.logo}
          name={myUser?.organization?.name}
          url={myUser?.organization?.url}
          officeLocation={myUser?.organization?.officeLocation}
          email={myUser?.organization?.email}
          phone={myUser?.organization?.phone}
          description={myUser?.organization?.description}
          rules={myUser?.organization?.rules}
          inn={myUser?.organization?.inn}
        />
      ) : (
        <></>
      )}

      <ProfileFooter registration={myUser?.created} />
    </div>
  );
};
